import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme, Typography, TextField, Fab, Hidden, Box
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DateTime as d } from 'luxon';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BILLING_CYCLE_NAME, BookingStatus, IAddonRequirement, IBooking, IBookingAddonRequirement, IBookingVehicleGroup, ILocationSurchargeInput } from "../../../../reducers/bookings/types";
import { PriceRuleOperator, ValueType } from "../../../../reducers/priceRule/types";
import { IVehiclePriceGroup } from "../../../../reducers/vehiclePriceGroups/types";
import { IAppState } from "../../../../store";
import { FloatInput } from "../../FloatInput/FloatInput";
import { getRateTypeString, getRentalTaxAmount, getTaxesDescription, getVehicleGroupApplicableTaxesDetails, isLongTermBooking, toCurrency } from "../../utils";
import { COLUMN_NAMES } from "./const";
import { getLocalizedBookingSyntex, getLocalizedTaxSyntex } from "../../../../utils/localized.syntex";
import { calculateNewPrice } from "../../../views/Settings/Pricing/PriceRuleCreation/utils";
import { RATE_TYPES } from "../../../views/ReservationManagement/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    tableCellBorder: {
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    miniTable: {
      width: "60%",
      border: "solid 1px rgba(224, 224, 224, 1)",
    }
  })
);

interface IInvoiceItems {
  bookingData: IBooking;
  isEditable?: boolean;
  setIsEditable(data: boolean): void;
  updateBooking(data?: IBooking): void;
  setBooking(booking: IBooking): void;
}
export const InvoiceItems = (props: IInvoiceItems) => {
  const classes = useStyles();
  const [booking, setBooking] = useState<IBooking>();
  const [vehicleCount, setVehicleCount] = useState<number>(1);
  const [paymentDetails, setPaymentDetails] = useState({
    discountAmount: 0,
    discountedRentalAmount: 0,
    taxAmount: 0,
    totalPayableAmount: 0
  })
  const [isProrated, setIsProrated] = useState<boolean>(false);
  const [proratedFraction, setProratedFraction] = useState<number>(1);
  const [bookingDurationInDays, setBookingDurationInDays] = useState<number>(0);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const { bookingData } = props;
  const [weeklySubscription, setWeeklySubscription] = useState<boolean>(false);

  useEffect(() => {
    if (bookingData) {
      if (bookingData.isSubscription && bookingData.activeSubscription) {
        const flag = bookingData.activeSubscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? true : false
        setWeeklySubscription(flag)
      }
      let bookingDuration;
      const longTermBooking = isLongTermBooking(bookingData);
      if (longTermBooking) {
        if (bookingData.paymentDetails.proratedBillingDays) {
          setBookingDurationInDays(bookingData.paymentDetails.proratedBillingDays);
        } else {
          switch (bookingData.rateTypeName) {
            case BILLING_CYCLE_NAME.DAILY:
              if (bookingData.isRecurringBilling && bookingData.billingFrequency) {
                bookingDuration = bookingData.billingFrequency;
                setBookingDurationInDays(bookingDuration);
              }
              break;
            case BILLING_CYCLE_NAME.WEEKLY:
              if (bookingData.isRecurringBilling && bookingData.billingFrequency) {
                bookingDuration = 7 * bookingData.billingFrequency
              } else {
                bookingDuration = 7
              }
              setBookingDurationInDays(bookingDuration);
              break;
            case BILLING_CYCLE_NAME.MONTHLY:
              if (bookingData.isRecurringBilling && bookingData.billingFrequency) {
                let idealEndDate = d.fromISO(bookingData.pickupDateTime).plus({ months: 1 }).toUTC().toISO();
                bookingDuration = d.fromISO(idealEndDate).diff(d.fromISO(bookingData.pickupDateTime), ['days']).days;
                setBookingDurationInDays(bookingDuration);
              }
              break;
            default:
              break;
          }
        }
      } else {
        bookingDuration = Math.ceil(Math.abs(d.fromISO(bookingData.pickupDateTime || "").diff(d.fromISO(bookingData.dropoffDateTime || "").minus({ milliseconds: 1 }), ["days"]).days));
        setBookingDurationInDays(bookingDuration);
      }
      setBooking(JSON.parse(JSON.stringify(bookingData)));
    }
  }, [bookingData]);

  useEffect(() => {
    if (props.isEditable && booking) {
      setBooking({
        ...booking,
        discount: {
          ...booking?.discount,
          amount: 0,
          code: ""
        }
      })
    }
  }, [props.isEditable])

  useEffect(() => {
    if (booking) {
      getPaymentDetails()
    }
  }, [booking])

  const getApplicableRentalAmount = (basePrice: number, priceRuleOperator?: PriceRuleOperator, priceRuleValueType?: ValueType, priceRuleAmount?: number) => {
    return calculateNewPrice(basePrice, priceRuleOperator, priceRuleValueType, priceRuleAmount)
  };

  const getAddonRate = (addon: IAddonRequirement, rentalTime: number) => {
    let rate = addon.rate * addon.quantity;
    if (!addon.hasFixedRate && rentalTime) {
      rate = Math.round(rate * rentalTime * proratedFraction);
    }
    return rate;
  };

  const getPaymentDetails = () => {
    if (booking) {
      setIsProrated(booking.paymentDetails.isProrated ? true : false)
      let fraction = 1;
      if (booking.paymentDetails.isProrated && booking.paymentDetails.proratedBillingDays && booking.paymentDetails.standardBillingDays) {
        fraction = parseFloat((booking.paymentDetails.proratedBillingDays / booking.paymentDetails.standardBillingDays).toString())
        setProratedFraction(fraction)
      }
      const rentalTime = booking.paymentDetails.rentalTime;
      const totalVehicleCount: number = booking.vehicleGroups?.reduce(
        (count: number, vehicleGroup) => {
          return count + vehicleGroup.count;
        },
        0
      );
      // rental amount
      let rentalAmount = 0;
      let rentalTaxAmount = 0;
      let addonTaxAmount = 0;
      let surchargeTaxAmount = 0;
      let oneWayRentalAmount = 0;
      let oneWayRentalTaxAmount = 0;
      booking.vehicleGroups?.forEach((vehicleGroup) => {
        let baseRate: number = vehicleGroup.baseRate;
        if (vehicleGroup.priceRuleAmount && vehicleGroup.priceRuleOperator && vehicleGroup.priceRuleValueType) {
          baseRate = getApplicableRentalAmount(
            vehicleGroup.baseRate,
            vehicleGroup.priceRuleOperator,
            vehicleGroup.priceRuleValueType,
            vehicleGroup.priceRuleAmount
          );
        }
        rentalAmount += baseRate * rentalTime * vehicleGroup.count;
        const unitPrice = booking.paymentDetails.isProrated ? (baseRate * rentalTime * vehicleGroup.count) * fraction : baseRate * rentalTime * vehicleGroup.count;
        const applicableTaxes = getVehicleGroupApplicableTaxesDetails(booking.tax || [], typeof vehicleGroup.vehicleGroup === 'string' ? vehicleGroup.vehicleGroup : vehicleGroup.vehicleGroup.id);
        rentalTaxAmount += getRentalTaxAmount(applicableTaxes, bookingDurationInDays, unitPrice, vehicleGroup.count);
      });
      rentalAmount = Math.round(rentalAmount * fraction);
      rentalTaxAmount = Math.round(rentalTaxAmount);
      // discount
      let discountAmount: number = 0;
      if (booking.discount && !props.isEditable) {
        if (booking.discount.isPercent) {
          discountAmount += Math.round((booking.discount.amount * rentalAmount) / 100);
        } else {
          discountAmount += booking.discount.amount;
        }
      }

      // discounted rental amount
      const discountedRentalAmount = rentalAmount - discountAmount;
      const insuranceAmount =
        booking.insuranceRate * rentalTime * totalVehicleCount * fraction;
      // add ons amount
      let addonsAmount: number = 0;
      booking.addonRequirements.forEach((addon) => {
        let payableAmount = 0;
        if (addon.hasFixedRate) {
          payableAmount = addon.quantity * addon.rate;
          if (addon.tax && addon.tax.value) {
            addonTaxAmount += Math.round((addon.rate * addon.tax.value / 100) * addon.quantity);
          }
        } else {
          payableAmount = addon.quantity * addon.rate * rentalTime * fraction;
          if (addon.tax && addon.tax.value) {
            addonTaxAmount += Math.round((addon.rate * addon.tax.value / 100) * addon.quantity * rentalTime * fraction);
          }
        }
        addonsAmount += payableAmount;
      });
      //location based surcharge
      let totalSurchargeAmount = 0;
      if (booking.locationSurcharges) {
        booking.locationSurcharges.forEach(surcharge => {
          if (surcharge.payableAmount) {
            totalSurchargeAmount += surcharge.payableAmount;
          }
          if (surcharge.payableTaxAmount) {
            surchargeTaxAmount += surcharge.payableTaxAmount;
          }
        });
      }

      //One way rental fee
      if (booking.oneWayRentalFee && booking.oneWayRentalFee.amount > 0) {
        oneWayRentalAmount = booking.oneWayRentalFee.amount * totalVehicleCount;
        if (booking.oneWayRentalFee.taxable) {
          oneWayRentalTaxAmount = Math.round((oneWayRentalAmount * booking.oneWayRentalFee.tax.value) / 100);
        }
      }

      // tax
      let taxAmount = rentalTaxAmount + addonTaxAmount + surchargeTaxAmount + oneWayRentalTaxAmount;

      // total amount to be paid
      const totalPayableAmount =
        discountedRentalAmount +
        insuranceAmount +
        addonsAmount +
        taxAmount +
        totalSurchargeAmount +
        oneWayRentalAmount;

      setVehicleCount(totalVehicleCount);
      setPaymentDetails({
        discountAmount,
        discountedRentalAmount,
        taxAmount,
        totalPayableAmount: booking?.isSubscription ? booking.paymentDetails.totalPayableAmount : totalPayableAmount,
      })
    }
  }

  const handleSubmit = () => {
    if (booking) {
      if (booking.status === BookingStatus.QUOTE) {
        props.updateBooking(booking)
      } else {
        props.setBooking(booking)
        props.setIsEditable(false)
      }
    }
  }

  if (!booking) {
    return <CircularProgress />;
  }

  const DashedLine = () =>
    <>
      <Grid item xs={12}>
        <Box mt={1} />
      </Grid>
      <Grid item xs={12}>
        <div style={{
          border: "1px dashed"
        }} />
      </Grid>
      <Grid item xs={12}>
        <Box mt={1} />
      </Grid>
    </>;

  return (
    <>
      <Hidden xsDown>
        <Grid container item xs={12}>
          <TableContainer>
            <Paper variant="outlined">
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    {
                      COLUMN_NAMES.map((column: string) => {
                        return (
                          <TableCell>
                            <Typography variant={"h4"}>{column === "VAT/TAX" ? getLocalizedTaxSyntex(country) : column}</Typography>
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    booking?.isSubscription ?
                      <>
                        <TableRow>
                          <TableCell
                            className={classes.tableCellBorder}
                            rowSpan={booking?.activeSubscription?.enrollmentAmount > 0 ? 2 : 1}
                          >
                            <Typography variant="body1">
                              {"Subscription"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {`${weeklySubscription ? "Weekly" : "Monthly"} Subscription Charges`} <br />
                            {booking?.activeSubscription?.subscription?.name} <br />
                            {getTaxesDescription(booking.tax || [], currency, locale)?.map(title => {
                              return (
                                <>
                                  <span>({title})</span><br></br>
                                </>
                              )
                            })}
                          </TableCell>
                          <TableCell>{1}</TableCell>
                          <TableCell>
                            {
                              toCurrency(booking?.activeSubscription?.subscriptionAmount || 0, currency, locale)
                            }
                          </TableCell>
                          <TableCell>{weeklySubscription ? "1 Week's" : "1 Month's"}</TableCell>
                          <TableCell>
                            {toCurrency((booking.tax ? Math.round(getRentalTaxAmount(booking.tax, bookingDurationInDays, booking?.activeSubscription?.subscriptionAmount || 0, 1)) : 0), currency, locale)}
                          </TableCell>
                          <TableCell>
                            {toCurrency((booking?.activeSubscription?.subscriptionAmount || 0) + (booking.tax ? Math.round(getRentalTaxAmount(booking.tax, bookingDurationInDays, booking?.activeSubscription?.subscriptionAmount || 0, 1)) : 0), currency, locale)}
                          </TableCell>
                        </TableRow>
                        {
                          booking?.activeSubscription?.enrollmentAmount > 0 && (
                            <TableRow>
                              <TableCell>
                                {"Enrollment Fees"} <br />
                                {booking?.activeSubscription?.subscription?.name} <br />
                                {getTaxesDescription(booking.tax && booking.tax.map(tax => { return { ...tax, rate: "ONE_TIME" } }) || [], currency, locale)?.map(title => {
                                  return (
                                    <>
                                      <span>({title})</span><br></br>
                                    </>
                                  )
                                })}
                              </TableCell>
                              <TableCell>{1}</TableCell>
                              <TableCell>
                                {
                                  toCurrency(booking?.activeSubscription?.enrollmentAmount || 0, currency, locale)
                                }
                              </TableCell>
                              <TableCell>N/A</TableCell>
                              <TableCell>
                                {toCurrency((booking.tax ? Math.round(getRentalTaxAmount(booking.tax, 1, booking?.activeSubscription?.enrollmentAmount || 0, 1)) : 0), currency, locale)}
                              </TableCell>
                              <TableCell>
                                {
                                  toCurrency((booking?.activeSubscription?.enrollmentAmount || 0) + (booking.tax ? Math.round(getRentalTaxAmount(booking.tax, 1, booking?.activeSubscription?.enrollmentAmount || 0, 1)) : 0), currency, locale)
                                }
                              </TableCell>
                            </TableRow>
                          )
                        }
                      </> :
                      booking?.vehicleGroups?.map((vehicleGroup: IBookingVehicleGroup, idx: number) => {
                        const vg = vehicleGroup.vehicleGroup as IVehiclePriceGroup;
                        let unitPrice = (getApplicableRentalAmount(
                          vehicleGroup.baseRate,
                          vehicleGroup.priceRuleOperator,
                          vehicleGroup.priceRuleValueType,
                          vehicleGroup.priceRuleAmount
                        )) * vehicleGroup.count * booking.paymentDetails.rentalTime;
                        unitPrice = isProrated ? unitPrice * proratedFraction : unitPrice;
                        const applicableTaxes = getVehicleGroupApplicableTaxesDetails(booking.tax || [], typeof vehicleGroup.vehicleGroup === 'string' ? vehicleGroup.vehicleGroup : vehicleGroup.vehicleGroup.id)
                        const rentalTaxAmount = booking.tax ? getRentalTaxAmount(applicableTaxes, bookingDurationInDays, unitPrice, vehicleGroup.count) : 0;
                        const taxTitles = getTaxesDescription(applicableTaxes, currency, locale);
                        return (
                          <>
                            <TableRow>
                              {
                                idx === 0 && (
                                  <TableCell
                                    className={classes.tableCellBorder}
                                    rowSpan={booking?.vehicleGroups.length}
                                  >
                                    <Typography variant="body1">
                                      {"Rental Price"}
                                    </Typography>
                                  </TableCell>
                                )
                              }
                              <TableCell colSpan={1}>
                                {vg.name} <br />
                                {
                                  taxTitles.length > 0 &&
                                  taxTitles.map(title => {
                                    return (
                                      <>
                                        <span>({title})</span><br></br>
                                      </>
                                    )
                                  })
                                }
                              </TableCell>
                              <TableCell>{vehicleGroup.count}</TableCell>
                              <TableCell>
                                {!props.isEditable ?
                                  toCurrency(getApplicableRentalAmount(
                                    vehicleGroup.baseRate,
                                    vehicleGroup.priceRuleOperator,
                                    vehicleGroup.priceRuleValueType,
                                    vehicleGroup.priceRuleAmount
                                  ), currency, locale) :
                                  <TextField
                                    fullWidth
                                    placeholder="Unit Price"
                                    label="Unit Price"
                                    name={"unitPrice"}
                                    InputProps={{
                                      value: getApplicableRentalAmount(
                                        vehicleGroup.baseRate,
                                        vehicleGroup.priceRuleOperator,
                                        vehicleGroup.priceRuleValueType,
                                        vehicleGroup.priceRuleAmount
                                      ),
                                      inputComponent: FloatInput as any,
                                      onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        const val = e.target.value || "0";
                                        const rowsArr = [...booking.vehicleGroups];
                                        vehicleGroup.baseRate = parseInt(val);
                                        delete vehicleGroup.priceRuleAmount;
                                        delete vehicleGroup.priceRule;
                                        delete vehicleGroup.priceRuleOperator;
                                        delete vehicleGroup.priceRuleName;
                                        delete vehicleGroup.priceRuleTriggerType;
                                        delete vehicleGroup.priceRuleValueType;
                                        rowsArr.splice(idx, 1, vehicleGroup);
                                        setBooking({
                                          ...booking,
                                          vehicleGroups: rowsArr
                                        });
                                      }
                                    }}
                                    helperText={getApplicableRentalAmount(
                                      vehicleGroup.baseRate,
                                      vehicleGroup.priceRuleOperator,
                                      vehicleGroup.priceRuleValueType,
                                      vehicleGroup.priceRuleAmount
                                    ) <= 0 ? "Rental Price cannot be 0" : ""}
                                    error={getApplicableRentalAmount(
                                      vehicleGroup.baseRate,
                                      vehicleGroup.priceRuleOperator,
                                      vehicleGroup.priceRuleValueType,
                                      vehicleGroup.priceRuleAmount
                                    ) <= 0 ? true : false}
                                    inputProps={{
                                      hasCurrencyPrefix: true,
                                      allowNegative: false
                                    }}
                                  />
                                }
                              </TableCell>
                              <TableCell>
                                {isProrated ?
                                  `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)} (${booking.paymentDetails.proratedBillingDays} days pro-rated)` :
                                  `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)}`}
                              </TableCell>
                              <TableCell>
                                {toCurrency(Math.round(rentalTaxAmount), currency, locale)}
                              </TableCell>
                              <TableCell>
                                {toCurrency(
                                  (Math.round(getApplicableRentalAmount(
                                    vehicleGroup.baseRate,
                                    vehicleGroup.priceRuleOperator,
                                    vehicleGroup.priceRuleValueType,
                                    vehicleGroup.priceRuleAmount
                                  ) * vehicleGroup.count * (booking.paymentDetails?.rentalTime || 1) * proratedFraction) + rentalTaxAmount),
                                  currency,
                                  locale
                                )}
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </>
                        )
                      })
                  }
                  {(() => {
                    if (booking.insurancePolicy?.id) {
                      const totalVehicles = booking.isSubscription ? 1 : booking.vehicleGroups.reduce(
                        (accumulator, current) => accumulator + current.count, 0);
                      return (
                        <TableRow>
                          <TableCell
                            className={classes.tableCellBorder}
                          >
                            <Typography variant="body1">{"Insurance"}</Typography>
                          </TableCell>
                          <TableCell >{booking?.insurancePolicy?.name}</TableCell>
                          <TableCell>{totalVehicles}</TableCell>
                          <TableCell>
                            {!props.isEditable ?
                              toCurrency(
                                booking?.insuranceRate,
                                currency,
                                locale
                              ) :
                              <TextField
                                fullWidth
                                placeholder="Price/Rate"
                                label="Price/Rate"
                                name={"unitPrice"}
                                InputProps={{
                                  value: booking.insuranceRate,
                                  inputComponent: FloatInput as any,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const val = e.target.value || "0";
                                    setBooking({
                                      ...booking,
                                      insuranceRate: parseInt(val)
                                    });
                                  }
                                }}
                                inputProps={{
                                  hasCurrencyPrefix: true,
                                  allowNegative: false
                                }}
                              />
                            }
                          </TableCell>
                          <TableCell>
                            {isProrated ?
                              `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)} (${booking.paymentDetails.proratedBillingDays} days pro-rated)` :
                              `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)}`}
                          </TableCell>
                          <TableCell>
                            {"NA"}
                          </TableCell>
                          <TableCell>
                            {toCurrency(
                              Math.round(booking?.insuranceRate * totalVehicles * (booking.paymentDetails?.rentalTime || 1) * proratedFraction),
                              currency,
                              locale
                            )}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      );
                    }
                  })()}
                  {
                    booking?.addonRequirements?.map((addonR: IBookingAddonRequirement, idx: number) => {
                      let taxAmount = 0;
                      let taxDescription = addonR.tax && addonR.tax.title && addonR.tax.value ? `${addonR.tax.title} ${addonR.tax.value}%` : "";
                      if (addonR.hasFixedRate) {
                        taxAmount = addonR.tax && addonR.tax.value ? Math.round((addonR.rate * addonR.tax.value / 100) * addonR.quantity) : 0;
                      } else {
                        taxAmount = addonR.tax && addonR.tax.value ? Math.round((addonR.rate * addonR.tax.value / 100) * addonR.quantity * booking.paymentDetails.rentalTime) : 0;
                      }
                      return (
                        <>
                          <TableRow>
                            {idx === 0 && (
                              <TableCell
                                className={classes.tableCellBorder}
                                rowSpan={booking.addonRequirements.length}
                              >
                                <Typography variant="body1">{"Add-Ons"}</Typography>
                              </TableCell>
                            )}
                            <TableCell colSpan={1}>
                              {addonR.displayName ?
                                addonR.displayName.toUpperCase() :
                                addonR.name.toUpperCase()} <br />
                              {taxDescription && (<span>({taxDescription})</span>)}
                            </TableCell>
                            <TableCell>{addonR.quantity}</TableCell>
                            <TableCell>
                              {!props.isEditable ?
                                toCurrency(
                                  addonR.rate,
                                  currency,
                                  locale
                                ) :
                                <TextField
                                  fullWidth
                                  placeholder="Price/Rate"
                                  label="Price/Rate"
                                  name={"unitPrice"}
                                  InputProps={{
                                    value: addonR.rate,
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const val = e.target.value || "0";
                                      const rowsArr = [...booking.addonRequirements];
                                      addonR.rate = parseInt(val);
                                      rowsArr.splice(idx, 1, addonR);
                                      setBooking({
                                        ...booking,
                                        addonRequirements: rowsArr
                                      });
                                    }
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                />
                              }
                            </TableCell>
                            <TableCell>
                              {addonR.hasFixedRate ?
                                "N/A" :
                                isProrated ?
                                  `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)} (${booking.paymentDetails.proratedBillingDays} days pro-rated)` :
                                  `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)}`
                              }
                            </TableCell>
                            <TableCell>
                              {toCurrency(
                                addonR.hasFixedRate ? taxAmount : Math.round(taxAmount * proratedFraction),
                                currency,
                                locale
                              )}
                            </TableCell>
                            <TableCell>
                              {toCurrency(
                                getAddonRate(addonR, booking.paymentDetails?.rentalTime) + (addonR.hasFixedRate ? taxAmount : Math.round(taxAmount * proratedFraction)),
                                currency,
                                locale
                              )}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow >
                        </>
                      );
                    })
                  }

                  {/* Location surcharge item */}
                  {
                    booking?.locationSurcharges?.map((surcharge: ILocationSurchargeInput, idx: number) => {
                      let taxDescription = surcharge.tax && surcharge.tax.title && surcharge.tax.value ? `${surcharge.tax.title} ${surcharge.tax.value}%` : "";
                      return (
                        <>
                          <TableRow>
                            {idx === 0 && (
                              <TableCell
                                className={classes.tableCellBorder}
                                rowSpan={booking.locationSurcharges.length}
                              >
                                <Typography variant="body1">{"Location Surcharge"}</Typography>
                              </TableCell>
                            )}
                            <TableCell colSpan={1}>
                              {surcharge.name}
                              {surcharge.type === "PERCENTAGE" && (<span> <br /> ({`${surcharge.value}% of total rental amount`}) </span>)}
                              {taxDescription && (<span> <br /> ({taxDescription})</span>)}
                            </TableCell>
                            <TableCell>{surcharge.type === "PERCENTAGE" ? "N/A" : vehicleCount}</TableCell>
                            <TableCell>
                              {!props.isEditable ?
                                surcharge.type === "PERCENTAGE" ? toCurrency(
                                  surcharge.payableAmount || 0,
                                  currency,
                                  locale
                                ) : toCurrency(
                                  surcharge.value,
                                  currency,
                                  locale
                                ) :
                                <TextField
                                  fullWidth
                                  placeholder="Price/Rate"
                                  label="Price/Rate"
                                  name={"unitPrice"}
                                  InputProps={{
                                    value: surcharge.type === 'PERCENTAGE' ? surcharge.payableAmount : surcharge.value,
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const val = e.target.value || "0";
                                      const rowsArr = [...booking.locationSurcharges];
                                      if (surcharge.type === 'PERCENTAGE') {
                                        surcharge.payableAmount = parseInt(val);
                                        surcharge.payableTaxAmount = parseInt(val) * (surcharge.tax.value / 100)
                                      } else {
                                        surcharge.value = parseInt(val);
                                        surcharge.payableAmount = parseInt(val) * vehicleCount;
                                        surcharge.payableTaxAmount = parseInt(val) * vehicleCount * (surcharge.tax.value / 100)
                                      }
                                      rowsArr.splice(idx, 1, surcharge);
                                      setBooking({
                                        ...booking,
                                        locationSurcharges: rowsArr
                                      });
                                    }
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                />
                              }
                            </TableCell>
                            <TableCell>
                              {surcharge.type === "PERCENTAGE" ? "N/A" : surcharge.rate === "PER_DAY" ? `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)}` : "N/A"}
                            </TableCell>
                            <TableCell>
                              {surcharge.tax && surcharge.tax.value ? toCurrency(surcharge.payableTaxAmount || 0, currency, locale) : toCurrency(0, currency, locale)}
                            </TableCell>
                            <TableCell>
                              {surcharge.tax && surcharge.tax.value ?
                                toCurrency(
                                  ((surcharge.payableAmount || 0) + (surcharge.payableTaxAmount || 0)),
                                  currency,
                                  locale
                                ) : toCurrency(
                                  surcharge.payableAmount || 0,
                                  currency,
                                  locale
                                )}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </>
                      );
                    })
                  }
                  {/* One way rental fee */}
                  {(() => {
                    if (booking.oneWayRentalFee) {
                      const totalVehicles = booking.vehicleGroups.reduce(
                        (accumulator, current) => accumulator + current.count,
                        0
                      );
                      const oneWayRentalTax = booking.oneWayRentalFee.taxable ? Math.round((booking.oneWayRentalFee.amount * totalVehicles * booking.oneWayRentalFee.tax.value) / 100) : 0;
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              className={classes.tableCellBorder}
                            >
                              <Typography variant="body1">{"One Way Fee"}</Typography>
                            </TableCell>
                            <TableCell >
                              {'One Way Rental Charge'} <br />
                              {booking.oneWayRentalFee.taxable ? `(${booking.oneWayRentalFee.tax.title} ${booking.oneWayRentalFee.tax.value}%)` : ''}
                            </TableCell>
                            <TableCell>{totalVehicles}</TableCell>
                            <TableCell>
                              {!props.isEditable ?
                                toCurrency(
                                  booking?.oneWayRentalFee.amount,
                                  currency,
                                  locale
                                ) :
                                <TextField
                                  fullWidth
                                  placeholder="Price/Rate"
                                  label="Price/Rate"
                                  name={"unitPrice"}
                                  InputProps={{
                                    value: booking.oneWayRentalFee.amount,
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const val = e.target.value || "0";
                                      setBooking({
                                        ...booking,
                                        oneWayRentalFee: {
                                          ...booking.oneWayRentalFee,
                                          amount: parseInt(val)
                                        }
                                      });
                                    }
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                />
                              }
                            </TableCell>
                            <TableCell>
                              {'NA'}
                            </TableCell>
                            <TableCell>
                              {toCurrency(oneWayRentalTax, currency, locale)}
                            </TableCell>
                            <TableCell>
                              {toCurrency(
                                (booking.oneWayRentalFee.amount * totalVehicles) + oneWayRentalTax,
                                currency,
                                locale
                              )}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </>
                      )
                    }
                  })()}
                </TableBody >
                {
                  paymentDetails.discountAmount > 0 && <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Typography variant="h4">{"DISCOUNT"}</Typography>
                    </TableCell>
                    <TableCell>
                      - {toCurrency(
                        paymentDetails.discountAmount,
                        currency,
                        locale
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
                < TableRow >
                  {booking.taxExempted || booking.zeroCostBooking ? <TableCell colSpan={2}>
                    <Typography variant="h5">
                      {booking.taxExempted && (<p> {`*This is a ${getLocalizedTaxSyntex(country)} exempted booking.`} </p>)}
                      {booking.zeroCostBooking && (<p>*This is a test/courtesy {getLocalizedBookingSyntex(country).toLowerCase()}.</p>)}
                    </Typography>
                  </TableCell> :
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="h4">{"GRAND TOTAL"}</Typography>
                  </TableCell>
                  <TableCell>
                    {toCurrency(
                      paymentDetails.totalPayableAmount,
                      currency,
                      locale
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow >
              </Table >
            </Paper>
          </TableContainer >
          {
            props.isEditable && (
              <Grid container xs={12} justifyContent="flex-end" style={{ marginTop: "20px" }}>
                <Fab
                  variant="extended"
                  size="medium"
                  className="blackBackButton"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    props.setIsEditable(false)
                    setBooking(JSON.parse(JSON.stringify(bookingData)))
                  }}
                >
                  Cancel
                </Fab>
                <Fab
                  variant="extended"
                  size="medium"
                  onClick={handleSubmit}
                >
                  Update
                </Fab>
              </Grid>)
          }
        </Grid >
      </Hidden >
      <Hidden smUp>
        <Grid container xs={12}
          style={{
            background: "#c9c9c9",
            borderRadius: 5,
            padding: 20
          }}>

          {(booking.vehicleGroups.length > 0) ?
            <>
              <Grid item xs={12}>
                <Typography variant={"h4"}>Rental Price</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid alignItems="center" container xs={12}>
                {
                  booking?.vehicleGroups?.map((vehicleGroup: IBookingVehicleGroup, idx: number) => {
                    const vg = vehicleGroup.vehicleGroup as IVehiclePriceGroup;
                    return (
                      <>
                        <Grid item xs={3}>
                          <Typography noWrap variant={"h5"}>{vg.name}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography noWrap variant={"h5"}>
                            {isProrated ?
                              `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)} (${booking.paymentDetails.proratedBillingDays} days pro-rated)` :
                              `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography noWrap variant={"h5"}>
                            {vehicleGroup.count} Vehicles
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography noWrap variant={"h5"}>
                            {toCurrency(
                              (Math.round(getApplicableRentalAmount(
                                vehicleGroup.baseRate,
                                vehicleGroup.priceRuleOperator,
                                vehicleGroup.priceRuleValueType,
                                vehicleGroup.priceRuleAmount
                              ) * vehicleGroup.count * (booking.paymentDetails?.rentalTime || 1)) * proratedFraction),
                              currency,
                              locale
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box mt={1} />
                        </Grid>
                      </>
                    )
                  })
                }
                <Grid item xs={9}>
                  <Typography noWrap variant={"h5"}>Total Rental VAT</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography noWrap variant={"h5"}>
                    {toCurrency((booking.vehicleGroups?.reduce((taxCounter, vg) => {
                      const unitPrice = (getApplicableRentalAmount(
                        vg.baseRate,
                        vg.priceRuleOperator,
                        vg.priceRuleValueType,
                        vg.priceRuleAmount
                      )) * vg.count * booking.paymentDetails.rentalTime;
                      const applicableTaxes = getVehicleGroupApplicableTaxesDetails(booking.tax || [], typeof vg.vehicleGroup === 'string' ? vg.vehicleGroup : vg.vehicleGroup.id)
                      const rentalTaxAmount = booking.tax ? getRentalTaxAmount(applicableTaxes, bookingDurationInDays, unitPrice, vg.count) : 0;
                      return taxCounter + rentalTaxAmount;
                    }, 0)), currency, locale)}
                  </Typography>
                </Grid>
              </Grid>
              <DashedLine />
            </>
            :
            <></>
          }

          {
            booking.insurancePolicy?.id ?
              <>
                <Grid item xs={12}>
                  <Typography variant={"h4"}>Insurance</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1} />
                </Grid>
                <Grid alignItems="center" container xs={12}>
                  {(() => {
                    if (booking.insurancePolicy?.id) {
                      const totalVehicles = booking.vehicleGroups.reduce(
                        (accumulator, current) => accumulator + current.count, 0);
                      return (
                        <>
                          <Grid item xs={3}>
                            <Typography noWrap variant={"h5"}>{booking?.insurancePolicy?.name}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography noWrap variant={"h5"}>
                              {totalVehicles} Vehicles Insured
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography noWrap variant={"h5"}>
                              {toCurrency(
                                Math.round(booking?.insuranceRate * totalVehicles * (booking.paymentDetails?.rentalTime || 1) * proratedFraction),
                                currency,
                                locale
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box mt={1} />
                          </Grid>
                        </>
                      );
                    }
                  })()}
                </Grid>
                <DashedLine />
              </>
              :
              <></>
          }

          {
            (booking?.addonRequirements.length > 0) ?
              <>
                <Grid item xs={12}>
                  <Typography variant={"h4"}>Add-Ons</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1} />
                </Grid>
                <Grid alignItems="center" container xs={12}>
                  {
                    booking?.addonRequirements?.map((addonR: IBookingAddonRequirement, idx: number) => {
                      return (
                        <>
                          <Grid item xs={3}>
                            <Typography noWrap variant={"h5"}>
                              {addonR.addon.displayName ?
                                addonR.addon.displayName.toUpperCase() :
                                addonR.addon.name.toUpperCase()}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography noWrap variant={"h5"}>
                              {addonR.hasFixedRate ?
                                "N/A" :
                                isProrated ?
                                  `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)} (${booking.paymentDetails.proratedBillingDays} days pro-rated)` :
                                  `${booking.paymentDetails?.rentalTime} ${getRateTypeString(booking.rateTypeName)}`
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography noWrap variant={"h5"}>
                              {addonR.quantity} Units
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography noWrap variant={"h5"}>
                              {toCurrency(
                                getAddonRate(addonR, booking.paymentDetails?.rentalTime),
                                currency,
                                locale
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box mt={1} />
                          </Grid>
                        </>
                      );
                    })
                  }
                  <Grid item xs={9}>
                    <Typography noWrap variant={"h5"}>Total Add-On VAT</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography noWrap variant={"h5"}>
                      {toCurrency((booking.addonRequirements?.reduce((taxCounter, addonR) => {
                        let taxAmount = 0;
                        if (addonR.hasFixedRate) {
                          taxAmount = addonR.tax && addonR.tax.value ? ((addonR.rate * addonR.tax.value / 100) * addonR.quantity) : 0;
                        } else {
                          taxAmount = addonR.tax && addonR.tax.value ? ((addonR.rate * addonR.tax.value / 100) * addonR.quantity * booking.paymentDetails.rentalTime) : 0;
                        }
                        let rentalTaxAmount = addonR.hasFixedRate ? taxAmount : Math.round(taxAmount * proratedFraction)
                        return taxCounter + rentalTaxAmount;
                      }, 0)), currency, locale)}
                    </Typography>
                  </Grid>
                </Grid>
                <DashedLine />
              </>
              :
              <></>
          }

          {
            (booking?.locationSurcharges && booking.locationSurcharges.length > 0) ?
              <>
                <Grid item xs={12}>
                  <Typography variant={"h4"}>Location Surcharges</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1} />
                </Grid>
                <Grid alignItems="center" container xs={12}>
                  {
                    booking?.locationSurcharges?.map((surcharge: ILocationSurchargeInput, idx: number) => {
                      const totalVehicles = booking.vehicleGroups.reduce(
                        (accumulator, current) => accumulator + current.count, 0);
                      return (
                        <>
                          <Grid item xs={6}>
                            <Typography noWrap variant={"h5"}>
                              {surcharge.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography noWrap variant={"h5"}>
                              {totalVehicles} Vehicles
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography noWrap variant={"h5"}>
                              {toCurrency(
                                surcharge.value * vehicleCount,
                                currency,
                                locale
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box mt={1} />
                          </Grid>
                        </>
                      );
                    })
                  }
                  <Grid item xs={9}>
                    <Typography noWrap variant={"h5"}>Total Surcharges VAT</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography noWrap variant={"h5"}>
                      {toCurrency((booking?.locationSurcharges?.reduce((taxCounter, surcharge) => {
                        let surchargeTaxAmount = 0;
                        let surchargeAmount = 0;
                        if (surcharge.type === "PERCENTAGE") {
                          surchargeAmount = Math.round(paymentDetails.discountedRentalAmount * surcharge.value / 100);
                          surchargeTaxAmount = Math.round(surchargeAmount * surcharge.tax.value / 100);
                        } else {
                          if (surcharge.tax && surcharge.tax.value) {
                            if (surcharge.rate === "PER_DAY") {
                              surchargeTaxAmount = (surcharge.value * surcharge.tax.value / 100) * bookingDurationInDays * vehicleCount * proratedFraction;
                            } else if (surcharge.rate === "ONE_TIME") {
                              surchargeTaxAmount = (surcharge.value * surcharge.tax.value / 100) * vehicleCount;
                            }
                          }
                        }
                        let rentalTaxAmount = (surcharge.tax && surcharge.tax.value) ? surchargeTaxAmount : 0
                        return taxCounter + rentalTaxAmount;
                      }, 0)), currency, locale)}
                    </Typography>
                  </Grid>
                </Grid>
                <DashedLine />
              </>
              :
              <></>
          }

          {
            paymentDetails.discountAmount > 0 &&
            <>
              <Grid alignItems="center" container xs={12}>
                <Grid item xs={6}>
                  <Typography noWrap variant={"h4"}>
                    {"DISCOUNT"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography align="right" noWrap variant={"h4"}>
                    {"-⠀"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography noWrap variant={"h5"}>
                    {toCurrency(
                      (paymentDetails.discountAmount),
                      currency,
                      locale
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
            </>
          }

          <Grid alignItems="center" container xs={12}>
            <Grid item xs={9}>
              <Typography noWrap variant={"h4"}>
                {"GRAND TOTAL"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography noWrap variant={"h5"}>
                {toCurrency(
                  paymentDetails.totalPayableAmount,
                  currency,
                  locale
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid alignItems="center" container xs={12}>
            <Grid item xs={12}>
              <Typography noWrap variant={"h5"}>
                {booking.zeroCostBooking && (<>*This is a test/courtesy {getLocalizedBookingSyntex(country).toLowerCase()}.</>)}
              </Typography>
            </Grid>
          </Grid>
        </Grid >
      </Hidden >
    </>
  );
};
