import { gql } from "@apollo/client";

export const GET_CURRENT_BOOKING_SCHEDULE = gql`
  query booking($id: String!) {
    booking(id: $id) {
      id
      bookingType
      referenceNumber
      pickupDateTime
      dropoffDateTime
      rateTypeName
      rateTypeDuration
      isRecurringBilling
      billingFrequency
      autoChargeLater
      autoChargeMode
      stripePaymentMethodId
      convergeTokenizedCard
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      isSubscription
      activeSubscription {
        startDate
        endDate
        subscription {
          id
          name
          insurancePolicy {
            name
          }
          addons {
            displayName
          }
        }
        vehicle {
          licencePlate
          make
          model
          vin
          id
        }
        duration
        subscriptionAmount
        enrollmentAmount
        mileageLimit
        unlimitedMileage
        pricePerExtraMile
        swapsAllowed
      }
      currentBookingSchedules {
        id
        vehicleStatus
        vehicle {
          id
          licencePlate
        }
      }
      customerType
      customer {
        email
      }
      businessCustomer {
        contact {
          email
        }
      }
      tax {
        appliedBy
        appliedDate
        code
        amount
        payableAmount
        type
        rate
        subdivisionOptIn
        subdivisionValue
        subdivisionType
        subdivisionRate
        isVehicleGroupSpecific
        vehicleGroups
      }
      insuranceRate
      insurancePolicy {
        id
        name
        description
      }
      addonRequirements {
        addon {
          id
          name
          category
          imageUrl
          hasFixedRate
          hasQuantity
          fixedRate
          description
          displayName
          recurringRate {
            rateTypeName
            rateTypeDuration
            rate
            longTerm
          }
          totalQuantity
          availableQuantity
          isActivated
        }
        rate
        quantity
        name
        hasFixedRate
        displayName
        payableAmount
        tax {
          title
          value
        }
      }
      vehicleGroups {
        vehicleGroup {
          name
          id
          basePrices {
            rateTypeName
            mileageLimit
            unlimitedMileage
          }
        }
        name
        vehicleIds
        baseRate
        priceRuleOperator
        priceRuleValueType
        priceRuleTriggerType
        priceRuleAmount
        priceRuleName
        deposit
        mileageLimit
        unlimitedMileage
        pricePerExtraMile
        count
        activeVehicleSchedules {
          id
          vehicleStatus
          status
          endDate
          vehicle {
            id
            licencePlate
            make
            model
            features
            colour
            pcoNumber
            vin
            branch
            branchDetails {
              id
              name
            }
          }
          dropoffCheck {
            vehicleStatePdfUrl
            vehicleState {
              consumerWalkAroundImages {
                key
                capturedAt
                angle
              }
            }
          }
          pickupCheck {
            vehicleStatePdfUrl
            vehicleState {
              consumerWalkAroundImages {
                key
                capturedAt
                angle
              }
            }
          }
          driver {
            id
            note
          }
        }
      }
      latestExtension {
        referenceNumber
        extensionNumber
        status
        startDate
        endDate
        dropoffServiceLocation {
          id
          name
        }
        vehicleDetails {
          vehicleGroupId
          vehicleIds
          basePrice
          priceRuleAmount
          quantity
          modifiedPrice
          description
        }
        paymentDetails {
          rentalAmount
          startDate
          endDate
          taxAmount
          rentalAmount
          insuranceAmount
          discountAmount
          addonsAmount
          totalPayableAmount
          rentalTime
        }
        addonRequirements {
          addon {
            id
            name
            category
            imageUrl
            hasFixedRate
            hasQuantity
            fixedRate
            description
            displayName
            recurringRate {
              rateTypeName
              rateTypeDuration
              rate
              longTerm
            }
            totalQuantity
            availableQuantity
            isActivated
          }
          rate
          quantity
          name
          hasFixedRate
          displayName
          payableAmount
          tax {
            title
            value
          }
        }
        otherCharges {
          description
          quantity
          unitPrice
        }
      }
    }
  }
`;
