import "../index.scss";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CircularProgress,
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import * as _ from "lodash";
import { DateTime as d } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { CREATE_VEHICLE_FINANCE } from "../../../../../../graphql/fleet/vehicleFinances/createVehicleFinanceMutation";
import { GET_VEHICLE_FINANCE } from "../../../../../../graphql/fleet/vehicleFinances/getVehicleFinance";
import { GET_VEHICLE_FINANCES } from "../../../../../../graphql/fleet/vehicleFinances/getVehicleFinances";
import { UPDATE_VEHICLE_FINANCE } from "../../../../../../graphql/fleet/vehicleFinances/updateVehicleFinanceMutation";
import { ADD_SUBSCRIPTION_TO_VEHICLE } from "../../../../../../graphql/SubscriptionPlans/addSubscriptionToVehicleMutation";
import { GET_SUBSCRIPTIONS } from "../../../../../../graphql/SubscriptionPlans/getSubscriptionsQuery";
import { UPDATE_VEHICLE_SUBSCRIPTION_PRICINGS } from "../../../../../../graphql/SubscriptionPlans/updateVehicleSubscriptionPricingMutation";
import { initialState } from "../../../../../../reducers/fleet/const";
import {
  ISubscriptionInfo,
  IVehicle
} from "../../../../../../reducers/fleet/types";
import {
  ISubscription,
  ISubscriptionPricing
} from "../../../../../../reducers/Subscription/types";
import { IAppState } from "../../../../../../store";
import { captureErrorException } from "../../../../../../utils/sentry";
import FlatPickerBar from "../../../../../common/FlatPicker";
import { FloatInput } from "../../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage
} from "../../../../../common/utils";
import { SourceType, paymentInterval, sourceTypes } from "./constants";
import EditSubscriptionPricingModal from "./EditSubscriptionPricingModal";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { useNavigate } from "react-router-dom";
import { UPDATE_VEHICLE } from "../../../../../../graphql/fleet/updateVehicleMutation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    createButton: {
      background: "#000000",
      color: "#fff",
      fontSize: "12px"
    }
  })
);

interface IFinanceProps {
  vehicle: IVehicle;
  updateVehicle(payload: any): void;
}

export const FinanceView: React.FC<IFinanceProps> = (props) => {
  const styles = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [editDialog, setEditDialog] = useState<boolean>(false);
  const [isSubmitting, setSubmittingButton] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<
    ISubscription[]
  >([]);
  const [subscriptionToAdd, setSubscriptionToAdd] = useState<ISubscription>();
  const [residualValue, setResidualValue] = useState<number>(props.vehicle.residualValue || 0);
  const [holdingCostPerDay, setHoldingCostPerDay] = useState<number>(props.vehicle.holdingCostPerDay || 0);

  const vehicleFinance = useSelector(
    (state: IAppState) => state.fleetReducer.vehicleFinance
  );

  const [values, setValues] = useState(
    _.cloneDeep(initialState.vehicleFinance)
  );

  const [getVehicleFinance, { data: finance }] = useLazyQuery(
    GET_VEHICLE_FINANCE,
    {
      fetchPolicy: "network-only"
    }
  );
  const [
    getvehicleFinances,
    { data: finances }
  ] = useLazyQuery(GET_VEHICLE_FINANCES, { fetchPolicy: "network-only" });

  const [loadSubscriptions, { data }] = useLazyQuery(GET_SUBSCRIPTIONS, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    getvehicleFinances();
  }, []);

  useEffect(() => {
    if (userState.tenancy) {
      loadSubscriptions();
    }
  }, [userState]);

  useEffect(() => {
    if (props.vehicle.residualValue) {
      setResidualValue(props.vehicle.residualValue);
    }
    if (props.vehicle.holdingCostPerDay) {
      setHoldingCostPerDay(props.vehicle.holdingCostPerDay);
    }
  }, [props.vehicle]);

  useEffect(() => {
    if (props.vehicle.id !== "" && finances && finances.vehicleFinances) {
      const getFinance = finances.vehicleFinances.find((el: any) => {
        return el.vehicleId === props.vehicle.id;
      });
      if (getFinance && getFinance.id) {
        getVehicleFinance({
          variables: {
            id: getFinance.id
          }
        });
      } else {
        setValues(vehicleFinance);
      }
    }
  }, [props.vehicle.id, finances]);

  useEffect(() => {
    if (finance && finance.vehicleFinance) {
      setValues(finance.vehicleFinance);
    }
  }, [finance]);

  useEffect(() => {
    if (
      data &&
      data.getVehicleSubscriptions &&
      data.getVehicleSubscriptions.length
    ) {
      const selectedSubscription: ISubscription[] = [];
      const availableSubscription: ISubscription[] = [];
      const vehicleSubscriptions: ISubscriptionInfo[] =
        props.vehicle.subscriptions;
      for (let i = 0; i < data.getVehicleSubscriptions.length; i++) {
        const subscription = data.getVehicleSubscriptions[i];
        if (subscription.isActive) {
          availableSubscription.push(subscription);
        }
        if (props.vehicle.subscriptions && props.vehicle.subscriptions.length) {
          for (let j = 0; j < vehicleSubscriptions.length; j++) {
            const vehicleSubscription = vehicleSubscriptions[j];
            if (subscription.id === vehicleSubscription.subscription.id) {
              selectedSubscription.push(subscription);
            }
          }
        }
      }
      setSelectedSubscriptions(selectedSubscription);
      setSubscriptions(availableSubscription);
    }
  }, [data]);

  const [updateVehicle, { loading: loadingVehicleUpdate }] = useMutation(
    UPDATE_VEHICLE, {
    onCompleted: () =>
      snackbar({
        message: "Vehicle updated sucessfully",
        variant: SnackBarVariant.SUCCESS
      }),
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  }
  );

  const [createVehicleFinance] = useMutation(CREATE_VEHICLE_FINANCE, {
    onCompleted: (data) => {
      snackbar({
        message: "Finance saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
      getVehicleFinance({
        variables: {
          id: data.createVehicleFinance.id
        }
      });
      setSubmittingButton(false);
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateVehicleFinance] = useMutation(UPDATE_VEHICLE_FINANCE, {
    onCompleted: () => {
      snackbar({
        message: "Finance Updated successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setSubmittingButton(false);
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [
    addSubscriptionToVehicle,
    { loading: loadingaddSubscription }
  ] = useMutation(ADD_SUBSCRIPTION_TO_VEHICLE, {
    onCompleted: (data) => {
      if (data && data.addSubscriptionToVehicle) {
        props.updateVehicle({
          ...props.vehicle,
          subscriptions: data.addSubscriptionToVehicle
        });
        handleAddSubscriptionToVehicle(data.addSubscriptionToVehicle);
        snackbar({
          message: "Subscription added to vehicle successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateVehicleSubscriptionPricings] = useMutation(
    UPDATE_VEHICLE_SUBSCRIPTION_PRICINGS,
    {
      onCompleted: () => {
        snackbar({
          message: formatGraphQLErrorMessage("Pricings updated successfully"),
          variant: SnackBarVariant.SUCCESS
        });
        setEditDialog(false);
        navigate(`/update-vehicle?vehicle=${props.vehicle.id}`);
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const handleAddSubscriptionToVehicle = (values: ISubscription[]) => {
    if (!loadingaddSubscription) {
      setSelectedSubscriptions(values);
    }
  };

  const validateForm = (values: any): string | null => {
    switch (values.sourceType) {
      case SourceType.FINANCED:
        if (!values.financedFromDate || !values.financedToDate) {
          return "Please select finance from and to date.";
        }
        break;
      case SourceType.RENTED:
        if (!values.rentedFromDate || !values.rentedToDate) {
          return "Please select rented from and to date.";
        }
        break;
      case SourceType.LEASED:
        if (!values.leasedOn || !values.leasedUntil) {
          return "Please select leased from and to date.";
        }
        break;
      case SourceType.OWNED:
        if (!values.purchasedOn) {
          return "Please select purchased date.";
        }
        break;
      default:
        return null;
    }
    return null;
  };

  const handleFormSubmit = (values: any) => {
    try {
      setSubmittingButton(true);

      const validationError = validateForm(values);
      if (validationError) {
        snackbar({
          message: validationError,
          variant: SnackBarVariant.ERROR
        });
        setSubmittingButton(false);
        return;
      }

      const vehicleFinanceValue = {
        ...values,
        vehicleId: props.vehicle.id,
      };
      delete vehicleFinanceValue.id;
      if (values.id) {
        updateVehicleFinance({
          variables: {
            vehicleFinance: vehicleFinanceValue,
            id: finance.vehicleFinance.id
          }
        });
      } else {
        createVehicleFinance({
          variables: { vehicleFinance: vehicleFinanceValue }
        });
      }
    } catch (err) {
      captureErrorException(err);
      setSubmittingButton(false)
      if (err instanceof Error) {
        snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const getValue = (value: string) => {
    if (d.fromISO(value).isValid) {
      return getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED);
    }
  };

  const updateSubscriptionPricings = (pricings: ISubscriptionPricing[]) => {
    if (subscriptionToAdd?.id) {
      updateVehicleSubscriptionPricings({
        variables: {
          subscriptionId: subscriptionToAdd.id,
          vehicleId: props.vehicle.id,
          pricings: pricings
        }
      });
    }
  };

  const detailsFormSchema = Yup.object().shape({
    paymentInterval: Yup.string().when("sourceType", {
      is: (sourceType: any) => sourceType !== "owned",
      then: schema => schema.required("Please select payment interval.")
    }),
    sourceType: Yup.string().required("Please select a Source Type"),
    financedDeposit: Yup.number().when("sourceType", {
      is: "financed",
      then: schema => schema.test("test-is-valid-phone-number", "", function (
        value: any
      ) {
        if (value > values.financedPrice) {
          return this.createError({
            message: "Financed deposit should not be more than price."
          });
        }
        return true;
      })
    })
  });

  return (
    <Grid container>
      <Grid item xs={12} className={styles.root}>
        <Formik
          enableReinitialize
          validationSchema={detailsFormSchema}
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values);
            setSubmitting(false);
          }}
        >
          {(formikProps) => {
            return (
              <React.Fragment>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h3">Finance Details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControl variant="outlined" fullWidth>
                            <Field
                              component={InputField}
                              name={"sourceType"}
                              fullWidth
                              type="text"
                              select
                              required
                              label="Source Type"
                              value={formikProps.values.sourceType}
                              inputProps={{
                                onChange: (event: any) => {
                                  setValues({
                                    ...values,
                                    sourceType: event.target.value
                                  });
                                }
                              }}
                            >
                              {sourceTypes.map((item: any, index: number) => {
                                return (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </FormControl>
                        </Grid>
                        {formikProps.values.sourceType === SourceType.OWNED && (
                          <React.Fragment>
                            <Grid item xs={12} sm={6} md={3}>
                              <Field
                                component={InputField}
                                placeholder="eg. Company Name"
                                label="Purchased From"
                                required
                                name={"purchasedFrom"}
                                value={formikProps.values.purchasedFrom}
                                inputProps={{
                                  onChange: (event: any) => {
                                    handleChange(event);
                                  }
                                }}
                                fullWidth
                              ></Field>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <Field
                                component={InputField}
                                label="Purchase Price"
                                name={"purchasePrice"}
                                value={formikProps.values.purchasePrice}
                                InputProps={{
                                  inputComponent: FloatInput as any
                                }}
                                required
                                inputProps={{
                                  allowNegative: false,
                                  hasCurrencyPrefix: true,
                                  onChange: (event: any) => {
                                    handleChange(event);
                                  }
                                }}
                                fullWidth
                              ></Field>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <FlatPickerBar
                                handleDateChange={(value: Date) => {
                                  if (!value) {
                                    return;
                                  }
                                  setValues({
                                    ...formikProps.values,
                                    purchasedOn: d
                                      .fromJSDate(value)
                                      .toUTC()
                                      .toISO()
                                  });
                                }}
                                required
                                label={"Purchase Date"}
                                enableTime={false}
                                type={"date-time-picker"}
                                identifier={"purchasedOn"}
                                placeholderValue={"Purchased Date"}
                                value={getValue(values.purchasedOn)}
                                maxDate={d.now().toJSDate()}
                                country={country}
                              />
                            </Grid>
                          </React.Fragment>
                        )}
                        {formikProps.values.sourceType ===
                          SourceType.LEASED && (
                            <React.Fragment>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={InputField}
                                  placeholder="eg. Company Name"
                                  label="Leased From"
                                  name={"leasedFrom"}
                                  value={formikProps.values.leasedFrom}
                                  inputProps={{
                                    onChange: (event: any) => {
                                      handleChange(event);
                                    },
                                    maxLength: 50
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={InputField}
                                  label="Lease Price"
                                  name={"leasePrice"}
                                  required
                                  value={Number(formikProps.values.leasePrice)}
                                  InputProps={{
                                    inputComponent: FloatInput as any
                                  }}
                                  inputProps={{
                                    allowNegative: false,
                                    hasCurrencyPrefix: true,
                                    onChange: (event: any) => {
                                      handleChange(event);
                                    }
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={InputField}
                                    name={"paymentInterval"}
                                    fullWidth
                                    type="text"
                                    select
                                    label="Payment Interval"
                                    required
                                    value={formikProps.values.paymentInterval}
                                    inputProps={{
                                      onChange: (event: any) => {
                                        setValues({
                                          ...values,
                                          paymentInterval: event.target.value
                                        });
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  >
                                    {paymentInterval.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem key={index} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Field>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FlatPickerBar
                                  handleDateChange={(value: Date) => {
                                    if (!value) {
                                      return;
                                    }
                                    setValues({
                                      ...formikProps.values,
                                      leasedOn: d
                                        .fromJSDate(value)
                                        .toUTC()
                                        .toISO()
                                    });
                                  }}
                                  required
                                  enableTime={false}
                                  label={"Leased From Date"}
                                  identifier={"leasedOn"}
                                  placeholderValue={"Select Date"}
                                  value={getValue(values.leasedOn)}
                                  country={country}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FlatPickerBar
                                  handleDateChange={(value: Date) => {
                                    if (!value) {
                                      return;
                                    }
                                    setValues({
                                      ...formikProps.values,
                                      leasedUntil: d
                                        .fromJSDate(value)
                                        .toUTC()
                                        .toISO()
                                    });
                                  }}
                                  required
                                  enableTime={false}
                                  label={"Leased Till Date"}
                                  identifier={"leasedUntil"}
                                  placeholderValue={"Select Date"}
                                  value={getValue(values.leasedUntil)}
                                  country={country}
                                />
                              </Grid>
                            </React.Fragment>
                          )}
                        {formikProps.values.sourceType ===
                          SourceType.FINANCED && (
                            <React.Fragment>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={InputField}
                                  label="Financed From "
                                  name={"financedFrom"}
                                  value={formikProps.values.financedFrom}
                                  inputProps={{
                                    onChange: (event: any) => {
                                      handleChange(event);
                                    },
                                    maxLength: 50
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FlatPickerBar
                                  handleDateChange={(value: Date) => {
                                    if (!value) {
                                      return;
                                    }
                                    setValues({
                                      ...formikProps.values,
                                      financedFromDate: d
                                        .fromJSDate(value)
                                        .toUTC()
                                        .toISO()
                                    });
                                  }}
                                  required
                                  label={"Financed From Date"}
                                  enableTime={false}
                                  type={"date-time-picker"}
                                  identifier={"financedFromDate"}
                                  name={"financedFromDate"}
                                  placeholderValue={"Financed From Date"}
                                  value={getValue(values.financedFromDate)}
                                  country={country}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FlatPickerBar
                                  handleDateChange={(value: Date) => {
                                    if (!value) {
                                      return;
                                    }
                                    setValues({
                                      ...formikProps.values,
                                      financedToDate: d
                                        .fromJSDate(value)
                                        .toUTC()
                                        .toISO()
                                    });
                                  }}
                                  required
                                  label={"Financed To Date"}
                                  enableTime={false}
                                  type={"date-time-picker"}
                                  identifier={"financedToDate"}
                                  placeholderValue={"Financed To Date"}
                                  value={getValue(values.financedToDate)}
                                  country={country}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={InputField}
                                  label="Financed Price"
                                  name={"financedPrice"}
                                  InputProps={{
                                    inputComponent: FloatInput as any
                                  }}
                                  required
                                  inputProps={{
                                    allowNegative: false,
                                    hasCurrencyPrefix: true,
                                    onChange: (event: any) => {
                                      setValues({
                                        ...formikProps.values,
                                        financedPrice: parseInt(
                                          event.target.value
                                        )
                                      });
                                    },
                                    value: formikProps.values.financedPrice
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={InputField}
                                  label="Financed Deposit"
                                  name={"financedDeposit"}
                                  value={Number(
                                    formikProps.values.financedDeposit
                                  )}
                                  InputProps={{
                                    inputComponent: FloatInput as any
                                  }}
                                  required
                                  inputProps={{
                                    allowNegative: false,
                                    hasCurrencyPrefix: true,
                                    onChange: (event: any) => {
                                      handleChange(event);
                                    }
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={InputField}
                                    name={"paymentInterval"}
                                    fullWidth
                                    type="text"
                                    select
                                    required
                                    label="Payment Interval"
                                    value={formikProps.values.paymentInterval}
                                    inputProps={{
                                      onChange: (event: any) => {
                                        setValues({
                                          ...values,
                                          paymentInterval: event.target.value
                                        });
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  >
                                    {paymentInterval.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem key={index} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Field>
                                </FormControl>
                              </Grid>
                            </React.Fragment>
                          )}
                        {formikProps.values.sourceType ===
                          SourceType.RENTED && (
                            <React.Fragment>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={InputField}
                                  label="Rented From "
                                  name={"rentedFrom"}
                                  value={formikProps.values.rentedFrom}
                                  inputProps={{
                                    onChange: (event: any) => {
                                      handleChange(event);
                                    },
                                    maxLength: 50
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FlatPickerBar
                                  handleDateChange={(value: Date) => {
                                    if (!value) {
                                      return;
                                    }
                                    setValues({
                                      ...formikProps.values,
                                      rentedFromDate: d
                                        .fromJSDate(value)
                                        .toUTC()
                                        .toISO()
                                    });
                                  }}
                                  required
                                  label={"Rented From Date"}
                                  enableTime={false}
                                  type={"date-time-picker"}
                                  identifier={"rentedFromDate"}
                                  placeholderValue={"Rented From Date"}
                                  value={getValue(values.rentedFromDate)}
                                  country={country}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FlatPickerBar
                                  handleDateChange={(value: Date) => {
                                    if (!value) {
                                      return;
                                    }
                                    setValues({
                                      ...formikProps.values,
                                      rentedToDate: d
                                        .fromJSDate(value)
                                        .toUTC()
                                        .toISO()
                                    });
                                  }}
                                  required
                                  label={"Rented To Date"}
                                  enableTime={false}
                                  type={"date-time-picker"}
                                  identifier={"rentedToDate"}
                                  placeholderValue={"Rented To Date"}
                                  value={getValue(values.rentedToDate)}
                                  country={country}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={InputField}
                                  label="Rented Price"
                                  name={"rentedPrice"}
                                  value={Number(formikProps.values.rentedPrice)}
                                  InputProps={{
                                    inputComponent: FloatInput as any
                                  }}
                                  required
                                  inputProps={{
                                    allowNegative: false,
                                    hasCurrencyPrefix: true,
                                    onChange: (event: any) => {
                                      handleChange(event);
                                    }
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={InputField}
                                    name={"paymentInterval"}
                                    fullWidth
                                    type="text"
                                    select
                                    required
                                    label="Payment Interval"
                                    value={formikProps.values.paymentInterval}
                                    inputProps={{
                                      onChange: (event: any) => {
                                        setValues({
                                          ...values,
                                          paymentInterval: event.target.value
                                        });
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  >
                                    {paymentInterval.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem key={index} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Field>
                                </FormControl>
                              </Grid>
                            </React.Fragment>
                          )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h3">Valuation Details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {(formikProps.values.sourceType === SourceType.OWNED || formikProps.values.sourceType === SourceType.FINANCED) &&
                          (<><Grid item xs={12} sm={6} md={3}>
                            <FormControl variant="outlined" fullWidth>
                              <Field
                                component={InputField}
                                label="Residual Value"
                                name={"residualValue"}
                                value={formikProps.values.residualValue || 0}
                                InputProps={{
                                  inputComponent: FloatInput as any
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  hasCurrencyPrefix: true,
                                  onChange: (event: any) => {
                                    handleChange(event);
                                  }
                                }}
                                fullWidth
                              ></Field>
                            </FormControl>
                          </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={InputField}
                                  label="Depreciation (%)"
                                  name={"depreciationPercentage"}
                                  value={
                                    formikProps.values.depreciationPercentage || 0
                                  }
                                  type={"number"}
                                  InputProps={{
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                      const { value } = e.target;
                                      const cleanedValue = value.replace(/^0+/, "") || "0";
                                      const numericValue = parseFloat(cleanedValue);

                                      if (numericValue > 100) {
                                        snackbar({
                                          message: `Amount can't be more than 100%`,
                                          variant: SnackBarVariant.ERROR
                                        });
                                        return;
                                      }

                                      if (numericValue < 0) {
                                        snackbar({
                                          message: `Amount can't be less than 0%`,
                                          variant: SnackBarVariant.ERROR
                                        });
                                        return;
                                      }
                                      setValues({
                                        ...values,
                                        depreciationPercentage: numericValue
                                      })
                                    }
                                  }}
                                  fullWidth
                                ></Field>
                              </FormControl>
                            </Grid>
                          </>)}
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControl variant="outlined" fullWidth>
                            <Field
                              component={InputField}
                              label="Vehicle Holding Cost"
                              name={"vehicleHoldingCost"}
                              value={formikProps.values.vehicleHoldingCost || 0}
                              InputProps={{
                                inputComponent: FloatInput as any
                              }}
                              inputProps={{
                                allowNegative: false,
                                hasCurrencyPrefix: true,
                                onChange: (event: any) => {
                                  handleChange(event);
                                }
                              }}
                              fullWidth
                            ></Field>
                          </FormControl>
                        </Grid>
                        {(formikProps.values.sourceType === SourceType.OWNED || formikProps.values.sourceType === SourceType.FINANCED) &&
                          (<>
                            <Grid item xs={3}>
                              <Field
                                component={InputField}
                                label="Road Fund Tax"
                                name={"roadFundTax"}
                                value={formikProps.values.roadFundTax || 0}
                                InputProps={{
                                  inputComponent: FloatInput as any
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  hasCurrencyPrefix: true,
                                  onChange: (event: any) => {
                                    handleChange(event);
                                  }
                                }}
                                fullWidth
                              ></Field>
                            </Grid>
                          </>)}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Fab
                          variant="extended"
                          color="primary"
                          aria-label="save"
                          type="submit"
                          size="medium"
                          disabled={isSubmitting}
                        >
                          Save
                        </Fab>
                      </Grid>
                      <Grid item>
                        {isSubmitting && <CircularProgress size={25} />}
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </React.Fragment>
            );
          }}
        </Formik>
      </Grid>
      {editDialog && subscriptionToAdd && (
        <EditSubscriptionPricingModal
          open={editDialog}
          subscription={subscriptionToAdd}
          pricings={[]}
          onClose={() => setEditDialog(false)}
          handlePricingUpdate={updateSubscriptionPricings}
        />
      )}
    </Grid>
  );
};
