import { useQuery } from "@apollo/client";
import {
  Card,
  CardContent,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
  Tooltip,
  Button,
  alpha,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardActions
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { createStyles, makeStyles } from "@mui/styles";
import CardHeader from "@mui/material/CardHeader";
import SearchIcon from "@mui/icons-material/Search";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { DateTime as d } from "luxon";
import { GET_VEHICLE_GROUP_CRITERIA } from "../../../../../graphql/vehiclePriceGroup/getVehicleGroupCriteria";
import {
  BOOKING_CATEGORY,
  IBookingCreateInput,
  IBookingSubscription,
  IBookingVehicleGroup,
  ISubscriptionArgs,

} from "../../../../../reducers/bookings/types";
import { ISubscriptionInfo, IVehicle } from '../../../../../reducers/fleet/types';
import { IBookingPriceGroup, IRate, IVehicleGroupImage, IVehiclePriceGroup } from "../../../../../reducers/vehiclePriceGroups/types";
import { IAppState } from "../../../../../store";
import { IFilter } from "../../../../common/FilterSection/FilterSection";
import { FiltersView } from "../../../../common/FiltersView/FiltersView";
import { NuvvenExpansionPanel } from "../../../../common/NuvvenExpansionPanel/NuvvenExpansionPanel";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  getFeatureTypeIcon,
  getVehicleTypeIcon,
  toCurrency
} from "../../../../common/utils";
import { capitalizeFirstLetter, CustomerType, FleetTypes, getMileageLimitText, RATE_TYPES } from "../../utils";
import VehicleCounter from "../VehicleCounter";
import InfoIcon from '@mui/icons-material/Info';
import { IPriceRule } from "../../../../../reducers/priceRule/types";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { GET_EXTERNAL_VEHICLE_GROUP_CRITERIA } from "../../../../../graphql/vehiclePriceGroup/getExternalVehicleGroupCriteria";
import { ILocationSurchargeInput, ILocationTaxSurcharge } from "../../../../../reducers/organisation/types";
import theme from "../../../../common/Theme";
import { UserRoles } from "../../../../hoc/Authorization";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../../utils/sentry";
import vehicleGroupIcon from "../../../../../assets/icons/vehicle-group.svg"
import { IOneWayRentalFee } from "../../../VehicleMovement/types";
import SelectSubscriptionVehiclesView from "./SelectSubscriptionVehiclesView";
interface IProps {
  vehicleGroupData: any;
  bookingData: IBookingCreateInput;
  locationSurcharges: ILocationSurchargeInput[];
  onSubmit: (data: IBookingCreateInput, updateOnly?: boolean) => void;
  toggleSubscription: (flag: boolean, rateTypeName: string) => void;
  isUpdate: boolean;
  oneWayRentalFee: IOneWayRentalFee | undefined
  subscriptionVehicles: IVehicle[]
  isLocationDisabled: boolean;
}

interface IVehicleSelection extends IVehicle {
  selected: boolean;
  shared?: boolean;
}
interface IRenderedVehicleGroup extends IVehiclePriceGroup {
  selectedCount: number;
  locationSurcharges?: IPriceRule[];
  vehicles: IVehicleSelection[]
}

interface InfoDisplayProps {
  items: any[];
  title: string;
  maxVisibleItems: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    success: {
      color: "#479F4B"
    },
    error: {
      color: "#D13135"
    },
    strikedText: {
      textDecoration: "line-through"
    },
    inlined: {
      display: "inline-flex"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    pricingGutterBottom: {
      marginBottom: 15
    },
    disableRentals: {
      pointerEvents: "none",
      opacity: 0.4
    }
  })
);

const InfoDisplay: React.FC<InfoDisplayProps> = ({
  items,
  title,
  maxVisibleItems = 2
}) => (
  <>
    <span style={{ position: "relative", bottom: "4px" }}>
      {items.length > maxVisibleItems
        ? items.slice(0, maxVisibleItems).join(", ")
        : items.join(", ")}
    </span>
    {items.length > maxVisibleItems && (
      <span>
        <IconButton
          style={{
            color: "black",
            padding: 0,
            paddingRight: 5,
            marginLeft: 10,
            marginTop: -10
          }}
        >
          <Tooltip
            title={
              <React.Fragment>
                <h3>{title}</h3>
                {items.map((item) => (
                  <p key={item}>{item}</p>
                ))}
              </React.Fragment>
            }
            arrow
            placement="right"
          >
            <InfoIcon />
          </Tooltip>
        </IconButton>
      </span>
    )}
  </>
);

const VehicleSelection: React.FC<IProps> = (props) => {

  const snackbar = useSnackBar();
  const classes = useStyles();
  let price = 0;
  let rentalPrice = 0;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { locale, currency, allowCarSharing } = userState.currentOrganisation;
  const { distanceUnit } = userState.currentBranch;
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<
    string | undefined
  >();
  const [values, setValues] = useState(props.bookingData);
  const [isSharedVehicleBooking, setIsSharedVehicleBooking] = useState<boolean>(false);
  const [selectedLicense, setSelectedLicense] = useState<string>("");
  const [vehicleImages, setVehicleImages] = useState<any>([]);
  const [vehicleGroupsImage, setVehicleGroupsImage] = useState<any[]>([]);
  const [filteredVehicleGroups, setFilteredVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [renderedVehicleGroups, setRenderedVehicleGroups] = useState<IRenderedVehicleGroup[]>([]);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [ownFilters, setOwnFilters] = useState<IFilter[]>([]);
  const [externalFilters, setExternalFilters] = useState<IFilter[]>([]);
  const [locationSurchargeData, setLocationSurchargeData] = useState<ILocationTaxSurcharge[]>([])
  const [searchFilters, setSearchFilters] = useState<{
    [key: string]: string[];
  }>({});
  const [selectedSubscriptionVehicle, setSelectedSubscriptionVehicle] = useState<IVehicleSelection>();
  const [activeSubscriptionData, setActiveSubscriptionData] = useState<Partial<IBookingSubscription>>()
  const [bookingDurationInWeeks, setBookingDurationInWeeks] = useState<number>(0);
  const [bookingDurationInMonths, setBookingDurationInMonths] = useState<number>(0);
  const [oneWayRentalFee, setOneWayRentalFee] = useState<IOneWayRentalFee>();
  const [renderedSubscriptionVehicles, setRenderedSubscriptionVehicles] = useState<IVehicle[]>([])
  const { data: criteriaData } = useQuery(GET_VEHICLE_GROUP_CRITERIA,
    {
      fetchPolicy: "network-only"
    }
  );
  const { data: externalCriteriaData } = useQuery(GET_EXTERNAL_VEHICLE_GROUP_CRITERIA,
    {
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (props.bookingData?.activeSubscriptionExpanded) {
      const data = props.bookingData.activeSubscriptionExpanded
      setActiveSubscriptionData(data)
    }
  }, [props.bookingData]);

  useEffect(() => {
    if (props.oneWayRentalFee) {
      setOneWayRentalFee(props.oneWayRentalFee);
    }
  }, [props.oneWayRentalFee]);

  useEffect(() => {
    const surChargeData: ILocationTaxSurcharge[] = [];
    if (props.locationSurcharges && props.locationSurcharges.length) {
      props.locationSurcharges.forEach((surcharge: ILocationSurchargeInput) => {
        surChargeData.push({
          id: surcharge.id || "",
          name: surcharge.title,
          value: surcharge.value,
          rate: surcharge.rate,
          type: surcharge.type,
          tax: {
            title: surcharge.taxable ? surcharge.tax.title : "",
            value: surcharge.taxable ? surcharge.tax.value : 0
          }
        });
      })
    }
    if (surChargeData && surChargeData.length) {
      setLocationSurchargeData(surChargeData);
    }
  }, [props.locationSurcharges]);

  useEffect(() => {
    if (props.bookingData) {
      if (props.bookingData.bookingCategory === BOOKING_CATEGORY.RENTAL) {
        const tempVehicleGroups = JSON.parse(JSON.stringify(props.vehicleGroupData));
        getVehicleGroupImage(tempVehicleGroups);
        let tempBookingVg = [...props.bookingData.vehicleGroups];
        props.bookingData.vehicleGroups.map((bookingVg, bookingVgIndex) => {                   //if selected vehicle GROUP is not available
          const groupIndex = tempVehicleGroups.findIndex((item: { id: any; }) => item.id === bookingVg.vehicleGroup);
          if (groupIndex < 0) {
            tempBookingVg.splice(bookingVgIndex, 1);
          }
        });
        setValues({
          ...props.bookingData,
          vehicleGroups: tempBookingVg
        });
      }
      setBookingDurationInMonths(d.fromISO(props.bookingData.dropoffDateTime || "").diff(d.fromISO(props.bookingData.pickupDateTime), ["months"]).months)
      setBookingDurationInWeeks(d.fromISO(props.bookingData.dropoffDateTime || "").diff(d.fromISO(props.bookingData.pickupDateTime), ["weeks"]).weeks)
    }
  }, [props.bookingData.vehicleGroups, props.vehicleGroupData]);

  useEffect(() => {
    if (criteriaData) {
      const { vehicleGroupCriteria } = criteriaData;
      if (vehicleGroupCriteria) {
        const fetchedCriteria = formatCriteriaForFilter(vehicleGroupCriteria);
        setOwnFilters(fetchedCriteria)
        const filtersData = mergeFiltersArray(fetchedCriteria, externalFilters)
        setFilters(filtersData)
      }
    }
  }, [criteriaData]);

  useEffect(() => {
    if (externalCriteriaData) {
      const { externalVehicleGroupCriteria } = externalCriteriaData;
      if (externalVehicleGroupCriteria) {
        const fetchedCriteria = formatCriteriaForFilter(externalVehicleGroupCriteria);
        setExternalFilters(fetchedCriteria)
        const filtersData = mergeFiltersArray(fetchedCriteria, ownFilters)
        setFilters(filtersData)
      }
    }
  }, [externalCriteriaData]);

  useEffect(() => {
    if (props.bookingData.bookingCategory !== BOOKING_CATEGORY.SUBSCRIPTION) {
      const filteredArr = JSON.parse(JSON.stringify(props.vehicleGroupData));
      const filteredVehicleGroupArr = [];
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < filteredArr.length; index++) {
        const element = filteredArr[index];
        element.imageUrl = ""
        const availableVehicles = [];
        const ghostVehicles = [];
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < element.vehicles.length; j++) {
          const vehicle: any = element.vehicles[j];
          let available = true;
          if (selectedLicense && vehicle.licencePlate.toLowerCase() !== selectedLicense.toLowerCase()) {
            available = false;
          }
          // tslint:disable-next-line: prefer-for-of
          for (let k = 0; k < Object.keys(searchFilters).length; k++) {
            if (searchFilters[Object.keys(searchFilters)[k]].length) {
              if (!vehicle.hasOwnProperty(Object.keys(searchFilters)[k])) {
                available = false;
                break;
              }
              if (["year", "numberOfDoors", "numberOfSeats"].includes(Object.keys(searchFilters)[k])) {
                const filtersArr = searchFilters[Object.keys(searchFilters)[k]].map((item) => parseInt(item));
                if (!filtersArr.includes(vehicle[Object.keys(searchFilters)[k]])) {
                  available = false;
                  break;
                }
              } else if (Object.keys(searchFilters)[k] === "features") {
                let flag = true;
                if (vehicle[Object.keys(searchFilters)[k]].length) {
                  vehicle[Object.keys(searchFilters)[k]].forEach((item: string) => {
                    if (searchFilters[Object.keys(searchFilters)[k]].includes(item)) {
                      flag = false;
                    }
                  });
                }
                if (flag) {
                  available = false;
                  break;
                }
              } else {
                if (!searchFilters[Object.keys(searchFilters)[k]].includes(
                  vehicle[Object.keys(searchFilters)[k]].toLowerCase())) {
                  available = false;
                  break;
                }
              }
            }
          }
          if (available) {
            if (userState.currentBranch.overbookingAllowed) {
              if (vehicle.isGhostVehicle) {
                ghostVehicles.push(vehicle);
              } else {
                availableVehicles.push(vehicle);
              }
            }
            else {
              if (!vehicle.isGhostVehicle) {
                availableVehicles.push(vehicle);
              }
            }
          }
        }
        element.vehicles = [...availableVehicles, ...ghostVehicles];
        if (element.vehicles.length > 0) {
          filteredVehicleGroupArr.push(element);
        }
      }
      setFilteredVehicleGroups(filteredVehicleGroupArr);
    } else {
      const availableVehicles = [];
      // tslint:disable-next-line: prefer-for-of
      const vehicles = JSON.parse(JSON.stringify(props.subscriptionVehicles))
      for (let i = 0; i < vehicles.length; i++) {
        const vehicle: any = vehicles[i];
        let available = true;
        if (selectedLicense && vehicle.licencePlate.toLowerCase() !== selectedLicense.toLowerCase()) {
          available = false;
        }
        // tslint:disable-next-line: prefer-for-of
        for (let k = 0; k < Object.keys(searchFilters).length; k++) {
          if (searchFilters[Object.keys(searchFilters)[k]].length) {
            if (!vehicle.hasOwnProperty(Object.keys(searchFilters)[k])) {
              available = false;
              break;
            }
            if (["year", "numberOfDoors", "numberOfSeats"].includes(Object.keys(searchFilters)[k])) {
              const filtersArr = searchFilters[Object.keys(searchFilters)[k]].map((item: string) => parseInt(item));
              if (!filtersArr.includes(vehicle[Object.keys(searchFilters)[k]])) {
                available = false;
                break;
              }
            } else if (Object.keys(searchFilters)[k] === "features") {
              let flag = true;
              if (vehicle[Object.keys(searchFilters)[k]].length) {
                vehicle[Object.keys(searchFilters)[k]].forEach((item: string) => {
                  if (searchFilters[Object.keys(searchFilters)[k]].includes(item)) {
                    flag = false;
                  }
                });
              }
              if (flag) {
                available = false;
                break;
              }
            } else {
              if (!searchFilters[Object.keys(searchFilters)[k]].includes(
                vehicle[Object.keys(searchFilters)[k]].toLowerCase())) {
                available = false;
                break;
              }
            }
          }
        }
        if (available) {
          availableVehicles.push(vehicle)
        }
      }
      setRenderedSubscriptionVehicles(availableVehicles)
    }
  }, [searchFilters, selectedLicense, props.vehicleGroupData, props.subscriptionVehicles]);


  useEffect(() => {
    let bookingVehicleGroups = values.vehicleGroups && values.vehicleGroups.length ? [...values.vehicleGroups] : [];
    let tempGroupArray: IRenderedVehicleGroup[] = [];
    tempGroupArray = filteredVehicleGroups.length ? filteredVehicleGroups.map((vg) => {
      let selectedCount: number = 0;
      let tempVehiclesArray: IVehicleSelection[] = vg.vehicles.map((vehicle) => {
        return {
          ...vehicle,
          selected: false
        }
      });
      if (bookingVehicleGroups.length) {
        bookingVehicleGroups.map((bookingVg, bookingVgIndex) => {
          if (bookingVg.vehicleGroup === vg.id) {
            bookingVg.vehicleIds.map((vehicleId, vehicleIdIndex) => {
              const vehicleIndex = tempVehiclesArray.findIndex(item => item.id === vehicleId)
              if (vehicleIndex >= 0) {
                selectedCount += 1;
                tempVehiclesArray[vehicleIndex].selected = true;
              }
            })
          }
          else {
            bookingVg.vehicleIds.map((vehicleId) => {
              const vehicleIndex = tempVehiclesArray.findIndex(item => item.id === vehicleId)
              if (vehicleIndex >= 0) {
                tempVehiclesArray.splice(vehicleIndex, 1);
              }
            })
          }
        })
      }
      return {
        ...vg,
        selectedCount,
        vehicles: tempVehiclesArray
      }
    }) : []
    setRenderedVehicleGroups(tempGroupArray);
  }, [filteredVehicleGroups])

  const formatCriteriaForFilter: any = (data: any) => {
    const filter = {} as any;
    for (let i = 0; i < data.length; i++) {
      if (data[i].values?.length) {
        let values = data[i].values.map((value: string) => value.toLowerCase())
        values = Array.from(new Set(values))
        filter[data[i].type] = {
          id: data[i].type,
          name: _.startCase(data[i].type),
          options: values.map((val: string) => {
            const formattedName = val.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            return {
              id: val,
              name: formattedName
            }
          })
        };
      }
    }
    return Object.values(filter);
  };

  const handleFilterUpdate = (filters: { [key: string]: string[] }) => {
    setSearchFilters({
      ...searchFilters,
      ...filters
    });
  };

  const mergeFiltersArray = (arr1: IFilter[], arr2: IFilter[]) => {
    const data: IFilter[] = [];
    arr1.forEach(item => {
      if (item.options) {
        const idx: number = data.findIndex(ele => ele.id === item.id);
        if (idx < 0) {
          data.push(item);
        } else {
          const arrItem = { ...data[idx] };
          if (arrItem) {
            item.options.forEach((option) => {
              const itemIdx = arrItem.options.findIndex(ele => ele.id === option.id)
              if (itemIdx < 0) {
                arrItem.options.push(option)
              }
            })
            data[idx] = arrItem;
          }
        }
      }
    })
    arr2.forEach(item => {
      if (item.options) {
        const idx: number = data.findIndex(ele => ele.id === item.id);
        if (idx < 0) {
          data.push(item);
        } else {
          const arrItem = { ...data[idx] };
          if (arrItem) {
            item.options.forEach((option) => {
              const itemIdx = arrItem.options.findIndex(ele => ele.id === option.id)
              if (itemIdx < 0) {
                arrItem.options.push(option)
              }
            })
            data[idx] = arrItem;
          }
        }
      }
    })
    const yearIdx = data.findIndex(ele => ele.id === "year");
    if (yearIdx) {
      const yearItem = data[yearIdx];
      if (yearItem && yearItem.options) {
        yearItem.options = yearItem.options.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      }
      data[yearIdx] = yearItem
    }
    return data;
  }

  const getVehicleImage = (vehicleGroupsList: IVehiclePriceGroup) => {
    vehicleGroupsList.vehicles.map(async (vehicle: any) => {
      try {
        if (vehicle && vehicle.imageUrl) {
          const file = await getSignedUrl(vehicle.imageUrl)
          setVehicleImages([...vehicleImages, {
            vehicleId: vehicle.id,
            imageUrl: file
          }]);
        }
      } catch (error) {
        captureErrorException(error)
        snackbar({
          message: "Invalid File Type. Allowed type: jpeg or png",
          variant: SnackBarVariant.ERROR
        });
      }
    });
  };

  const getDoorString = (item: IBookingPriceGroup) => {
    const doorSet = new Set();
    item.vehicles.forEach((vehicle: IVehicle) => {
      doorSet.add(vehicle.numberOfDoors);
    });
    let outputArr = Array.from(doorSet);
    outputArr = outputArr.filter((item: any) => item > 0).sort();
    return (
      <span>
        <img
          style={{ height: "18px", width: "18px", marginRight: "10px" }}
          src={getFeatureTypeIcon("numberOfDoors")}
          alt={"numberOfDoors"}
        />
        <span style={{ position: "relative", bottom: "4px" }}>
          {outputArr.join(", ")}
          {" Doors"}
        </span>
      </span>
    );
  };

  const getSeatString = (item: IBookingPriceGroup) => {
    const seatSet = new Set();
    item.vehicles.forEach((vehicle: IVehicle) => {
      seatSet.add(vehicle.numberOfSeats);
    });
    let outputArr = Array.from(seatSet);
    outputArr = outputArr.filter((item: any) => item > 0).sort();
    return (
      <span>
        <img
          style={{ height: "18px", width: "18px", marginRight: "10px" }}
          src={getFeatureTypeIcon("numberOfSeats")}
          alt={"numberOfSeats"}
        />
        <span style={{ position: "relative", bottom: "4px" }}>
          {outputArr.join(", ")}
          {" Seats"}
        </span>
      </span>
    );
  };

  const getTransmissionString = (item: IBookingPriceGroup) => {
    const transmmissionSet = new Set();
    item.vehicles.forEach((vehicle: IVehicle) => {
      transmmissionSet.add(vehicle.transmission.toUpperCase());
    });
    const outputArr = Array.from(transmmissionSet);
    return (
      <span>
        <img
          style={{ height: "18px", width: "18px", marginRight: "10px" }}
          src={getFeatureTypeIcon("transmission")}
          alt={"transmission"}
        />
        <InfoDisplay
          items={outputArr}
          title="Transmission Type"
          maxVisibleItems={2}
        />
      </span>
    );
  };

  const getFuelTypeString = (item: IBookingPriceGroup) => {
    const fuelSet = new Set();
    item.vehicles.forEach((vehicle: IVehicle) => {
      fuelSet.add(vehicle.fuelType.toUpperCase());
    });
    const outputArr = Array.from(fuelSet);
    return (
      <span>
        <img
          style={{ height: "18px", width: "18px", marginRight: "10px" }}
          src={getFeatureTypeIcon("fuelType")}
          alt={"fuelType"}
        />
        <InfoDisplay items={outputArr} title="Fuel Type" maxVisibleItems={2} />
      </span>
    );
  };

  const sortVehicleGroups = (groups: IRenderedVehicleGroup[]) => {
    const sortedVehicleGroups: IRenderedVehicleGroup[] = [];
    const mappedGroups = groups.map((vg: IRenderedVehicleGroup) => {
      const groupItem: any = {};
      groupItem.id = vg.id;
      groupItem.price = 0;
      const basePrice = vg.basePrices.find((item) => item.rateTypeName === values.rateTypeName);
      if (basePrice) {
        groupItem.price = basePrice.applicableAmount ? basePrice.applicableAmount : basePrice.rate
      }
      return groupItem;
    }).sort((a, b) => a.price > b.price ? 1 : -1);
    mappedGroups.forEach((mappedGroup) => {
      const vehicleGroup = groups.find((group) => group.id === mappedGroup.id);
      if (vehicleGroup) {
        sortedVehicleGroups.push(vehicleGroup)
      }
    })
    return sortedVehicleGroups;
  }

  const renderGroupBasePrice = (item: IRenderedVehicleGroup) => {
    const basePrice = item.basePrices.find((price: IRate) => price.rateTypeName === values.rateTypeName);
    let totalSurcharge = 0;
    if (basePrice) {
      if (basePrice.applicableAmount) {
        price = basePrice.applicableAmount;
        rentalPrice = basePrice.applicableAmount;
      } else {
        price = basePrice.rate;
        rentalPrice = basePrice.rate;
      }
      if (locationSurchargeData && locationSurchargeData.length) {
        locationSurchargeData.forEach((surcharge: ILocationTaxSurcharge) => {
          if (surcharge.type === "PERCENTAGE") {
            totalSurcharge = totalSurcharge + (rentalPrice * surcharge.value / 100);
          } else {
            totalSurcharge = totalSurcharge + surcharge.value;
          }
        })
      }
      if (oneWayRentalFee && oneWayRentalFee.amount > 0) {
        price = price + oneWayRentalFee.amount
      }
      if (totalSurcharge) {
        price = price + totalSurcharge;
      }
      return <span>
        {(!basePrice.applicableAmount && !totalSurcharge && !oneWayRentalFee?.amount) ?
          (
            <Typography variant={"h4"}>
              Price: {toCurrency(basePrice.rate, currency, locale)}
            </Typography>
          ) : (
            <>
              {(totalSurcharge > 0 && !basePrice.applicableAmount) ? (
                <>
                  <Typography
                    variant={"h4"}
                  >
                    Current Price: {toCurrency(price, currency, locale)}
                  </Typography>
                  <Typography variant={"h4"} className={classes.strikedText}>
                    Original Price: {toCurrency(basePrice.rate, currency, locale)}
                  </Typography>
                </>
              ) : (
                <>
                  {oneWayRentalFee && oneWayRentalFee.amount && !basePrice.applicableAmount ? (
                    <>
                      <Typography
                        variant={"h4"}
                      >
                        Current Price: {toCurrency(price, currency, locale)}
                      </Typography>
                      <Typography variant={"h4"} className={classes.strikedText}>
                        Original Price: {toCurrency(basePrice.rate, currency, locale)}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant={"h4"}
                      >
                        Current Price: {toCurrency(price, currency, locale)}
                      </Typography>
                      <Typography variant={"h4"} className={classes.strikedText}>
                        Original Price: {toCurrency(basePrice.rate, currency, locale)}
                      </Typography>
                      <Typography variant={"h4"} >
                        Price Rule: {basePrice.priceRuleName}
                      </Typography>
                    </>
                  )}
                </>
              )}
            </>
          )}
      </span>;
    }
  };

  const onSelectSubscription = (selectedSubscription: ISubscriptionArgs, vehicle: IVehicle, startDate: string) => {
    const booking = { ...values }
    if (selectedSubscription && selectedSubscription?.subscription && selectedSubscription.subscription?.id) {
      const weeklySubscription = selectedSubscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? true : false
      const durationKey = selectedSubscription.subscription.rateTypeName === "monthly" ? "month" : "week";

      const currentBranch = userState.currentBranch
      const vehicleCurrentLocation = vehicle.currentServiceLocation?.isDisabled ? null : vehicle.currentServiceLocation
      const activeServiceLocation = currentBranch?.locations.filter(location => !location.isDisabled)
      const currentLocation = vehicleCurrentLocation ? vehicleCurrentLocation : activeServiceLocation[0]
      const vehiclePreparationTime = currentBranch?.vehiclePreparationTime
      const availableTimeMinutes = d.fromISO(startDate || "").minute
      const roundToMultipleOf15 = Math.ceil(availableTimeMinutes / 15) * 15;

      startDate = vehiclePreparationTime ? d.fromISO(startDate).set({ minute: roundToMultipleOf15 + vehiclePreparationTime + 15, millisecond: 0 }).toUTC().toISO() : d.fromISO(startDate).set({ minute: roundToMultipleOf15 + 15, millisecond: 0 }).toUTC().toISO()
      const endDate = d.fromISO(startDate || "").plus({ [durationKey]: selectedSubscription?.pricing?.duration }).toUTC().toISO()

      const activeSubscription = {
        duration: selectedSubscription.pricing.duration,
        endDate,
        enrollmentAmount: selectedSubscription.pricing.enrollmentAmount,
        subscriptionAmount: selectedSubscription.pricing.subscriptionAmount,
        startDate: startDate,
        subscription: selectedSubscription?.subscription?.id,
        vehicle: vehicle?.id || "",
        unlimitedMileage: selectedSubscription.pricing.unlimitedMileage,
        mileageLimit: selectedSubscription.pricing.mileage,
        pricePerExtraMile: selectedSubscription.pricing.pricePerExtraMile,
        swapsAllowed: selectedSubscription.pricing.swapsAllowed,
        deposit: selectedSubscription.deposit
      }
      setActiveSubscriptionData({
        ...activeSubscription,
        subscription: selectedSubscription.subscription,
        vehicle: vehicle
      })
      const updatedBooking = {
        ...booking,
        vehicleGroups: [],
        isSubscription: true,
        activeSubscription,
        pickupServiceLocation: currentLocation.id,
        dropoffServiceLocation: currentLocation.id,
        pickupDateTime: startDate,
        dropoffDateTime: endDate,
        billingCycleDuration: weeklySubscription ? 10080 : 43200,
        billingCycleName: weeklySubscription ? RATE_TYPES.WEEKLY : RATE_TYPES.MONTHLY,
        rateTypeDuration: weeklySubscription ? 10080 : 43200,
        rateTypeName: weeklySubscription ? RATE_TYPES.WEEKLY : RATE_TYPES.MONTHLY,
        activeSubscriptionExpanded: {
          ...activeSubscription,
          subscription: selectedSubscription.subscription,
          vehicle: vehicle
        },
        locationSurcharges: [],

      }
      props.onSubmit(updatedBooking);
    }

  }

  const removeSubscription = () => {
    setValues({
      ...values,
      isSubscription: false,
      vehicleGroups: [],
      insurancePolicy: "",
      insuranceRate: 0,
      addonRequirements: []
    })
  }

  const renderVehicleCards = (item: IRenderedVehicleGroup) => {
    const vehiclesToRender = userState.currentOrganisation.crossLocationBookingEnabled ? _.sortBy(item.vehicles, (v) => v.awayDistance) : item.vehicles || [];
    return vehiclesToRender.map((vehicle: IVehicleSelection, idx: number) => {
      if (vehicleImages) {
        vehicleImages.find((vehicleImage: any, index: number) => {
          if (vehicleImage.vehicleId === vehicle.id) {
            vehicle.imageUrl = vehicleImage.imageUrl;
          }
        });
      }

      return (
        <Card
          key={idx}
          style={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            marginBottom: "20px",
            marginLeft: "20px",
            width: "95%",
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item sm={3} style={{ padding: "0px 10px 0px 0px" }}>
                {vehicle.isGhostVehicle && (
                  <>
                    <a style={{ color: "red" }}>
                      Virtual vehicle
                    </a>
                  </>
                )}
                {vehicle.imageUrl ? (
                  <img
                    className="fleet-resrc-img"
                    alt="vehicle type icon"
                    src={vehicle.imageUrl}
                    height="90"
                    width="180"
                    style={{ width: "100%", height: "85%", borderRadius: 4 }}
                  />
                ) : (
                  <img
                    className="fleet-resrc-img"
                    alt="vehicle type icon"
                    src={getVehicleTypeIcon(
                      capitalizeFirstLetter(vehicle.bodyType)
                    )}
                    style={{ width: "100%", height: "85%", borderRadius: 4 }}
                  />
                )}
              </Grid>
              <Grid item sm={9}>
                <Grid container>
                  <Grid item sm={9}>
                    <Grid item sm={12}>
                      <Typography variant={"body2"}>
                        {`${vehicle.model} ${vehicle.make} | ${vehicle.year} | ${vehicle.colour} | ${vehicle.bodyType}`}
                      </Typography>
                    </Grid>
                    <Grid container item sm={12} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                      <Grid item sm={6}>{vehicle.licencePlate?.toUpperCase()}</Grid>
                      {vehicle.shared && (
                        <Grid item sm={6} style={{ background: "#253858", color: "#F4F5F7", fontWeight: 600, maxWidth: "fit-content", padding: "3px" }}>{vehicle.branchDetails?.name || ""}</Grid>
                      )}
                    </Grid>
                    <Grid container item sm={12}>
                      <Grid item sm={3}>
                        <img
                          style={{
                            height: "18px",
                            marginRight: "10px",
                            width: "18px",
                          }}
                          src={getFeatureTypeIcon("numberOfDoors")}
                          alt={"numberOfDoors"}
                        />
                        <span>{`${vehicle.numberOfDoors} Doors`}</span>
                      </Grid>
                      <Grid item sm={3}>
                        <img
                          style={{
                            height: "18px",
                            marginRight: "10px",
                            width: "18px",
                          }}
                          src={getFeatureTypeIcon("numberOfSeats")}
                          alt={"numberOfSeats"}
                        />
                        <span>{`${vehicle.numberOfSeats} Seats`}</span>
                      </Grid>
                      <Grid item sm={3}>
                        <img
                          style={{
                            height: "18px",
                            marginRight: "10px",
                            width: "18px",
                          }}
                          src={getFeatureTypeIcon("transmission")}
                          alt={"transmission"}
                        />
                        <span>{vehicle.transmission}</span>
                      </Grid>
                      <Grid item sm={3}>
                        <img
                          style={{
                            height: "18px",
                            marginRight: "10px",
                            width: "18px",
                          }}
                          src={getFeatureTypeIcon("fuelType")}
                          alt={"fuelType"}
                        />
                        <span>{vehicle.fuelType}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            updateVehicleSelection(item, !vehicle.selected, vehicle.id)
                          }}
                          checked={vehicle.selected}
                          color="primary"
                        />
                      }
                      label="Select"
                    />
                    {userState.currentOrganisation.crossLocationBookingEnabled &&
                      <h3>Away : {userState.currentBranch.distanceUnit === 'kilometer' ? `${vehicle.awayDistance} km` :
                        `${Math.round(((vehicle.awayDistance || 0) * 0.621371))} miles`}
                      </h3>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    });
  };

  const getVehicleGroupImage = (vehicleGroupsList: IVehiclePriceGroup[]) => {
    vehicleGroupsList.map(async (vehicleGroup: any) => {
      try {
        if (vehicleGroup?.images?.length) {
          const imageUrls: IVehicleGroupImage[] = await Promise.all(vehicleGroup.images.map(async (image: any) => {
            const url = await getSignedUrl(image.url);
            return {
              default: image.default,
              idx: image.idx,
              url: url,
            };
          }))
          const sortedImages = imageUrls.sort((a: any, b: any) => b.default - a.default)
          setVehicleGroupsImage([...vehicleGroupsImage, {
            vehicleGroupId: vehicleGroup.id,
            images: sortedImages
          }]);
        }
      } catch (error) {
        captureErrorException(error)
        snackbar({
          message: "Invalid File Type. Allowed type: jpeg or png",
          variant: SnackBarVariant.ERROR
        });
      }
    });
  };

  const renderVehicleGroups = () => {
    if (renderedVehicleGroups.length) {
      const sortedGroups = sortVehicleGroups(renderedVehicleGroups);
      const subscriptions: ISubscriptionInfo[] = [];
      return sortedGroups.map((item: any, idx: number) => {
        item.vehicles.forEach((vehicle: IVehicleSelection) => {
          if (vehicle.subscriptions && vehicle.subscriptions.length > 0) {
            subscriptions.push(...vehicle.subscriptions)
          }
        })
        vehicleGroupsImage.forEach((vgImg: any) => {
          if (vgImg.vehicleGroupId === item.id) {
            item.imageUrl = vgImg.images[0].url;
          }
        });
        const basePrice = item.basePrices.find((price: IRate) => price.rateTypeName === values.rateTypeName);
        let isPriceZero = false;
        if (basePrice) {
          if (basePrice.hasOwnProperty("applicableAmount") && typeof basePrice.applicableAmount === "number") {
            if (basePrice.applicableAmount === 0) {
              isPriceZero = true;
            }
          } else {
            if (basePrice.rate === 0) {
              isPriceZero = true;
            }
          }
        }
        const vehiclesCount = item.vehicles.length;
        if (item.vehicles.length && !isPriceZero) {
          return (
            <Grid
              container
              item
              key={idx}
              sm={12}
              style={{
                marginLeft: "20px",
                marginTop: "20px",
                pointerEvents: values.isSubscription ? "none" : "auto",
                opacity: values.isSubscription ? 0.4 : ""
              }}
            >
              <Card
                style={{
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  position: "relative",
                  height: 'fit-content',
                  width: "95%",
                }}
              >
                <CardHeader
                  style={{ backgroundColor: userState.tenancy.themeColor ? "var(--theme-primary)" : "#2A9CB7", color: "white" }}
                  title={
                    <Grid container item sm={12}>
                      <Grid item xs={12} sm={3}>
                        {item.name.toUpperCase()}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography variant={"body1"}>
                          Available: {vehiclesCount}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                      </Grid>
                      {
                        props.bookingData.bookingCategory === BOOKING_CATEGORY.RENTAL && (
                          <Grid container item xs={12} sm={3} alignItems="flex-start">
                            <Typography variant={"h5"}>
                              <Typography variant={"body1"} className={classes.inlined}>
                                {renderGroupBasePrice(item)}
                              </Typography>
                              <IconButton style={{
                                color: "black",
                                padding: 0,
                                paddingRight: 5,
                                marginLeft: 10
                              }}>
                                <Tooltip
                                  title={
                                    <React.Fragment>
                                      <h5>Price Specification</h5>
                                      <p>{`Rental (1 x ${toCurrency(rentalPrice, currency, locale)}) = ${toCurrency(rentalPrice, currency, locale)}`}</p>
                                      {
                                        locationSurchargeData && locationSurchargeData.length ?
                                          locationSurchargeData.map((surcharge: ILocationTaxSurcharge) => (
                                            <div>
                                              <p>{surcharge.name} = {surcharge.type === "PERCENTAGE" ? toCurrency(rentalPrice * surcharge.value / 100, currency, locale) : toCurrency(surcharge.value, currency, locale)}</p>
                                            </div>
                                          )) : ""
                                      }
                                      {
                                        props.oneWayRentalFee && props.oneWayRentalFee.amount > 0 && (
                                          <div>
                                            <p> One Way Fee = {toCurrency(props.oneWayRentalFee.amount, currency, locale)}</p>
                                          </div>
                                        )
                                      }
                                    </React.Fragment>
                                  }
                                  arrow
                                  placement={"right"}
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </IconButton>
                            </Typography>
                          </Grid>
                        )
                      }
                    </Grid>
                  }
                ></CardHeader>
                <CardContent>
                  <Grid container>
                    <Grid item sm={2}>
                      {item.imageUrl ? (
                        <img
                          src={item.imageUrl}
                          alt="Vehicle Group"
                          style={{ width: "90%", marginLeft: "-10px", marginRight: "10px" }}
                        />
                      ) : (
                        <img
                          src={vehicleGroupIcon}
                          alt="Vehicle Group"
                          style={{ width: "90%", marginLeft: "-10px", marginRight: "10px" }}
                        />
                      )}
                    </Grid>
                    <Grid item sm={10} style={{ marginBottom: "20px" }}>
                      <Grid container>
                        <Grid item sm={2}>{getDoorString(item)}</Grid>
                        <Grid item sm={2}>{getSeatString(item)}</Grid>
                        {!basePrice.unlimitedMileage && basePrice.mileageLimit > 0 && <Grid item sm={3}>
                          <span>
                            <img
                              src={require("../../../../../assets/icons/speedometer.png")}
                              alt="speedometer"
                              style={{ height: "18px", width: "18px", marginRight: "10px" }}
                            />
                            <span style={{ position: "relative", bottom: "4px" }}>
                              {getMileageLimitText(basePrice.mileageLimit, basePrice.rateTypeName, distanceUnit)}
                            </span>
                          </span>
                        </Grid>}
                        {basePrice.unlimitedMileage && <Grid item sm={3}>
                          <span>
                            <img
                              src={require("../../../../../assets/icons/speedometer.png")}
                              alt="speedometer"
                              style={{ height: "18px", width: "18px", marginRight: "10px" }}
                            />
                            <span style={{ position: "relative", bottom: "4px" }}>
                              {"Unlimited Mileage"}
                            </span>
                          </span>
                        </Grid>}
                        <Grid item sm={4}>
                          <VehicleCounter
                            count={item.selectedCount || 0}
                            maxCount={item.vehicles.length}
                            vehicleGroup={item}
                            updateCounter={updateVehicleSelection}
                            bookingData={props.bookingData}
                            vehiclesCount={values.vehicleGroups.length || 0}
                          />
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: 10 }}>
                        <Grid item sm={4}>{getTransmissionString(item)}</Grid>
                        <Grid item sm={5}>{getFuelTypeString(item)}</Grid>
                        <Grid item sm={4}></Grid>
                        <Grid item sm={2}>
                          <span
                            style={{
                              backgroundColor: "black",
                              bottom: 0,
                              color: "white",
                              cursor: "pointer",
                              padding: "10px",
                              position: "absolute",
                              right: 0,
                              fontSize: "x-large"
                            }}
                            onClick={() => {
                              getVehicleImage(item);
                              if (selectedVehicleGroup === item.id) {
                                setSelectedVehicleGroup(undefined);
                              } else {
                                setSelectedVehicleGroup(item.id);
                              }
                            }}
                          >
                            {selectedVehicleGroup === item.id ? " - " : " + "}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid style={{ maxHeight: "20rem", overflowY: "auto", width: "100%" }}>
                      {selectedVehicleGroup === item.id && renderVehicleCards(item)}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        }
      });
    } else {
      return <Typography>No groups found with matching criteria!</Typography>;
    }
  };

  const updateVehicleSelection = (focusedGroup: IVehiclePriceGroup, isIncrease: boolean, vehicleId?: string) => {
    if (props.isUpdate && props.bookingData.isSubscription) {
      return snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Subscription booking cannot be converted to rental bookings"
      })
    }
    let limitVehicleSelection: boolean = false;
    const { bookingCategory, customerType } = props.bookingData;
    if (props.bookingData.smartCarVehicle) {
      limitVehicleSelection = true;
    }
    if (
      (
        bookingCategory === BOOKING_CATEGORY.COURTESY_CAR &&
        customerType === CustomerType.INDIVIDUAL
      ) ||
      bookingCategory === BOOKING_CATEGORY.TEST_DRIVE
    ) {
      limitVehicleSelection = true;
    }
    const vehicleGroupIndex = renderedVehicleGroups.findIndex((vg) => vg.id === focusedGroup.id);
    const vehicleGroup: IRenderedVehicleGroup = renderedVehicleGroups[vehicleGroupIndex];
    let tempGroupArray = [...renderedVehicleGroups];
    if (limitVehicleSelection && isIncrease) {
      for (let i = 0; i < tempGroupArray.length; i++) {
        tempGroupArray[i].selectedCount = 0;
        for (const vehicle of tempGroupArray[i].vehicles) {
          vehicle.selected = false;
        }
      }
    }
    const tempVehiclesArray = tempGroupArray[vehicleGroupIndex].vehicles;
    const count = vehicleGroup.selectedCount || 0;
    let bookingVehicleGroups: IBookingVehicleGroup[] = limitVehicleSelection && isIncrease ? [] : [...values.vehicleGroups];
    let isShared = isSharedVehicleBooking;
    let checkCount = limitVehicleSelection ? 1 : vehicleGroup.vehicles.length;
    if (isIncrease) {
      if (count < checkCount) {
        let selectedVehicleId: string = '';
        for (var i = 0; i < tempVehiclesArray.length; i++) {
          if ((!vehicleId && !tempVehiclesArray[i].selected) || (vehicleId && tempVehiclesArray[i].id === vehicleId)) {
            if (tempGroupArray[vehicleGroupIndex].vehicles[i].shared) {
              isShared = true
            }
            tempGroupArray[vehicleGroupIndex].vehicles[i].selected = true;
            selectedVehicleId = tempVehiclesArray[i].id as string;
            const basePrice = tempGroupArray[vehicleGroupIndex].basePrices.find(
              (item) => item.rateTypeName === values.rateTypeName
            );
            let bookingVG = bookingVehicleGroups.find(vg => vg.vehicleGroup === tempGroupArray[vehicleGroupIndex].id)
            if (!bookingVG) {
              if (basePrice) {
                bookingVehicleGroups.push({
                  baseRate: basePrice.rate || 0,
                  count: 1,
                  deposit: tempGroupArray[vehicleGroupIndex].deposit,
                  mileageLimit: basePrice.mileageLimit || 0,
                  pricePerExtraMile: basePrice.pricePerExtraMile || 0,
                  ...(basePrice.priceRule && { priceRule: basePrice.priceRule }),
                  ...(basePrice.priceRuleAmount && { priceRuleAmount: basePrice.priceRuleAmount }),
                  ...(basePrice.priceRuleName && { priceRuleName: basePrice.priceRuleName }),
                  ...(basePrice.priceRuleOperator && { priceRuleOperator: basePrice.priceRuleOperator }),
                  ...(basePrice.priceRuleValueType && { priceRuleValueType: basePrice.priceRuleValueType }),
                  ...(basePrice.priceRuleTriggerType && { priceRuleTriggerType: basePrice.priceRuleTriggerType }),
                  unlimitedMileage: basePrice.unlimitedMileage ? basePrice.unlimitedMileage : false,
                  vehicleGroup: tempGroupArray[vehicleGroupIndex].id as string,
                  name: tempGroupArray[vehicleGroupIndex].name,
                  vehicleIds: [selectedVehicleId],
                });
              }
            } else {
              if (!bookingVG.vehicleIds.some(item => item === selectedVehicleId)) {
                bookingVG.vehicleIds.push(selectedVehicleId);
                bookingVG.count += 1;
              }
            }
            break;
          }
        }
        tempGroupArray.map((vg, index) => {
          if (index !== vehicleGroupIndex) {
            vg.vehicles = vg.vehicles.filter(vehicle => vehicle?.id !== selectedVehicleId);
          }
        });
        tempGroupArray[vehicleGroupIndex].selectedCount = count + 1;
      }
    } else {
      if (count > 0) {
        let unselectedVehicleId: string = '';
        for (var i = tempVehiclesArray.length - 1; i >= 0; i--) {
          if ((!vehicleId && tempVehiclesArray[i].selected) || (vehicleId && tempVehiclesArray[i].id === vehicleId)) {
            tempGroupArray[vehicleGroupIndex].vehicles[i].selected = false;
            unselectedVehicleId = tempVehiclesArray[i].id as string;
            let bookingVGIndex = bookingVehicleGroups.findIndex(vg => vg.vehicleGroup === tempGroupArray[vehicleGroupIndex].id);
            bookingVehicleGroups[bookingVGIndex].vehicleIds = bookingVehicleGroups[bookingVGIndex].vehicleIds.filter(id => id !== unselectedVehicleId);
            bookingVehicleGroups[bookingVGIndex].count -= 1;
            if (!bookingVehicleGroups[bookingVGIndex].vehicleIds.length) {
              bookingVehicleGroups.splice(bookingVGIndex, 1);
            }
            break;
          }
        }
        renderedVehicleGroups.map((vg: any, groupIndex) => {
          let tempVehicleArray: IVehicleSelection[] = [];
          if (groupIndex !== vehicleGroupIndex) {
            vg.vehicles?.map((vehicle: any) => {
              const vehicleIndex = tempGroupArray[groupIndex].vehicles.findIndex(item => item.id === vehicle.id)
              if (vehicleIndex >= 0) {
                tempVehicleArray.push(tempGroupArray[groupIndex].vehicles[vehicleIndex]);
              }
              if ((vehicle.id === unselectedVehicleId)) {
                tempVehicleArray.push({ ...vehicle, selected: false })
              }
            });
            tempGroupArray[groupIndex].vehicles = tempVehicleArray
          }
        });
        tempGroupArray[vehicleGroupIndex].selectedCount = count - 1;
      }
    }
    setRenderedVehicleGroups(tempGroupArray);
    setIsSharedVehicleBooking(isShared);
    let isCarShare = false
    if (props.bookingData.smartCarVehicle) {
      const selectedVehicleGroupId = bookingVehicleGroups[0].vehicleGroup
      const selectedVehicleId = bookingVehicleGroups[0].vehicleIds[0]

      const selectedVehicle = renderedVehicleGroups?.find(
        vg => vg.id === selectedVehicleGroupId
      )?.vehicles.find(
        vehicle => vehicle.id === selectedVehicleId
      )

      if (selectedVehicle?.isCarShare && allowCarSharing) {
        isCarShare = true
      }
    }
    setValues({
      ...values,
      vehicleGroups: bookingVehicleGroups,
      isCarShare: isIncrease ? isCarShare : false
    });
  }

  const getSelectedVehiclesCount = () => {
    let count = 0;
    if (values.isSubscription) {
      count = 1
    } else {
      values.vehicleGroups.forEach((group) => {
        count += group.vehicleIds.length;
      })
    }
    return count;
  }

  const handleSubmit = () => {
    if (!props.bookingData.isSubscription && userState.role !== UserRoles.SUPER_ADMIN) {
      if (props.isLocationDisabled) {
        snackbar({
          message: "Pick up location should not be empty",
          variant: SnackBarVariant.ERROR
        });
        return;
      }
      if (new Date(values.pickupDateTime) < new Date()) {
        snackbar({
          message: "Pick up date/time should not be empty",
          variant: SnackBarVariant.ERROR
        });
        return;
      }
      if (values.dropoffDateTime && new Date(values.dropoffDateTime) < new Date(values.pickupDateTime)) {
        snackbar({
          message: "Drop off time should be greater than pickup time",
          variant: SnackBarVariant.ERROR
        });
        return;
      }
    }
    values.locationSurcharges = locationSurchargeData;
    values.oneWayRentalFee = oneWayRentalFee;
    props.onSubmit(values);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid container item xs={12}>
          <Grid item sm={2}>
            <Typography
              variant={"h3"}
              style={{ marginLeft: "10px", marginBottom: "10px" }}
            >
              FILTERS
            </Typography>
            <NuvvenExpansionPanel title={"Registration Number Search"}>
              <TextField
                label="Licence"
                value={selectedLicense}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedLicense(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </NuvvenExpansionPanel>
            <FiltersView
              filters={filters}
              onChange={handleFilterUpdate}
            />
          </Grid>
          <Grid item sm={10}>
            {
              values.isSubscription && activeSubscriptionData && (
                <Grid item xs={9} sm={10} md={12} container style={{ marginLeft: "40px", marginTop: "35px" }}>
                  <Card style={{ width: "90%" }}>
                    <Grid item xs={12} container justifyContent="flex-end">
                      <Tooltip title={<Typography variant="h5">Remove selected plan to customize.</Typography>}>
                        <IconButton
                          onClick={() => removeSubscription()}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <CardContent>
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={3}>
                          {activeSubscriptionData.vehicle?.imageUrl ? (
                            <img
                              className="fleet-resrc-img"
                              alt="vehicle type icon"
                              src={activeSubscriptionData.vehicle.imageUrl}
                              height="90"
                              width="180"
                              style={{ width: "75%", height: "70%", borderRadius: 4, marginTop: 10, marginBottom: 10 }}
                            />
                          ) : (
                            <img
                              className="fleet-resrc-img"
                              alt="vehicle type icon"
                              src={getVehicleTypeIcon(
                                capitalizeFirstLetter(selectedSubscriptionVehicle?.bodyType || "")
                              )}
                              style={{ width: "75%", height: "70%", borderRadius: 4, marginTop: 10, marginBottom: 10 }}
                            />
                          )}
                          <Typography gutterBottom variant="h4">{
                            activeSubscriptionData.vehicle?.licencePlate}
                          </Typography>
                        </Grid>
                        <Grid item xs={9} container>
                          <Grid item xs={12}><Typography gutterBottom style={{ fontSize: 16, textTransform: "uppercase", fontWeight: 600, marginBottom: 10 }} variant="h3">{activeSubscriptionData.subscription?.name}</Typography></Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.pricingGutterBottom} variant="h4">Start Date: {getLocalizedDateFormat(country, activeSubscriptionData.startDate || "", DATE_TYPE.CONDENSED)}</Typography>
                            <Typography className={classes.pricingGutterBottom} variant="h4">End Date : {getLocalizedDateFormat(country, activeSubscriptionData.endDate || "", DATE_TYPE.CONDENSED)}</Typography>
                            <Typography className={classes.pricingGutterBottom} variant="h4">{`Duration : ${activeSubscriptionData.duration} ${activeSubscriptionData.subscription?.rateTypeName === RATE_TYPES.WEEKLY ? "Weeks" : "Months"} (Minimum)`}</Typography>
                            <Typography className={classes.pricingGutterBottom} variant="h4">Swaps Allowed : {activeSubscriptionData.swapsAllowed || "N/A"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.pricingGutterBottom} variant="h4">Enrollment Amount: {toCurrency(activeSubscriptionData.enrollmentAmount || 0, currency, locale)}</Typography>
                            <Typography className={classes.pricingGutterBottom} variant="h4">Subscription Amount: {toCurrency(activeSubscriptionData.subscriptionAmount || 0, currency, locale)}</Typography>
                            <Typography className={classes.pricingGutterBottom} variant="h4">Mileage Limit: {activeSubscriptionData.unlimitedMileage ? "Unlimited" : activeSubscriptionData.mileageLimit}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            {
                              activeSubscriptionData.unlimitedMileage ?
                                <Typography className={classes.pricingGutterBottom} variant="h4">Price per extra mile: N/A</Typography>
                                :
                                <Typography className={classes.pricingGutterBottom} variant="h4">Price per extra mile: {toCurrency(activeSubscriptionData.pricePerExtraMile || 0, currency, locale)}</Typography>
                            }
                            <Typography className={classes.pricingGutterBottom} variant="h4">Insurance Policy:
                              {activeSubscriptionData.subscription?.insurancePolicy?.name || "-"}
                            </Typography>
                            <Typography className={classes.pricingGutterBottom} variant="h4">Addons:
                              {activeSubscriptionData.subscription?.addons?.map(addon => addon.displayName).join(", ") || "-"}
                            </Typography>

                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid item xs={12} container justifyContent="flex-end">
                        <Fab
                          onClick={handleSubmit}
                          size="medium"
                          variant="extended"
                        >Continue
                        </Fab>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              )
            }
            <Grid container item xs={9} sm={10} md={props.bookingData.bookingCategory !== BOOKING_CATEGORY.SUBSCRIPTION ? 11 : 12}>
              {
                props.bookingData.bookingCategory !== BOOKING_CATEGORY.SUBSCRIPTION && (
                  renderVehicleGroups()
                )
              }
              {
                (
                  props.bookingData.bookingCategory === BOOKING_CATEGORY.SUBSCRIPTION
                ) ?
                  renderedSubscriptionVehicles.length ? (
                    <div className={(values.isSubscription) ? classes.disableRentals : ""} style={{ width: "100%" }}>
                      <SelectSubscriptionVehiclesView
                        bookingDurationInMonths={bookingDurationInMonths}
                        bookingDurationInWeeks={bookingDurationInWeeks}
                        onSelectSubscription={onSelectSubscription}
                        subscriptionVehicles={renderedSubscriptionVehicles}
                      />
                    </div>
                  ) : <Typography>No groups found with matching criteria!</Typography> : ""
              }
            </Grid>
            {
              (props.bookingData.bookingCategory !== BOOKING_CATEGORY.SUBSCRIPTION) && (
                <Grid container item xs={3} sm={2} md={1} style={{ position: "fixed", bottom: "2rem", right: "5px" }}>
                  <Grid container item xs={12}>
                    <Typography variant="body1">
                      Selected Vehicles: {getSelectedVehiclesCount()}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Fab
                      size="medium"
                      variant="extended"
                      onClick={handleSubmit}
                      disabled={getSelectedVehiclesCount() > 0 ? false : true}
                    >
                      Continue
                    </Fab>
                  </Grid>
                </Grid>
              )
            }
            {/* <Grid container item xs={10}>{renderVehicleGroups()}</Grid>
          {showSummary &&
            <>
              <Grid container item xs={2}>
                <SummaryPanel bookingInput={values} />
              </Grid>
            </>
          }
          <Grid item xs={10} />
          <Grid container xs={1} style={{ position: "fixed", bottom: "3rem", right: "1rem" }}>
            <Grid container item xs={12}>
              <Typography variant="body1">
                Selected Vehicles: {getSelectedVehiclesCount()}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Fab
                size="medium"
                variant="extended"
                onClick={handleSubmit}
                disabled={!values.vehicleGroups.some((item) => item.count > 0)}
              >
                Continue
              </Fab>
            </Grid>
          </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <FiltersView
            searchFilters={searchFilters}
            filters={filters}
            onChange={handleFilterUpdate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Exit</Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default VehicleSelection;
