import "./index.scss"
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, CircularProgress, Fab, FormControl, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import * as Yup from "yup";
import { DateTime as d } from "luxon";
import { GET_CURRENT_BOOKING_SCHEDULE } from '../../../../../graphql/bookings/getCurrentBookingScheduleQuery'
import { BookingExtensionStatus, IBookingAddonRequirement, IBookingExtension, IBookingExtensionCreateInput, IBookingVehicleGroup, IExtensionAddonDetailsInput, IExtensionInsuranceDetailsInput, IExtensionOtherCharge, IExtensionOtherChargesDetailsInput, IExtensionVehicleDetail, IExtensionVehicleDetailsInput, ISubscriptionDetailsInput } from '../../../../../reducers/bookings/types';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { GET_BRANCH } from '../../../../../graphql/organisation/getBranch';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { IServiceLocation } from '../../../../../reducers/user/types';
import { SimpleDateTimePicker } from '../../../../common/SimpleDateTimePicker';
import { calculateNewPrice } from '../../../Settings/Pricing/PriceRuleCreation/utils';
import { FloatInput } from '../../../../common/FloatInput/FloatInput';
import { CREATE_BOOKING_EXTENSION } from '../../../../../graphql/bookings/createBookingExtensionMutation';
import { ApolloError } from '@apollo/client';
import { DATE_TYPE, capitalize, formatGraphQLErrorMessage, getRateTypeString, getRentalTaxAmount, getTaxesDescription, getVehicleGroupApplicableTaxesDetails, isLongTermBooking, toCurrency } from '../../../../common/utils';
import PaymentViewExtension from '../PaymentViewExtension/PaymentViewExtension';
import { getMileageLimitText } from '../../../ReservationManagement/utils';
import { CONFIRM_BOOKING_EXTENSION } from '../../../../../graphql/bookings/confirmBookingExtensionMutation';
import { useNavigate } from 'react-router-dom';
import { getLocalizedBookingSyntex, getLocalizedDateFormat, getLocalizedTaxSyntex } from "../../../../../utils/localized.syntex";
import { SUGGEST_EXTENSION_BOOKING_PRICE } from "../../../../../graphql/bookings/suggestExtendPriceMutation";
import { SUGGEST_EXTENSION_SUBSCRIPTION_PRICE } from "../../../../../graphql/bookings/suggestExtensionPriceSubscriptionQuery";
import { makeStyles } from '@mui/styles';
import { VEHICLE_STATUS } from "../../../ReservationManagement/Summary/const";
import { BOOKING_TYPES } from "../../../../../reducers/bookings/consts";
import { GET_SERVICE_LOCATIONS_IN_ORG } from "../../../../../graphql/vehicleMovement/queries";

const columns = ["Item", "Quantity", "Duration", "Price"];

const initialValues: any = {
  customerType: "individual",
  pickupServiceLocation: "",
  dropoffServiceLocation: "",
  pickupDateTime: "",
  dropoffDateTime: "",
  flightNumber: "",
  longTermBooking: false,
  rateTypeName: "daily",
  rateTypeDuration: 1440,
  vehicleGroups: [],
  insuranceRate: 0,
  excess: 0,
  tax: [],
  addonRequirements: [],
  approvedDrivers: []
};

const initialExtensionData: IBookingExtensionCreateInput = {
  dropoffServiceLocation: "",
  startDate: "",
  endDate: "",
  bookingId: "",
  vehicleDetails: [],
  addonDetails: [],
  otherChargesDetails: [],
  subscriptionDetails: {
    modifiedSubscriptionAmount: 0,
    subscriptionAmount: 0,
    vehicleId: ""
  }
};

interface IDateTime {
  date: string;
  time: number;
}

interface IUpdateBookingSchedule {
  startDate: string;
  endDate: string;
  bookingId: string;
  bookingSchedules: string[]
}

interface IDialogData {
  startDate?: string | undefined;
  endDate?: string;
  bookingId?: string;
}
interface IProps {
  type: string;
  handleClose(type?: boolean): void;
  dialogData: IDialogData;
  updateBookingSchedules(values: IUpdateBookingSchedule): void;
}

const useStyles = makeStyles({
  root: {
    '& .MuiTableHead-root .MuiTableCell-root': {
      fontSize: '1rem', // adjust the font size as needed
      whiteSpace: 'nowrap', // prevent text wrapping
      overflow: 'hidden', // prevent horizontal overflow
      textOverflow: 'ellipsis', // add ellipsis when text is truncated
      maxWidth: 150,
    },
  },
});

const ExtendInProgressBooking: React.FC<IProps> = (props) => {
  const { dialogData, handleClose } = props;
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { distanceUnit } = userState.currentBranch;
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [bookingData, setBookingData] = useState<any>(initialValues);
  const [extension, setExtension] = useState<IBookingExtension>();
  const [extensionData, setExtensionData] = useState(initialExtensionData);
  const [extensionCreated, setExtensionCreated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceLocations, setServiceLocations] = useState<IServiceLocation[]>([]);
  const classes = useStyles();
  let totalRentalTaxAmount = 0;
  let totalAddonTaxAmount = 0;

  const [loadBranchData, { data: branchData }] = useLazyQuery(GET_BRANCH, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      if (branchData && branchData.branch && branchData.branch.activeLocations) {
        setServiceLocations(branchData.branch.activeLocations)
      }
    }
  });

  const [loadServiceLocationInOrg, { loading: loadingServiceLocationInOrg, data: serviceLocationsData }] = useLazyQuery(GET_SERVICE_LOCATIONS_IN_ORG, {
    fetchPolicy: "no-cache"
  });

  const [getCurrentBookingSchedule, { loading: currentBookingScheduleLoading, data: currentBookingSchedule }] = useLazyQuery(
    GET_CURRENT_BOOKING_SCHEDULE, {
    fetchPolicy: "network-only"
  })

  const [createBookingExtensionMutation, { data: extensionCreateData }] = useMutation(
    CREATE_BOOKING_EXTENSION,
    {
      onCompleted: ({ extendBooking }) => {
        if (extendBooking) {
          setLoading(false);
          setExtensionCreated(true);
          setExtension(extendBooking)
        }
      },
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [suggestExtensionPrice, { data: suggestExtensionpriceData }] = useLazyQuery(SUGGEST_EXTENSION_BOOKING_PRICE)
  const [suggestSubscriptionExtensionPrice, { data: suggestSubscriptionExtensionpriceData }] = useLazyQuery(SUGGEST_EXTENSION_SUBSCRIPTION_PRICE)
  const [confirmBookingExtensionMutation, { data: extensionConfirmData }] = useMutation(
    CONFIRM_BOOKING_EXTENSION,
    {
      onCompleted: ({ confirmBookingExtension }) => {
        if (confirmBookingExtension) {
          setLoading(false)
          handleClose(true)
          navigate(`/view-booking?booking=${bookingData.id}`)
        }
      },
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useState(() => {
    if (dialogData && dialogData.bookingId) {
      getCurrentBookingSchedule({
        variables: {
          id: dialogData.bookingId
        }
      })
    }
  })

  useEffect(() => {
    if (props.dialogData.endDate) {
      setExtensionData({
        ...extensionData,
        endDate: props.dialogData.endDate
      })
    }
  }, [props.dialogData])

  useEffect(() => {
    if (userState && userState.currentBranch && userState.currentBranch.id) {
      if (userState.currentOrganisation.crossLocationBookingEnabled) {
        loadServiceLocationInOrg({
          variables: {
            organisationId: userState.currentOrganisation.id
          }
        })
      } else {
        loadBranchData({
          variables: {
            branchId: userState.currentBranch.id
          }
        });
      }
    }
  }, [userState]);

  useEffect(() => {
    if (serviceLocationsData && serviceLocationsData.getServiceLocations) {
      setServiceLocations(serviceLocationsData.getServiceLocations)
    }
  }, [serviceLocationsData]);

  useEffect(() => {
    if (currentBookingSchedule && currentBookingSchedule.booking) {
      let { booking } = currentBookingSchedule
      const addonDetails: IExtensionAddonDetailsInput[] = [];
      let vehicleDetails: IExtensionVehicleDetailsInput[] = [];
      let otherChargesDetails: IExtensionOtherChargesDetailsInput[] = [];
      let dropoffServiceLocation: string = "";
      dropoffServiceLocation = booking.dropoffServiceLocation?.id || "";
      let startDate = booking.dropoffDateTime;
      let basePrice: any = {};
      setBookingData(booking)
      if (booking.latestExtension) {
        dropoffServiceLocation = booking.latestExtension?.dropoffServiceLocation?.id || "";
        if (booking.latestExtension.status === BookingExtensionStatus.QUOTE) {
          startDate = booking.latestExtension.startDate
          otherChargesDetails = booking.latestExtension.otherCharges;
        }
      } else {
        dropoffServiceLocation = booking.dropoffServiceLocation?.id || "";
      }
      booking.addonRequirements.forEach((addonRequirement: IBookingAddonRequirement) => {
        if (!addonRequirement.hasFixedRate) {
          const addonObj = {
            quantity: addonRequirement.quantity,
            addonId: addonRequirement?.addon?.id || "",
            unitPrice: addonRequirement.rate,
            modifiedPrice: addonRequirement.rate,
            description: addonRequirement.displayName || "",
            tax: {
              title: addonRequirement.tax && addonRequirement.tax.title ? addonRequirement.tax.title : "",
              value: addonRequirement.tax && addonRequirement.tax.value ? addonRequirement.tax.value : 0,
            }
          }
          addonDetails.push(addonObj);
        }
      })
      booking.vehicleGroups.forEach((vg: IBookingVehicleGroup) => {
        if (vg.activeVehicleSchedules?.length) {
          vg.activeVehicleSchedules.forEach((schedule) => {
            if (![VEHICLE_STATUS.PICKUP_CHECK_COMPLETE, VEHICLE_STATUS.COMPLETED].includes(schedule.vehicleStatus)) {
              if (typeof vg.vehicleGroup !== "string") {
                const id = vg.vehicleGroup?.id || ""
                const idx = vehicleDetails.findIndex((obj) => obj.vehicleGroupId === id)
                const vehicleId = schedule.vehicle.id || ""
                if (idx > -1) {
                  vehicleDetails[idx].quantity += 1
                  vehicleDetails[idx].vehicleIds.push(vehicleId)
                } else {
                  const vehicleDetailObj = {
                    basePrice: vg.baseRate,
                    priceRuleAmount: vg.priceRuleAmount,
                    priceRuleOperator: vg.priceRuleOperator,
                    priceRuleValueType: vg.priceRuleValueType,
                    priceRuleTriggerType: vg.priceRuleTriggerType,
                    modifiedPrice: calculateNewPrice(vg.baseRate, vg.priceRuleOperator, vg.priceRuleValueType, vg.priceRuleAmount),
                    vehicleGroupId: vg.vehicleGroup?.id || "",
                    vehicleIds: [vehicleId],
                    quantity: 1,
                    description: `Rental Price (${vg.name ? vg.name.toUpperCase() : vg.vehicleGroup.name.toUpperCase()})`
                  }
                  vehicleDetails.push(vehicleDetailObj);
                }
              }
            }
          })
        }

      })
      booking.vehicleGroups.forEach((vg: any) => {
        basePrice = vg.vehicleGroup.basePrices.find((bp: any) => bp.rateTypeName === booking.rateTypeName);
      })
      const insuranceDetails: IExtensionInsuranceDetailsInput = {
        insurancePolicy: "",
        unitPrice: 0,
        modifiedPrice: 0,
        description: ""
      }
      if (booking.insurancePolicy) {
        insuranceDetails.insurancePolicy = booking.insurancePolicy?.id || "";
        insuranceDetails.unitPrice = booking.insuranceRate || 0;
        insuranceDetails.modifiedPrice = booking.insuranceRate || 0;
        insuranceDetails.description = booking.insurancePolicy.name;
      }
      if (!dropoffServiceLocation) {
        dropoffServiceLocation = booking.dropoffServiceLocation?.id || "";
      }
      let subscriptionExtension: ISubscriptionDetailsInput | undefined;
      if (booking.isSubscription) {
        subscriptionExtension = {
          subscriptionAmount: booking.activeSubscription?.subscriptionAmount,
          modifiedSubscriptionAmount: booking.activeSubscription?.subscriptionAmount,
          vehicleId: booking.activeSubscription?.vehicle?.id
        }
      }
      setExtensionData({
        ...extensionData,
        dropoffServiceLocation,
        bookingId: booking.id,
        startDate,
        insuranceDetails,
        addonDetails,
        vehicleDetails,
        otherChargesDetails,
        ...((booking.isSubscription && subscriptionExtension) && { subscriptionDetails: subscriptionExtension })
      })
    }
  }, [currentBookingSchedule])

  const validationSchema = Yup.object().shape({

  });

  const getAddonRate = (addon: any, rentalTime: number) => {
    let rate = addon.rate * addon.quantity;
    if (!addon.hasFixedRate && rentalTime) {
      rate = rate * rentalTime;
    }
    return rate;
  };

  const submitForm = () => {
    setLoading(true);
    createBookingExtensionMutation({
      variables: {
        extensionData
      }
    })
  };
  if (currentBookingScheduleLoading) {
    return <Grid container justifyContent="center" style={{ paddingBottom: "20px" }}>
      <CircularProgress />
    </Grid>
  }

  return (
    <React.Fragment>
      {
        extensionCreated && extension ?
          <Grid container item xs={12} spacing={2} style={{ marginBottom: 15 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant={"h4"} style={{ display: "inline" }}>
                  {`${getLocalizedBookingSyntex(country)} Reference: `}
                </Typography>
                <Typography variant={"body1"} style={{ display: "inline" }}>
                  {bookingData?.referenceNumber}
                </Typography>
              </Grid>
              <Grid item xs={6} alignContent="flex-end">
                <Typography variant={"h4"} style={{ display: "inline" }}>
                  {"Extension Reference: "}
                </Typography>
                <Typography variant={"body1"} style={{ display: "inline" }}>
                  {extension ? extension.referenceNumber : ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"h4"} style={{ display: "inline" }}>
                  {"Start Date: "}
                </Typography>
                <Typography variant={"body1"} style={{ display: "inline" }}>
                  {getLocalizedDateFormat(country, extension?.startDate, DATE_TYPE.EXPANDED)}
                </Typography>
              </Grid>
              <Grid item xs={6} alignContent="flex-end">
                <Typography variant={"h4"} style={{ display: "inline" }}>
                  {"End Date: "}
                </Typography>
                <Typography variant={"body1"} style={{ display: "inline" }}>
                  {getLocalizedDateFormat(country, extension?.endDate, DATE_TYPE.EXPANDED)}
                </Typography>
              </Grid>
            </Grid>
            {(isLongTermBooking(bookingData) || bookingData.bookingType === BOOKING_TYPES.POSTPAID) ? <Grid container xs={12}>
              <Grid item xs={12} style={{ margin: "1rem" }}>
                <Typography variant="h4">
                  Are you sure you want to confirm the extension?
                </Typography>
              </Grid>
              <Grid container xs={12} style={{ marginLeft: "1rem" }}>
                <Fab
                  size="medium"
                  variant="extended"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true)
                    confirmBookingExtensionMutation({
                      variables: {
                        id: extension.id
                      }
                    })
                  }}
                >
                  {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                  Confirm
                </Fab>
                <Box pl={2}></Box>
                <Fab
                  size="medium"
                  variant="extended"
                  className="blackBackButton"
                  onClick={() => {
                    handleClose(true)
                  }}
                >
                  Cancel
                </Fab>
              </Grid>
            </Grid> :
              <>
                <Grid container item xs={12}>
                  <TableContainer>
                    <Table aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          {
                            columns.map((column: string) => {
                              return (
                                <TableCell>
                                  <Typography variant={"h4"}>{column}</Typography>
                                </TableCell>
                              )
                            })
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          extension?.vehicleDetails.map((vehicleDetail: IExtensionVehicleDetail) => {
                            const applicableTaxes = getVehicleGroupApplicableTaxesDetails(bookingData.tax, vehicleDetail.vehicleGroupId);
                            totalRentalTaxAmount += getRentalTaxAmount(applicableTaxes || [], extension.paymentDetails.rentalTime, vehicleDetail.modifiedPrice * vehicleDetail.quantity * extension?.paymentDetails?.rentalTime || 1, vehicleDetail.quantity);
                            const taxTitles = getTaxesDescription(applicableTaxes, currency, locale);
                            return (
                              <TableRow>
                                <TableCell>
                                  {vehicleDetail.description} <br />
                                  {
                                    taxTitles.length > 0 &&
                                    taxTitles.map(title => {
                                      return (
                                        <>
                                          <span>({title})</span><br></br>
                                        </>
                                      )
                                    })
                                  }
                                </TableCell>
                                <TableCell>{vehicleDetail.quantity}</TableCell>
                                <TableCell>
                                  {extension?.paymentDetails?.rentalTime} {getRateTypeString(bookingData.rateTypeName)}
                                </TableCell>
                                <TableCell>
                                  {toCurrency(
                                    !vehicleDetail.modifiedPrice ? 0 :
                                      (vehicleDetail.modifiedPrice * vehicleDetail.quantity * extension?.paymentDetails?.rentalTime || 1),
                                    currency,
                                    locale
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        }
                        {(() => {
                          if (extension?.insurancePolicy?.id) {
                            const totalVehicles = extension?.vehicleDetails.reduce(
                              (accumulator, current) => accumulator + current.quantity, 0);
                            return (
                              <TableRow>
                                <TableCell>
                                  {extension.insurancePolicy.name || ""}
                                </TableCell>
                                <TableCell>
                                  {totalVehicles}
                                </TableCell>
                                <TableCell>
                                  {extension.paymentDetails?.rentalTime} {getRateTypeString(bookingData.rateTypeName)}
                                </TableCell>
                                <TableCell>
                                  {toCurrency(
                                    extension.paymentDetails.insuranceAmount,
                                    currency,
                                    locale
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          }
                        })()}
                        {
                          extension?.addonRequirements?.map((addonR: IBookingAddonRequirement) => {
                            let taxAmount = 0;
                            let taxDescription = addonR.tax && addonR.tax.title && addonR.tax.value ? `${addonR.tax.title} ${addonR.tax.value}%` : "";
                            if (addonR.hasFixedRate) {
                              totalAddonTaxAmount += addonR.tax && addonR.tax.value ? ((addonR.rate * addonR.tax.value / 100) * addonR.quantity) : 0;
                            } else {
                              totalAddonTaxAmount += addonR.tax && addonR.tax.value ? ((addonR.rate * addonR.tax.value / 100) * addonR.quantity * extension.paymentDetails.rentalTime) : 0;
                            }
                            return (
                              <>
                                <TableRow>
                                  <TableCell colSpan={1}>
                                    {addonR?.displayName?.toUpperCase() || ""} <br />
                                    {taxDescription && (<span>({taxDescription})</span>)}
                                  </TableCell>
                                  <TableCell>{addonR.quantity}</TableCell>
                                  <TableCell>
                                    {addonR.hasFixedRate ? "N/A" : `${extension.paymentDetails?.rentalTime} ${getRateTypeString(bookingData.rateTypeName)}`}
                                  </TableCell>
                                  <TableCell>
                                    {toCurrency(
                                      getAddonRate(addonR, extension.paymentDetails?.rentalTime),
                                      currency,
                                      locale
                                    )}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        }
                        {
                          extension?.otherCharges?.map((otherCharge: IExtensionOtherCharge) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell colSpan={1}>
                                    {otherCharge.description}
                                  </TableCell>
                                  <TableCell>{otherCharge.quantity}</TableCell>
                                  <TableCell>
                                    {"N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {toCurrency(
                                      otherCharge.unitPrice,
                                      currency,
                                      locale
                                    )}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        }
                        <TableRow>
                          <TableCell colSpan={2} rowSpan={3}>
                          </TableCell>
                          <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                            <Typography variant="h4">{"SUBTOTAL"}</Typography>
                          </TableCell>
                          <TableCell>
                            {toCurrency(
                              extension?.paymentDetails?.totalPayableAmount - extension?.paymentDetails?.taxAmount,
                              currency,
                              locale
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h4">{getLocalizedTaxSyntex(country)}</Typography>
                          </TableCell>
                          <TableCell>
                            {toCurrency(
                              extension?.paymentDetails?.taxAmount,
                              currency,
                              locale
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h4">{"TOTAL DUE"}</Typography>
                          </TableCell>
                          <TableCell>
                            {toCurrency(
                              extension?.paymentDetails?.totalPayableAmount,
                              currency,
                              locale
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid container item xs={12}>
                  <PaymentViewExtension
                    bookingData={bookingData}
                    extensionData={extension}
                    amountToPay={extension.paymentDetails.totalPayableAmount}
                    handleClose={handleClose}
                  />
                </Grid>
              </>
            }
          </Grid>
          :
          <Grid container item xs={12} spacing={2} style={{ marginBottom: 15 }}>
            <Grid container item xs={12}>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <Typography variant={"h4"} style={{ display: "inline" }}>
                  {`${getLocalizedBookingSyntex(country)} Reference: `}
                </Typography>
                <Typography variant={"body1"} style={{ display: "inline" }}>
                  {bookingData?.referenceNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={6} alignContent="flex-end">
                <Typography variant={"h4"} style={{ display: "inline" }}>
                  {"VRN(s): "}
                </Typography>
                <Typography variant={"body1"} style={{ display: "inline" }}>
                  {
                    bookingData?.currentBookingSchedules?.length
                      ? bookingData.currentBookingSchedules
                        .filter((value: any) => value.vehicleStatus === "IN_PROGRESS")
                        .map((value: any) => value.vehicle.licencePlate).join(", ")
                      : bookingData.isSubscription ?
                        bookingData.activeSubscription?.vehicle?.licencePlate : ""
                  }

                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={extensionData}
                onSubmit={(extensionData, { setSubmitting }) => {
                  submitForm();
                  setSubmitting(false);
                }}
              >
                {(formikProps) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4} lg={4} xl={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={InputField}
                            required
                            select
                            label={"Drop-off Location"}
                            inputProps={{
                              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                const otherChargesArr = [];
                                if (!bookingData.isSubscription && bookingData.dropoffServiceLocation?.id !== event.target.value) {
                                  const otherChargeItem = {
                                    description: "Location Change Charge",
                                    quantity: 1,
                                    unitPrice: 0
                                  }
                                  otherChargesArr.push(otherChargeItem)
                                }
                                setExtensionData({
                                  ...extensionData,
                                  dropoffServiceLocation: event.target.value,
                                  otherChargesDetails: otherChargesArr
                                });
                              },
                              value: extensionData.dropoffServiceLocation
                            }}
                            name={"dropoffServiceLocation"}
                            fullWidth
                          >
                            {serviceLocations && serviceLocations.length > 0 &&
                              [...serviceLocations].sort((a: IServiceLocation, b: IServiceLocation) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                                .map(
                                  (location: IServiceLocation, key: number) => {
                                    return (
                                      <MenuItem value={location.id} key={location.id}>
                                        {userState.currentOrganisation.crossLocationBookingEnabled ?
                                          `${location.name} - ${location.branch?.name}` : location.name}
                                      </MenuItem>
                                    );
                                  }
                                )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8} xl={8} >
                        <SimpleDateTimePicker
                          date={extensionData.endDate}
                          handleChange={(date: IDateTime) => {
                            const endDate = d.fromISO(date.date).toUTC().toISO();
                            if (date && date.date) {
                              setExtensionData({
                                ...extensionData,
                                endDate
                              })
                              {
                                bookingData.isSubscription ? (
                                  suggestSubscriptionExtensionPrice({
                                    variables: {
                                      bookingId: dialogData.bookingId,
                                      startDate: dialogData.startDate,
                                      endDate: endDate
                                    }
                                  })
                                ) : (
                                  suggestExtensionPrice({
                                    variables: {
                                      bookingId: dialogData.bookingId,
                                      startDate: dialogData.startDate,
                                      endDate: endDate
                                    }
                                  })
                                )
                              }
                            }
                          }}
                          name={"dropOffDateTime"}
                          dateTitle={"Drop-off Date"}
                          timeTitle={"Drop-off Time"}
                          minDate={props.dialogData.endDate}
                        />
                      </Grid>
                    </Grid>
                    <Grid container xs={12} style={{ marginTop: "20px" }}>
                      <Typography variant="h4" >
                        Payment Information
                      </Typography>
                    </Grid>
                    <Grid container xs={12}>
                      <Paper elevation={0} className='responsive-extenton-payment-table'>
                        <Table className={classes.root}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography variant={"h4"}>Item Description</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant={"h4"}>Original {capitalize(bookingData.rateTypeName)} Price</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant={"h4"}>Suggested {capitalize(bookingData.rateTypeName)} Price</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant={"h4"}>New {capitalize(bookingData.rateTypeName)} Price</Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              bookingData.isSubscription && (
                                <TableRow>
                                  <TableCell>
                                    {bookingData.activeSubscription?.subscription?.name}
                                  </TableCell>
                                  <TableCell>
                                    {toCurrency(bookingData.activeSubscription.subscriptionAmount, currency, locale)}
                                  </TableCell>
                                  <TableCell>
                                    {suggestSubscriptionExtensionpriceData ?
                                      <>{suggestSubscriptionExtensionpriceData.suggestSubscriptionExtensionPrice.map((item: { subscriptionId: string; suggestedPrice: number }) => {
                                        if (item.subscriptionId) {
                                          return <>{toCurrency(item.suggestedPrice, currency, locale)}</>
                                        }
                                      })}
                                      </> : <> {toCurrency(bookingData.activeSubscription.subscriptionAmount, currency, locale)}</>
                                    }
                                  </TableCell>
                                  <TableCell>
                                    <Field
                                      component={InputField}
                                      fullWidth
                                      placeholder={`New ${capitalize(bookingData.rateTypeName)} Price`}
                                      label={`New ${capitalize(bookingData.rateTypeName)} Price`}
                                      name="modifiedSubscriptionAmount"
                                      InputProps={{
                                        value: extensionData.subscriptionDetails?.modifiedSubscriptionAmount,
                                        inputComponent: FloatInput as any,
                                        onChange: (
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          const modifiedPrice = parseInt(e.target.value);
                                          setExtensionData({
                                            ...extensionData,
                                            subscriptionDetails: {
                                              ...extensionData.subscriptionDetails,
                                              modifiedSubscriptionAmount: modifiedPrice
                                            }
                                          })
                                        }
                                      }}
                                      inputProps={{
                                        hasCurrencyPrefix: true,
                                        allowNegative: false
                                      }}
                                    ></Field>
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            {extensionData.vehicleDetails.map((vehicle: IExtensionVehicleDetail, idx: number) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {vehicle.description}
                                  </TableCell>
                                  <TableCell>
                                    {toCurrency(
                                      calculateNewPrice(vehicle.basePrice, vehicle.priceRuleOperator, vehicle.priceRuleValueType, vehicle.priceRuleAmount),
                                      currency,
                                      locale
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {suggestExtensionpriceData && suggestExtensionpriceData.suggestExtensionPrice.map((item: { vehicleGroup: string; suggestedPrice: number }) => {
                                      if (item.vehicleGroup === vehicle.vehicleGroupId) {
                                        return <>{toCurrency(item.suggestedPrice, currency, locale)}</>
                                      }
                                    })}
                                    {!suggestExtensionpriceData &&
                                      toCurrency(
                                        calculateNewPrice(vehicle.basePrice, vehicle.priceRuleOperator, vehicle.priceRuleValueType, vehicle.priceRuleAmount),
                                        currency,
                                        locale
                                      )
                                    }
                                  </TableCell>
                                  <TableCell>
                                    <Field
                                      component={InputField}
                                      fullWidth
                                      placeholder={`New ${capitalize(bookingData.rateTypeName)} Price`}
                                      label={`New ${capitalize(bookingData.rateTypeName)} Price`}
                                      name="vehicleModifiedPrice"
                                      InputProps={{
                                        value: vehicle.modifiedPrice,
                                        inputComponent: FloatInput as any,
                                        onChange: (
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          const item = extensionData.vehicleDetails[idx];
                                          const val = e.target.value || "0";
                                          const vehicleDetailsArr = [...extensionData.vehicleDetails];
                                          item.modifiedPrice = parseInt(val);
                                          vehicleDetailsArr.splice(idx, 1, item);
                                          setExtensionData({
                                            ...extensionData,
                                            vehicleDetails: vehicleDetailsArr
                                          })
                                        }
                                      }}
                                      inputProps={{
                                        hasCurrencyPrefix: true,
                                        allowNegative: false
                                      }}
                                    ></Field>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                            {extensionData.insuranceDetails && extensionData.insuranceDetails.insurancePolicy && (
                              <TableRow>
                                <TableCell>
                                  {`Insurance (${extensionData.insuranceDetails.description})`}
                                </TableCell>
                                <TableCell>
                                  {toCurrency(
                                    extensionData.insuranceDetails.unitPrice,
                                    currency,
                                    locale
                                  )}
                                </TableCell>
                                <TableCell>
                                  -
                                </TableCell>
                                <TableCell>
                                  <Field
                                    component={InputField}
                                    fullWidth
                                    placeholder={`New ${capitalize(bookingData.rateTypeName)} Price`}
                                    label={`New ${capitalize(bookingData.rateTypeName)} Price`}
                                    name="modifiedUnitPrice"
                                    InputProps={{
                                      value: extensionData.insuranceDetails.modifiedPrice,
                                      disabled: true,
                                      inputComponent: FloatInput as any,
                                      onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => { }
                                    }}
                                    inputProps={{
                                      hasCurrencyPrefix: true,
                                      allowNegative: false
                                    }}
                                  ></Field>
                                </TableCell>
                              </TableRow>
                            )}
                            {extensionData.addonDetails.map((addonDetail: IExtensionAddonDetailsInput, idx: number) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {addonDetail.description}
                                  </TableCell>
                                  <TableCell>
                                    {toCurrency(
                                      addonDetail.unitPrice,
                                      currency,
                                      locale
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    -
                                  </TableCell>
                                  <TableCell>
                                    <Field
                                      component={InputField}
                                      fullWidth
                                      placeholder={`New ${capitalize(bookingData.rateTypeName)} Price`}
                                      label={`New ${capitalize(bookingData.rateTypeName)} Price`}
                                      name="modifiedUnitPrice"
                                      InputProps={{
                                        value: addonDetail.modifiedPrice,
                                        inputComponent: FloatInput as any,
                                        onChange: (
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          const val = e.target.value || "0";
                                          const item = extensionData.addonDetails[idx];
                                          const addonDetailsArr = [...extensionData.addonDetails];
                                          item.modifiedPrice = parseInt(val);
                                          addonDetailsArr.splice(idx, 1, item);
                                          setExtensionData({
                                            ...extensionData,
                                            addonDetails: addonDetailsArr
                                          })
                                        }
                                      }}
                                      inputProps={{
                                        hasCurrencyPrefix: true,
                                        allowNegative: false
                                      }}
                                    ></Field>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                            {extensionData.otherChargesDetails.map((otherChargeDetail: IExtensionOtherChargesDetailsInput, idx: number) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {otherChargeDetail.description}
                                  </TableCell>
                                  <TableCell>
                                    N/A
                                  </TableCell>
                                  <TableCell>
                                    -
                                  </TableCell>
                                  <TableCell>
                                    <Field
                                      component={InputField}
                                      fullWidth
                                      placeholder="Price"
                                      label="Price"
                                      name="otherChargePrice"
                                      InputProps={{
                                        value: otherChargeDetail.unitPrice,
                                        inputComponent: FloatInput as any,
                                        onChange: (
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          const val = e.target.value || "0";
                                          const item = extensionData.otherChargesDetails[idx];
                                          const otherChargesArr = [...extensionData.otherChargesDetails];
                                          item.unitPrice = parseInt(val);
                                          otherChargesArr.splice(idx, 1, item);
                                          setExtensionData({
                                            ...extensionData,
                                            otherChargesDetails: otherChargesArr
                                          })
                                        }
                                      }}
                                      inputProps={{
                                        hasCurrencyPrefix: true,
                                        allowNegative: false
                                      }}
                                    ></Field>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Box mt={2}></Box>
                    <Grid container item xs={12}>
                      <Typography>
                        {bookingData && bookingData.vehicleGroups && bookingData.vehicleGroups[0] && bookingData.vehicleGroups[0].unlimitedMileage && (
                          <>
                            <b>Mileage Limit:</b> {"Unlimited Mileage"}
                          </>
                        )}

                        {bookingData && bookingData.vehicleGroups && bookingData.vehicleGroups[0] &&
                          bookingData.vehicleGroups[0].mileageLimit > 0 && (
                            <>
                              <b>Mileage Limit: </b> {getMileageLimitText(bookingData.vehicleGroups[0].mileageLimit, bookingData.rateTypeName, distanceUnit || "mile")}
                            </>
                          )}
                        {bookingData && bookingData.vehicleGroups && bookingData.vehicleGroups[0] && bookingData.vehicleGroups[0].priceRuleName && (
                          <p>
                            <b> Price Rule :</b> {bookingData && bookingData.vehicleGroups[0] ? bookingData.vehicleGroups[0].priceRuleName : ""}
                          </p>
                        )}
                      </Typography>
                    </Grid>
                    <Box mt={1}></Box>
                    {
                      !bookingData.isSubscription && (
                        <Grid container item xs={12}>
                          <Typography style={{ color: "red" }}>
                            Note: The vehicle group pricing might have changed. Please check before proceeding further.
                          </Typography>
                        </Grid>
                      )
                    }
                    <Box mt={1}></Box>
                    <Grid container item xs={12}>
                      <Fab
                        size="medium"
                        variant="extended"
                        disabled={bookingData.isSubscription ? (d.fromISO(extensionData.endDate) > d.fromISO(bookingData.dropoffDateTime)) ? false : true : (d.fromISO(extensionData.endDate) > d.fromISO(bookingData.dropoffDateTime)) ? false : true || !extensionData.dropoffServiceLocation || loading}
                        type="submit"
                      >
                        {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                        Proceed
                      </Fab>
                      <Box pl={2}></Box>
                      <Fab
                        size="medium"
                        variant="extended"
                        className="blackBackButton"
                        onClick={() => {
                          handleClose(true)
                        }}
                      >
                        Cancel
                      </Fab>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
      }
    </React.Fragment>
  )
};

export default ExtendInProgressBooking;