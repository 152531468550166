import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IInvoice, IPaymentInput, PaymentMode } from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CHECK_BOOKING_CONFIRMATION_ELIGIBILITY } from "../../../../../graphql/bookings/checkBookingConfirmationEligibility";
import { ApolloError } from "@apollo/client";
import { formatGraphQLErrorMessage, PaymentGateway } from "../../../../common/utils";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { CREATE_PAYMENT } from "../../../../../graphql/invoices/createPaymentMutation";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { loadStripe } from "@stripe/stripe-js";
import { CHECK_FOR_TBA_WARNINGS } from "../../../../../graphql/bookings/CheckForTbaWarnings";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { ICreditNotesToAdjust } from "./PaymentView";

interface IProps {
  bookingId: string;
  invoice: string;
  businessCustomer?: string;
  amount: number;
  creditAmount: number;
  longTermBooking?: boolean;
  redirectToInvoice?: boolean;
  invoiceData?: IInvoice;
  closeDialog?(): void;
  collectDeposit?: boolean;
  depositAmount?: number;
  tba?: boolean;
  creditNotesToAdjust: ICreditNotesToAdjust[];
  isConsolidated?: boolean;
  isAutochargeSetup?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    basicButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      width: 'calc(100vw - 90px)',
    },
  })
);

const BacsTransaction: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [amount, setAmount] = useState<number>(props.amount);
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("fullAmount");
  const [saveDetails, setSaveDetails] = useState<boolean>(false);
  const [tbaWarning, setTbaWarning] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency, bacsEnabled, stripeAccountId, autoChargeEnabled } = userState.currentOrganisation;
  const [minimumPayableAmount, setMinimumPayableAmount] = useState<number>(0);

  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  useEffect(() => {
    if (autoChargeEnabled && !props.isAutochargeSetup && !props.isConsolidated) {
      setSaveDetails(true)
    }
  }, [autoChargeEnabled, props.isConsolidated, props.isAutochargeSetup])

  const [
    createPayment,
    { loading: sessionLoading }
  ] = useMutation(CREATE_PAYMENT, {
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (data?.createPayment?.payment?.stripeCheckoutSessionId) {
        // Get Stripe.js instance
        const stripe = await stripePromise;
        // When the customer clicks on the button, redirect them to Checkout.
        if (stripe) {
          setLoading(false);
          const result = await stripe.redirectToCheckout({
            sessionId: data.createPayment?.payment?.stripeCheckoutSessionId
          });
          if (result.error && result.error.message) {
            snackbar({
              message: result.error.message,
              variant: SnackBarVariant.ERROR
            });
          }
        }
      }
    }
  });

  const [checkForTbaWarnings] = useLazyQuery(CHECK_FOR_TBA_WARNINGS,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        navigate(`/update-booking?booking=${props.bookingId}&step=3`);
      },
      onCompleted: (data) => {
        if (data.checkForTbaWarnings) {
          setTbaWarning(true)
        } else {
          if (paymentInput) {
            createPayment({
              variables: {
                payment: {
                  ...paymentInput,
                  booking: props.bookingId
                },
                saveDetails,
                collectDeposit: props.collectDeposit || false,
                creditNotesToAdjust: props.creditNotesToAdjust
              }
            });
          }
        }
      }
    }
  )

  const [checkBookingConfirmationEligibility] = useLazyQuery(
    CHECK_BOOKING_CONFIRMATION_ELIGIBILITY,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        navigate(`/update-booking?booking=${props.bookingId}&step=3`);
      },
      onCompleted: () => {
        if (paymentInput) {
          if (props.tba) {
            checkForTbaWarnings({
              variables: {
                bookingId: props.bookingId
              }
            })
          } else {
            createPayment({
              variables: {
                payment: {
                  ...paymentInput,
                  booking: props.bookingId
                },
                saveDetails,
                collectDeposit: props.collectDeposit || false,
                creditNotesToAdjust: props.creditNotesToAdjust
              }
            });
          }
        }
      }
    }
  );

  useEffect(() => {
    if (props.collectDeposit && props.depositAmount) {
      setMinimumPayableAmount(props.depositAmount)
    }
  }, [props.collectDeposit, props.depositAmount])

  useEffect(() => {
    setAmount(props.amount)
  }, [props.amount])

  const handleSubmit = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: amount,
      booking: props.bookingId || "",
      businessCustomer: props.businessCustomer,
      currency,
      description: PaymentMode.BACS_BANK_DEBIT,
      expireBy: "",
      invoice: props.invoice,
      paymentMode: PaymentMode.BACS_BANK_DEBIT,
      paymentType: "INWARD",
      paymentGateway: PaymentGateway.STRIPE,
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/update-booking?booking=${props.bookingId}&step=3`
    };
    if (props.redirectToInvoice) {
      if (props.isConsolidated) {
        payment.successUrl = `${window.location.protocol}//${window.location.host}/update-consolidated-invoice?invoice=${props.invoice}&status=success`;
        payment.cancelUrl = `${window.location.protocol}//${window.location.host}/update-consolidated-invoice?invoice=${props.invoice}&status=failure`;
      } else {
        payment.successUrl = `${window.location.protocol}//${window.location.host}/update-billing?invoice=${props.invoice}&status=success`;
        payment.cancelUrl = `${window.location.protocol}//${window.location.host}/update-billing?invoice=${props.invoice}&status=failure`;
      }
    }
    setPaymentInput(payment);
    if (!props.redirectToInvoice) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: props.bookingId
        }
      });
    } else {
      createPayment({
        variables: {
          payment: {
            ...payment,
            booking: props.bookingId || "",
          },
          saveDetails,
          creditNotesToAdjust: props.creditNotesToAdjust,
          isConsolidated: props.isConsolidated
        }
      });
    }
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(props.amount / 100)}`;

  const AMOUNT_TOO_LOW = `Amount must be more than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(minimumPayableAmount / 100)}`;

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan((minimumPayableAmount - 1), AMOUNT_TOO_LOW)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!bacsEnabled ?
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable bacs bank debit in Payment and Integration Settings..
          </Typography>
        </Grid> :
        <>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                name="isPayingFull"
                value={paymentType}
                onChange={(event) => {
                  if (event.target.value === "fullAmount") {
                    setPaymentType("fullAmount");
                    setAmount(props.amount)
                  } else {
                    setPaymentType("customAmount");
                  }
                }}
              >
                <FormControlLabel
                  value="fullAmount"
                  control={<Radio />}
                  label={
                    <Typography variant="body1">
                      Pay Total Due Amount
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="customAmount"
                  control={<Radio />}
                  label={
                    <Typography variant="body1">Pay Custom Amount</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <Formik
              enableReinitialize
              initialValues={{ amount: amount }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit();
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <Form>
                  <Grid item xs={12}>
                    {paymentType === "customAmount" && (
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          placeholder="Enter Amount to pay"
                          label="Custom Amount"
                          name={"amount"}
                          fullWidth
                          required
                          InputProps={{
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setAmount(parseInt(e.target.value))
                            },
                            value: amount,
                            inputComponent: FloatInput as any
                          }}
                          inputProps={{
                            hasCurrencyPrefix: true,
                            allowNegative: false
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!props.isConsolidated && !props.isAutochargeSetup && (
                    autoChargeEnabled ?
                      <Grid item xs={12}>
                        <Typography>
                          Future charges related to the ongoing booking will be charged to this payment method
                        </Typography>
                      </Grid> :
                      stripeAccountId &&
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={saveDetails}
                                onChange={(e: any) => {
                                  setSaveDetails(e.target.checked)
                                }}
                                value={saveDetails}
                                color="primary"
                                name={"agreed"}
                                disabled={sessionLoading}
                              />
                            }
                            label={
                              <Typography variant="body1">
                                Save this card to make payment for future invoices on this booking.
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid item container>
                      <Box mt={2}></Box>
                    </Grid>
                    <Grid item container>
                      <Hidden xsDown>
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="add"
                          onClick={() => {
                            formikProps.setSubmitting(false);
                            formikProps.handleSubmit();
                          }}
                          disabled={sessionLoading}
                        >
                          {sessionLoading && (
                            <CircularProgress
                              size={14}
                              style={{ color: "white", marginRight: "10px" }}
                            />
                          )}
                          Proceed To Pay
                        </Fab>
                      </Hidden>
                      <Hidden smUp>
                        <Button
                          className={classes.basicButton}
                          variant="contained"
                          onClick={() => {
                            formikProps.setSubmitting(false);
                            formikProps.handleSubmit();
                          }}
                          aria-label="add"
                          disabled={sessionLoading}
                        >
                          {sessionLoading && (
                            <CircularProgress
                              size={14}
                              style={{ color: "white", marginRight: "10px" }}
                            />
                          )}
                          Proceed To Pay
                        </Button>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </>
      }
      {tbaWarning && (
        <ConfirmationDialog
          isOpen={tbaWarning}
          onCancel={() => {
            setTbaWarning(false)
            navigate(`/update-booking?booking=${props.bookingId}&step=3`);
          }}
          onConfirm={() => {
            if (paymentInput) {
              setTbaWarning(false)
              createPayment({
                variables: {
                  payment: {
                    ...paymentInput,
                    booking: props.bookingId
                  },
                  saveDetails,
                  collectDeposit: props.collectDeposit || false,
                  creditNotesToAdjust: props.creditNotesToAdjust
                }
              });
            }
          }}
          title=""
          description={"The vehicles in this booking have some future bookings. Do you wish to continue to with this Flex booking?"}
        />
      )}
    </Grid>
  );
}

export default BacsTransaction;
