import {
  CircularProgress,
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import { DateTime as d } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BILLING_CYCLE_NAME } from "../../../../../reducers/bookings/types";
import { IInvoice } from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  getRateTypeString,
  getTaxesDescription,
  toCurrency
} from "../../../../common/utils";
import { getInvoiceItemTaxValue } from "../../utils";
import { IInvoiceItem, IInvoiceItemType } from "./types";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

interface IProps {
  invoice: IInvoice;
  isEditable: boolean;
  loading: boolean;
  setIsEditable(data: boolean): void;
  updateInvoice(items: IInvoiceItem[]): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    },
    tableRow: {
      borderBottom: "none",
      borderTop: "solid 1px rgba(224, 224, 224, 1)",
      cursor: "pointer"
    },
    tableCell: {
      borderBottom: "none"
    },
    invoiceHeader: {
      "&:hover": {
        color: "blue",
        fontWeight: "bold"
      },
      fontWeight: "bold"
    },
    header: {
      paddingTop: "10px"
    }
  })
);

const columns = [
  "#",
  "Particulars",
  "Quantity",
  "Unit Price",
  "Duration",
  "VAT/TAX",
  "Amount"
];

export const ConsolidatedInvoiceDetails = (props: IProps) => {
  const styles = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [invoiceItems, setInvoiceItems] = useState<IInvoiceItem[]>([]);
  const snackbar = useSnackBar();
  const [values, setValues] = useState<IInvoice>();
  const { invoice } = { ...props };
  const navigate = useNavigate();

  useEffect(() => {
    setValues(invoice);
    if (invoice.invoiceItems) {
      setInvoiceItems(JSON.parse(JSON.stringify(invoice.invoiceItems)));
    }
  }, [invoice]);

  const redirectToInvoice = (invoiceId: string) => {
    navigate(`/update-consolidated-invoice?invoice=${invoiceId}`);
  };

  const addRows = () => {
    const rowsArr = [...invoiceItems];
    rowsArr.push({
      type: IInvoiceItemType.OTHER_CHARGE,
      description: "",
      unitPrice: 0,
      quantity: 1,
      totalAmount: 0,
      taxRate: 0,
      taxAmount: 0
    });
    setInvoiceItems(rowsArr);
  };

  const handleSubmit = () => {
    if (invoiceItems.some((charge) => !charge.description)) {
      return snackbar({
        message: "Invoice item description cannot be empty!",
        variant: SnackBarVariant.ERROR
      });
    }
    if (invoiceItems.some((charge) => !charge.unitPrice)) {
      return snackbar({
        message: "Invoice item price/rate should be greater than 0",
        variant: SnackBarVariant.ERROR
      });
    }
    props.updateInvoice(invoiceItems);
  };

  return (
    <Grid container item xs={12}>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="consolidated invoice table">
          <TableHead>
            <TableRow>
              {columns.map((column: string) => {
                if (country === "United States" && column === "VAT/TAX") {
                  column = "Taxes & Fees";
                }
                return (
                  <TableCell>
                    <Typography variant={"h4"}>{column}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isEditable ? (
              <>
                {invoiceItems?.map((item: IInvoiceItem, idx: number) => {
                  let taxAmount = 0;
                  if (item.taxAmount) {
                    taxAmount = item.taxAmount;
                  }
                  return (
                    <TableRow>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Description"
                          label="Description"
                          name={"description"}
                          inputProps={{
                            value: item.description,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const invoiceItem = JSON.parse(
                                JSON.stringify(invoiceItems)
                              )[idx];
                              const rowsArr = JSON.parse(
                                JSON.stringify(invoiceItems)
                              );
                              invoiceItem.description = e.target.value;
                              rowsArr.splice(idx, 1, invoiceItem);
                              setInvoiceItems(rowsArr);
                            }
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        {item.quantity ? item.quantity : "N/A"}
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          placeholder="Unit Price"
                          label="Unit Price"
                          name={"unitPrice"}
                          InputProps={{
                            value: item.unitPrice,
                            inputComponent: FloatInput as any,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value || "0";
                              const invoiceItem = JSON.parse(
                                JSON.stringify(invoiceItems)
                              )[idx];
                              const rowsArr = JSON.parse(
                                JSON.stringify(invoiceItems)
                              );
                              invoiceItem.unitPrice = parseInt(val);
                              let totalAmount =
                                invoiceItem.unitPrice *
                                (invoiceItem.duration || 1) *
                                invoiceItem.quantity;
                              if (
                                invoiceItem.isProrated &&
                                invoiceItem.proratedBillingDays
                              ) {
                                let standardBillingDays = 1;
                                switch (item.rateTypeName) {
                                  case BILLING_CYCLE_NAME.WEEKLY:
                                    standardBillingDays =
                                      7 * (item.duration || 1);
                                    break;
                                  case BILLING_CYCLE_NAME.MONTHLY:
                                    let idealEndDate = d
                                      .fromISO(invoice.startDate)
                                      .plus({
                                        months: item.duration
                                      })
                                      .toUTC()
                                      .toISO();
                                    standardBillingDays = d
                                      .fromISO(idealEndDate)
                                      .diff(d.fromISO(invoice.startDate)).days;
                                    break;
                                  default:
                                    break;
                                }
                                const proratedFraction = parseFloat(
                                  (
                                    invoiceItem.proratedBillingDays /
                                    standardBillingDays
                                  ).toString()
                                );
                                invoiceItem.totalAmount = Math.round(
                                  totalAmount * proratedFraction
                                );
                              } else {
                                invoiceItem.totalAmount = totalAmount;
                              }
                              if (invoiceItem.type === IInvoiceItemType.ADDON) {
                                invoiceItem.taxAmount =
                                  (invoiceItem.totalAmount *
                                    getInvoiceItemTaxValue(
                                      invoiceItem.taxDescription[0]
                                    )) /
                                  100;
                              }
                              if (
                                invoiceItem.type ===
                                IInvoiceItemType.LOCATION_SURCHARGE
                              ) {
                                let surchargetotalPrice =
                                  invoiceItem.unitPrice *
                                  (invoiceItem.quantity || 1);
                                if (invoiceItem.duration) {
                                  surchargetotalPrice =
                                    surchargetotalPrice * invoiceItem.duration;
                                }
                                invoiceItem.totalAmount = surchargetotalPrice;
                                invoiceItem.taxAmount =
                                  (surchargetotalPrice *
                                    getInvoiceItemTaxValue(
                                      invoiceItem.taxDescription[0]
                                    )) /
                                  100;
                              }
                              rowsArr.splice(idx, 1, invoiceItem);
                              setInvoiceItems(rowsArr);
                            }
                          }}
                          inputProps={{
                            hasCurrencyPrefix: true,
                            allowNegative: false
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {item.duration
                          ? item.isProrated
                            ? `${item.duration} ${getRateTypeString(
                              item.rateTypeName || ""
                            )} (${item.proratedBillingDays} days pro-rated)`
                            : `${item.duration} ${getRateTypeString(
                              item.rateTypeName || ""
                            )}`
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          placeholder="Tax Amount"
                          label="Tax Amount"
                          name={"taxAmount"}
                          disabled={item.type === IInvoiceItemType.INSURANCE}
                          InputProps={{
                            value: item.taxAmount,
                            inputComponent: FloatInput as any,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value || "0";
                              const invoiceItem = JSON.parse(
                                JSON.stringify(invoiceItems)
                              )[idx];
                              const rowsArr = JSON.parse(
                                JSON.stringify(invoiceItems)
                              );
                              invoiceItem.taxAmount = parseInt(val);
                              rowsArr.splice(idx, 1, invoiceItem);
                              setInvoiceItems(rowsArr);
                            }
                          }}
                          inputProps={{
                            hasCurrencyPrefix: true,
                            allowNegative: false
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {toCurrency(
                          item.totalAmount + (item.taxAmount || 0),
                          currency,
                          locale
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <>
                {values?.invoiceItems.map((item: any, idx: number) => {
                  let taxAmount = 0;
                  if (item.taxAmount) {
                    taxAmount = item.taxAmount;
                  }
                  const rentalTaxDetails = getTaxesDescription(
                    values.tax || 0,
                    currency,
                    locale
                  );
                  return (
                    <TableRow>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>
                        {item.type === IInvoiceItemType.RENTAL_AMOUNT
                          ? values?.booking?.isSubscription
                            ? item.description.toUpperCase()
                            : `Vehicle Rental Charges (${item.description.toUpperCase()})`
                          : item.type === IInvoiceItemType.DEPOSIT
                            ? `Deposit (${item.description.toUpperCase()}) (Refundable)`
                            : item.type === IInvoiceItemType.INSURANCE
                              ? `Insurance (${item.description.toUpperCase()})`
                              : item.type === IInvoiceItemType.LOCATION_SURCHARGE
                                ? `Location Surcharge (${item.description.toUpperCase()})`
                                : item.type === IInvoiceItemType.ADDON
                                  ? `${item.description.toUpperCase()}`
                                  : item.description.toUpperCase()}{" "}
                        <br />
                        {item.type === IInvoiceItemType.RENTAL_AMOUNT
                          ? rentalTaxDetails &&
                          rentalTaxDetails.length > 0 &&
                          rentalTaxDetails.map((title) => {
                            return (
                              <>
                                <span>({title})</span>
                                <br></br>
                              </>
                            );
                          })
                          : item.taxDescription &&
                          item.taxDescription.length > 0 &&
                          item.taxDescription.map((title: any) => {
                            return (
                              <>
                                <span>({title})</span>
                                <br></br>
                              </>
                            );
                          })}
                      </TableCell>
                      <TableCell>
                        {item.quantity ? item.quantity : "N/A"}
                      </TableCell>
                      <TableCell>
                        {toCurrency(item.unitPrice, currency, locale)}
                      </TableCell>
                      <TableCell>
                        {item.duration
                          ? item.isProrated
                            ? `${item.duration} ${getRateTypeString(
                              item.rateTypeName || ""
                            )} (${item.proratedBillingDays} days pro-rated)`
                            : `${item.duration} ${getRateTypeString(
                              item.rateTypeName || ""
                            )}`
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {toCurrency(taxAmount, currency, locale)}
                      </TableCell>
                      <TableCell>
                        {toCurrency(
                          item.totalAmount + taxAmount,
                          currency,
                          locale
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}

                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{
                      borderBottom: "none",
                      borderTop: "solid 1px rgba(224, 224, 224, 1)"
                    }}
                  ></TableCell>
                  <TableCell style={{ borderBottom: "none" }}></TableCell>
                  <TableCell
                    style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}
                  >
                    <Typography variant="body1">{"Total"}</Typography>
                  </TableCell>
                  <TableCell>
                    {toCurrency(invoice.totalPayableAmount, currency, locale)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{ borderBottom: "none" }}
                  ></TableCell>
                  <TableCell style={{ borderBottom: "none" }}></TableCell>
                  <TableCell
                    style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}
                  >
                    <Typography variant="body1">{"Paid"}</Typography>
                  </TableCell>
                  <TableCell>
                    {toCurrency(invoice.confirmedPaidAmount, currency, locale)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{ borderBottom: "none" }}
                  ></TableCell>
                  <TableCell style={{ borderBottom: "none" }}></TableCell>
                  <TableCell
                    style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}
                  >
                    <Typography variant="h4">{"Balance Due"}</Typography>
                  </TableCell>
                  <TableCell>
                    {toCurrency(invoice.dueAmount, currency, locale)}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {props.isEditable && (
        <Grid container xs={12} style={{ marginTop: "20px" }}>
          <Grid container xs={1}>
            <Typography
              variant={"subtitle1"}
              onClick={addRows}
              style={{ cursor: "pointer" }}
            >
              + Add New
            </Typography>
          </Grid>
          <Grid container xs={11} justifyContent="flex-end">
            <Fab
              variant="extended"
              size="medium"
              className="blackBackButton"
              style={{ marginRight: "20px" }}
              onClick={() => {
                props.setIsEditable(false);
                setInvoiceItems(values?.invoiceItems || []);
              }}
            >
              Cancel
            </Fab>
            <Fab
              variant="extended"
              size="medium"
              onClick={handleSubmit}
              disabled={props.loading}
            >
              {props.loading && (
                <CircularProgress
                  size={14}
                  style={{ color: "white", marginRight: "10px" }}
                />
              )}
              Update
            </Fab>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} className={styles.header}>
        <Typography variant="h2">Invoices Consolidated</Typography>
      </Grid>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="consolidated invoices table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={"h4"}>#</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"h4"}>Invoice Reference Number</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values?.invoices?.map((item: any, idx: number) => {
              return (
                <TableRow
                  onClick={() => redirectToInvoice(item.id)}
                  className={styles.tableRow}
                >
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>
                    <Typography className={styles.invoiceHeader}>
                      {item.invoiceRef}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
