import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import styles from "./index.module.css";
import _ from 'lodash';
import { DateTime as d } from 'luxon';
import { UserRoles } from '../../hoc/Authorization';
import { getLocalizedBookingSyntex } from '../../../utils/localized.syntex';

export const getYears = () => {
  const thisYear = d.now().get("year") + 1;
  let years = []
  for (var i = thisYear ; i > thisYear - 5; i--) {
    years.push(i);
  }
  return years;
}

export const allowdRolesForVehicleReports: string[] = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.ORGANISATION_OWNER, UserRoles.BRANCH_MANAGER, UserRoles.FLEET_MANAGER];

export const SelectButton: React.FC<{ id: string, onChange: (selected: string) => void, initialValue: string, renderVelue?: (selected: string) => string }> = (props) => {
  const [state, setState] = useState({ open: false, value: props.initialValue })

  const handleChange = (event: any) => {
    setState({ value: event.target.value, open: false });
    props.onChange(event.target.value)
  };
  const handleClose = () => {
    setState((prev) => ({ ...prev, open: false }));
  };
  const handleOpen = () => {
    setState((prev) => ({ ...prev, open: true }));;
  };
  return (
    <FormControl>
      <div id={props.id} onClick={() => handleOpen()} className={styles.filterInput}>{props.renderVelue ? props.renderVelue(state.value) : state.value}</div>
      <Select
        value={state.value}
        onChange={handleChange}
        input={<Input />}
        style={{ display: "none" }}
        open={state.open}
        onClose={handleClose}
        MenuProps={{
          anchorEl: document.getElementById(props.id),
          style: { marginTop: 10 }
        }}
      >
        {props.children}
      </Select>
    </FormControl>
  )
}

export const filteredPickupTiming = (fromDate: string, toDate: string, type: string, rawData: any) => {
  let data = _.cloneDeep(dayDataTemplate);
  if (rawData?.bookingStart) {
    const bookingStartData = rawData.bookingStart;
    switch (type) {
      case "MONTH":
        data = _.cloneDeep(monthDataTemplate);
        for (let i = 0; i < bookingStartData.length; i++) {
          const monthNumber = d.fromFormat(bookingStartData[i], "yyyy-MM-dd").get("month") - 1;
          if (d.fromFormat(bookingStartData[i], 'yyyy-MM-dd') <= d.fromISO(toDate) && d.fromFormat(bookingStartData[i], 'yyyy-MM-dd') >= d.fromISO(fromDate)) {
            data[monthNumber].y += 1
          }
        }
        break;

      case "DAY":
        for (let i = 0; i < bookingStartData.length; i++) {
          if (d.fromFormat(bookingStartData[i], 'yyyy-MM-dd') <= d.fromISO(toDate) && d.fromFormat(bookingStartData[i], 'yyyy-MM-dd') >= d.fromISO(fromDate)) {
            const dayNumber = new Date(bookingStartData[i]).getDay();
            data[dayNumber].y += 1
          }
        }
        break;

      case "TIME":
        data = [];
        for (let i = 0; i < 24; i++) {
          data.push({
            "x": `${i + 1}`,
            "y": 0
          })
        }
        for (let i = 0; i < bookingStartData.length; i++) {
          if (d.fromFormat(bookingStartData[i], 'yyyy-MM-dd') < d.fromISO(toDate) && d.fromFormat(bookingStartData[i], 'yyyy-MM-dd') > d.fromISO(fromDate)) {
            const timeOfDay = rawData.hourOfDayStart[i]
            data[parseInt(timeOfDay)].y += 1
          }
        }
        break;
    }
  }

  return [{
    "id": "Pickup",
    "color": "hsl(35, 70%, 50%)",
    "data": data
  }]
}
export const filteredDropOffTiming = (fromDate: string, toDate: string, type: string, rawData: any) => {
  let data = _.cloneDeep(dayDataTemplate);
  if (rawData?.bookingEnd) {
    const bookingEndData = rawData.bookingEnd;
    switch (type) {
      case "MONTH":
        data = _.cloneDeep(monthDataTemplate);
        for (let i = 0; i < bookingEndData.length; i++) {
          const monthNumber = d.fromFormat(bookingEndData[i], 'yyyy-MM-dd').get("month") - 1;
          if (d.fromFormat(bookingEndData[i], 'yyyy-MM-dd') <= d.fromISO(toDate) && d.fromFormat(bookingEndData[i], 'yyyy-MM-dd') >= d.fromISO(fromDate)) {
            data[monthNumber].y += 1
          }
        }
        break;

      case "DAY":
        for (let i = 0; i < bookingEndData.length; i++) {
          if (d.fromFormat(bookingEndData[i], 'yyyy-MM-dd') <= d.fromISO(toDate) && d.fromFormat(bookingEndData[i], 'yyyy-MM-dd') >= d.fromISO(fromDate)) {
            const dayNumber = new Date(bookingEndData[i]).getDay();
            data[dayNumber].y += 1
          }
        }
        break;

      case "TIME":
        data = [];
        for (let i = 0; i < 24; i++) {
          data.push({
            "x": `${i + 1}`,
            "y": 0
          })
        }
        for (let i = 0; i < bookingEndData.length; i++) {
          if (d.fromFormat(bookingEndData[i], 'yyyy-MM-dd') < d.fromISO(toDate) && d.fromFormat(bookingEndData[i], 'yyyy-MM-dd') > d.fromISO(fromDate)) {
            const timeOfDay = rawData.hourOfDayEnd[i]
            data[parseInt(timeOfDay)].y += 1
          }
        }
        break;
    }
  }

  return [{
    "id": "DropOff",
    "color": "hsl(35, 70%, 50%)",
    "data": data
  }]
}

export const dayDataTemplate = [
  {
    "x": "Sun",
    "y": 0
  },
  {
    "x": "Mon",
    "y": 0
  },
  {
    "x": "Tue",
    "y": 0
  },
  {
    "x": "Wed",
    "y": 0
  },
  {
    "x": "Thu",
    "y": 0
  },
  {
    "x": "Fri",
    "y": 0
  },
  {
    "x": "Sat",
    "y": 0
  }
]

export const monthDataTemplate = [
  {
    "x": "Jan",
    "y": 0
  },
  {
    "x": "Feb",
    "y": 0
  },
  {
    "x": "Mar",
    "y": 0
  },
  {
    "x": "Apr",
    "y": 0
  },
  {
    "x": "May",
    "y": 0
  },
  {
    "x": "Jun",
    "y": 0
  },
  {
    "x": "Jul",
    "y": 0
  },
  {
    "x": "Aug",
    "y": 0
  },
  {
    "x": "Sep",
    "y": 0
  },
  {
    "x": "Oct",
    "y": 0
  },
  {
    "x": "Nov",
    "y": 0
  },
  {
    "x": "Dec",
    "y": 0
  }
]

export const InvoiceExtractFields: string[] = [
  "branch",
  "customerName",
  "customerEmail",
  "customerAddress",
  "nationalInsuranceNumber",
  "reference",
  "bookingReference",
  "type",
  "dueDate",
  "dateCreated",
  "status",
  "paidOn",
  "netAmount",
  "taxAmount",
  "totalAmount",
  "details",
  "quantity",
  "unitPrice",
  "discountAmount",
  "accountReference",
  "taxCode",
  "pickupDate",
  "dropoffDate",
  "invoicePeriod",
  "registrationNumber",
  "notes",
  "costCenter",
  "projectId"
];

export const LocalizeInvoiceExtractMap = (country : string) => {
  return {
  branch: "Branch",
  customerName: "Customer Name",
  customerEmail: "Customer Email",
  customerAddress: "Customer Address",
  nationalInsuranceNumber: "National Insurance Number",
  reference: "Invoice Reference",
  bookingReference: `${getLocalizedBookingSyntex(country)} Ref`,
  type: "Invoice Type",
  dueDate: "Due Date",
  dateCreated: "Generated Date",
  status: "Invoice Status",
  netAmount: "Net Amount",
  taxAmount: "Tax Amount",
  totalAmount: "Total Amount",
  details: "Details",
  quantity: "Quantity",
  unitPrice: "Unit Price",
  discountAmount: "Discount Amount",
  accountReference: "Account Reference",
  taxCode: "Tax Code",
  pickupDate: "Pick-up Date",
  dropoffDate: "Drop-off Date",
  invoicePeriod: "Invoice Period",
  registrationNumber: "Registration Number",
  notes: "Notes",
  costCenter: "Cost Center",
  projectId: "Project Id",
  paidOn: "Paid On"
  }
}

export const ExtractTypes = {
  CUSTOM: "CUSTOM",
  SAGE_COMPATIBLE: "SAGE_COMPATIBLE",
  CREATE_TEMPLATE: "CREATE_TEMPLATE",
}

export const formatSecondsToHour = (seconds: number) => {
  var h = Math.floor(seconds / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 3600 % 60);
  var h_string = h > 9 ? `${h}` : `0${h}`;
  var m_string = m > 9 ? `${m}` : `0${m}`;
  var s_string = s > 9 ? `${s}` : `0${s}`;
  return `${h_string}:${m_string}:${s_string}`;
}

export const getQuartersList = () => {
  const currentDate = d.now().toFormat("YYYY-M-d");
  const quartersEndDate = [`${d.now().get("year")}-03-31`, `${d.now().get("year")}-06-30`, `${d.now().get("year")}-09-30`, `${d.now().get("year") - 1}-12-31`];
  if (currentDate > quartersEndDate[0] && currentDate < quartersEndDate[1]) {
    return ["Q1"];
  } else if (currentDate > quartersEndDate[1] && currentDate < quartersEndDate[2]) {
    return ["Q1", "Q2"];
  } else if (currentDate > quartersEndDate[2] && currentDate < quartersEndDate[3]) {
    return ["Q1", "Q2", "Q3"];
  } else if (currentDate >= quartersEndDate[3]) {
    return ["Q1", "Q2", "Q3", "Q4"];
  } else {
    return [];
  }
}