import "./index.scss";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  TextField as InputField,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  ButtonBase,
  Checkbox,
  FormGroup,
  Button,
  Autocomplete
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpwardRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { ApolloError } from "@apollo/client";
import { Field, FieldProps, Form, Formik, ErrorMessage } from "formik";
import { TextField } from "formik-mui";
import { DateTime as d } from "luxon";
import React, { ChangeEvent, useEffect, useState } from "react";
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  resetBranch,
  updateBranch
} from "../../../../../actions/organisation/actions";
import { updateUserCurrentBranch } from "../../../../../actions/user/actions";
import { CREATE_BRANCH } from "../../../../../graphql/organisation/createBranchMutation";
import { GET_BRANCHES } from "../../../../../graphql/organisation/getBranch";
import { GET_USERS } from "../../../../../graphql/organisation/getUsersQuery";
import { UPDATE_BRANCH } from "../../../../../graphql/organisation/updateBranchMutation";
import {
  DEFAULT_RENTAL_AGREEMENT_SETTINGS,
  DEFAULT_SUBSCRIPTION_AGREEMENT_SETTINGS,
  DepositCollection,
  MinimumBookingAmountTypes,
  taxRateTypes,
  taxTypes
} from "../../../../../reducers/organisation/const";
import {
  IBranch,
  IHoliday,
  IOperatingHours,
  IOptionsMenu,
  IOrganisation,
  ITaxInput,
  TaxValueType
} from "../../../../../reducers/organisation/types";
import { IAddress } from "../../../../../reducers/user/types";
import { IAppState } from "../../../../../store";
import {
  getDefaultCountryCode,
  getLocalizedBookingSyntex
} from "../../../../../utils/localized.syntex";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { TimePicker } from "../../../../common/TimePicker";
import {
  formatGraphQLErrorMessage,
  getImage,
  returnfileContentTypeAndExtention
} from "../../../../common/utils";
import { UserRoles } from "../../../../hoc/Authorization";
import styles from "../../../AppSettings/NewAppSetting/index.module.css";
import { ANSWER_TYPES, IBookingQuestion } from "../../../AppSettings/types";
import AddressPicker from "../../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import DragDropImages from "../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { AddHolidaysDialog } from "./AddHolidaysDialog";
import BranchList from "./BranchList";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { TIMEZONES } from "./languageCurrencyConstant";
import MobileBranchCards from "./MobileBranchCards";
import { QuestionsDialog } from "./QuestionsDialog";
import { ViewLogo } from "./UploadLogo";
import { Add, Remove } from "@mui/icons-material";
import { ColorPickerField } from "./SketchPicker";
import { IVehiclePriceGroup } from "../../../../../reducers/vehiclePriceGroups/types";
import { GET_VEHICLE_GROUPS_PRICE } from "../../../../../graphql/priceRules/getVehiclesGroupsQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flexGrow: 1,
      paddingRight: 70,
      [theme.breakpoints.down('md')]: {
        width: "calc(100vw - 45px)",
      },
      [theme.breakpoints.up("sm")]: {
        width: "calc(100vw - 245px)"
      }
    },
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    formControlLabel: {
      fontSize: 14,
      fontWeight: "bold"
    },
    cardStyle: {
      padding: "5px",
      height: "120px",
      borderRadius: "12px",
      width: "calc(100vw - 148px)"
    }
  })
);

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 22,
      padding: 0,
      display: "flex"
    },
    switchBase: {
      padding: 4,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "var(--theme-primary)",
          borderColor: "var(--theme-primary)"
        }
      }
    },
    thumb: {
      width: 14,
      height: 14,
      boxShadow: "none"
    },
    track: {
      border: `1px solid ${theme.palette.grey[800]}`,
      borderRadius: 22 / 2,
      height: 22,
      opacity: 1,
      backgroundColor: theme.palette.common.white
    },
    checked: {}
  })
)(Switch);

const initialBookingQuestionData: IBookingQuestion = {
  question: "",
  answerType: ANSWER_TYPES.YES_NO,
  required: false,
  expectedAnswer: "YES",
  options: [""],
  errorMessage: "",
  B2B: true,
  B2B2C: true
};

interface IBranchProps {
  organisation: IOrganisation;
  branch: IBranch;
  step: number;
}

const initialOperatingHours: IOperatingHours[] = [
  {
    day: "Monday",
    dayIndex: 1,
    workingFromTime: "08:00",
    workingToTime: "20:00",
    pickupFromTime: "08:00",
    pickupToTime: "20:00",
    returnFromTime: "08:00",
    returnToTime: "20:00",
    closed: false
  },
  {
    day: "Tuesday",
    dayIndex: 2,
    workingFromTime: "08:00",
    workingToTime: "20:00",
    pickupFromTime: "08:00",
    pickupToTime: "20:00",
    returnFromTime: "08:00",
    returnToTime: "20:00",
    closed: false
  },
  {
    day: "Wednesday",
    dayIndex: 3,
    workingFromTime: "08:00",
    workingToTime: "20:00",
    pickupFromTime: "08:00",
    pickupToTime: "20:00",
    returnFromTime: "08:00",
    returnToTime: "20:00",
    closed: false
  },
  {
    day: "Thursday",
    dayIndex: 4,
    workingFromTime: "08:00",
    workingToTime: "20:00",
    pickupFromTime: "08:00",
    pickupToTime: "20:00",
    returnFromTime: "08:00",
    returnToTime: "20:00",
    closed: false
  },
  {
    day: "Friday",
    dayIndex: 5,
    workingFromTime: "08:00",
    workingToTime: "20:00",
    pickupFromTime: "08:00",
    pickupToTime: "20:00",
    returnFromTime: "08:00",
    returnToTime: "20:00",
    closed: false
  },
  {
    day: "Saturday",
    dayIndex: 6,
    workingFromTime: "08:00",
    workingToTime: "20:00",
    pickupFromTime: "08:00",
    pickupToTime: "20:00",
    returnFromTime: "08:00",
    returnToTime: "20:00",
    closed: true
  },
  {
    day: "Sunday",
    dayIndex: 7,
    workingFromTime: "08:00",
    workingToTime: "20:00",
    pickupFromTime: "08:00",
    pickupToTime: "20:00",
    returnFromTime: "08:00",
    returnToTime: "20:00",
    closed: true
  }
];

const initialBookingAmount = {
  type: "FIXED",
  value: 0
};
interface Section {
  reason: string;
  color: string;
}

interface ISelectedVehicleGroups {
  taxTitle: string;
  vehicleGroups: IVehiclePriceGroup[]
}

export const vehiclePreparationTime = [
  {
    value: 15,
    label: "15 Minutes"
  },
  {
    value: 30,
    label: "30 Minutes"
  },
  {
    value: 45,
    label: "45 Minutes"
  },
  {
    value: 60,
    label: "1 Hour"
  },
  {
    value: 120,
    label: "2 Hours"
  },
  {
    value: 180,
    label: "3 Hours"
  },
  {
    value: 240,
    label: "4 Hours"
  },
  {
    value: 300,
    label: "5 Hours"
  },
  {
    value: 360,
    label: "6 Hours"
  }
];

export const dashboardReminders = [
  {
    value: 7,
    label: "7 Days"
  },
  {
    value: 15,
    label: "15 Days"
  },
  {
    value: 30,
    label: "30 Days"
  },
  {
    value: 60,
    label: "60 Days"
  },
  {
    value: 90,
    label: "90 Days"
  }
];

export const autoPayDays: IOptionsMenu[] = [
  {
    value: 1,
    label: "Monday"
  },
  {
    value: 2,
    label: "Tuesday"
  },
  {
    value: 3,
    label: "Wednesday"
  },
  {
    value: 4,
    label: "Thursday"
  },
  {
    value: 5,
    label: "Friday"
  },
  {
    value: 6,
    label: "Saturday"
  },
  {
    value: 7,
    label: "Sunday"
  }
];

enum BookingQuestionType {
  "PRE_BOOKING" = "PRE_BOOKING",
  "VEHICLE_PICKUP" = "VEHICLE_PICKUP",
  "ADDITIONAL_DRIVER" = "ADDITIONAL_DRIVER"
}

const Branch = (props: IBranchProps) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(
    false
  );
  const [openTaxDialog, setOpenTaxDialog] = useState<boolean>(false);
  const branchToUpdate = useSelector(
    (state: IAppState) => state.organisationReducer.branch
  );
  const createdOrganisation = useSelector(
    (state: IAppState) => state.organisationReducer.organisation
  );
  const [values, setValues] = useState(props.branch);
  const [branches, setBranches] = useState<IBranch[]>([]);
  const [countryCode, setCountryCode] = useState<any>(
    getDefaultCountryCode(country)
  );
  const [signature, setSignature] = useState("");
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string>("");
  const [operatingHours, setOperatingHours] = useState<IOperatingHours[]>([]);
  const [openAddHolidayDialog, setOpenAddHolidayDialog] = useState<boolean>(
    false
  );
  const [holiday, setHoliday] = useState<IHoliday>();
  const [holidays, setHolidays] = useState<IHoliday[]>([]);
  const [holidayIndex, setHolidayIndex] = useState<number | null>(null);
  const [questionDialogData, setQuestionDialogData] = useState<{
    open: boolean;
    data: IBookingQuestion;
    questionType?: string;
    questionIndex?: number;
    isPreBooking?: boolean;
  }>({
    open: false,
    data: initialBookingQuestionData
  });
  const [dialog, setDialog] = useState<{
    open: boolean;
    data: IOperatingHours;
  }>({
    open: false,
    data: initialOperatingHours[0]
  });
  const [epyxDetails, setEpyxDetails] = useState({
    epyxAccountNumber: "",
    epyxSchemeReferenceNumber: ""
  });
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [selectedVehicleGroups, setSelectedVehicleGroups] = useState<ISelectedVehicleGroups[]>([]);

  let organisationId = "";
  if (props.organisation.id) {
    organisationId = props.organisation.id;
  } else if (createdOrganisation) {
    organisationId = createdOrganisation.id;
  }
  const { data: usersList } = useQuery(GET_USERS);
  const branch = userState.currentBranch;
  const [getBranches, { data: branchesData }] = useLazyQuery(GET_BRANCHES, {
    fetchPolicy: "network-only"
  });

  const [loadVehicleGroups,
    { loading: vehicleGroupsLoading, data: vehicleGroupsData }
  ] = useLazyQuery(
    GET_VEHICLE_GROUPS_PRICE, {
    fetchPolicy: "network-only"
  });

  const [addBranchMutation, { loading: branchLoading }] = useMutation(
    CREATE_BRANCH,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        snackbar({
          message: "Branch created",
          variant: SnackBarVariant.SUCCESS
        });
        dispatch(resetBranch());
        getBranches({
          variables: {
            tenancyId: userState.currentTenancy.id,
            organisationId
          }
        });
        // reset branch fields after saving
        setValues({
          ...props.branch,
          operatingHours: initialOperatingHours
        });
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [updateBranchMutation, { loading: updateBranchLoading }] = useMutation(
    UPDATE_BRANCH,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        snackbar({
          message: "Branch updated",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(
          `/update-organisation?organisation=${props.organisation.id}&step=${props.step}`
        );
        dispatch(updateBranch(data.updateBranch));
        dispatch(resetBranch());
        getBranches({
          variables: {
            tenancyId: userState.currentTenancy.id,
            organisationId
          }
        });
        if (data.updateBranch.id === userState.currentBranch.id) {
          dispatch(updateUserCurrentBranch(data.updateBranch));
        }
        setIsUpdateSuccessful(true);
        // reset branch fields after saving
        setValues({
          ...props.branch,
          operatingHours: initialOperatingHours
        });
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  useEffect(() => {
    if (branchToUpdate) {
      if (branchToUpdate.eventsColor && branchToUpdate.eventsColor.length) {
        setSections(branchToUpdate.eventsColor);
      } else {
        setSections([{ reason: "", color: "" }]);
      }
      let _operatingHours = initialOperatingHours;
      if (
        branchToUpdate.operatingHours &&
        branchToUpdate.operatingHours.length
      ) {
        _operatingHours = branchToUpdate.operatingHours.map((opHr) => {
          if (opHr.day === "Sunday" && opHr.dayIndex === 0) {
            return {
              ...opHr,
              dayIndex: 7
            };
          }
          return opHr;
        });
      }
      if (!branchToUpdate.signatureUrl) {
        setSignature("");
      }
      setOperatingHours(_operatingHours);
      _setValues(branchToUpdate);
      if (vehicleGroupsData) {
        const savedGroups = branchToUpdate.taxes.map((tax) => ({
          taxTitle: tax.title,
          vehicleGroups: vehicleGroupsData.vehicleGroups.filter((group: any) =>
            tax.vehicleGroups && tax.vehicleGroups.includes(group.id)
          ),
        }));
        setSelectedVehicleGroups(savedGroups);
      }
    }
  }, [branchToUpdate, vehicleGroupsData]);

  useEffect(() => {
    getBranches({
      variables: {
        tenancyId: userState.currentTenancy.id,
        organisationId
      }
    });
    loadVehicleGroups();
  }, [organisationId]);

  useEffect(() => {
    if (branchesData && branchesData.branches) {
      setBranches(branchesData.branches);
    }
  }, [branchesData]);

  useEffect(() => {
    if (vehicleGroupsData && vehicleGroupsData.vehicleGroups) {
      const sortedGroups = [...vehicleGroupsData.vehicleGroups].sort((firstGroup: IVehiclePriceGroup, secondGroup: IVehiclePriceGroup) => {
        return firstGroup.name.toLowerCase().localeCompare(secondGroup.name.toLowerCase());
      })
      setVehicleGroups(sortedGroups)
    }
  }, [vehicleGroupsData])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setEpyxDetails({
      ...epyxDetails,
      [event.target.name]: event.target.value
    });
  };

  const _setValues = async (data: IBranch) => {
    setEpyxDetails({
      ...epyxDetails,
      epyxAccountNumber: data.epyxAccountNumber || "",
      epyxSchemeReferenceNumber: data.epyxSchemeReferenceNumber || ""
    });
    setValues({
      address: data.address
        ? data.address
        : {
          city: "",
          country: "",
          fullAddress: "",
          state: "",
          street: "",
          zipcode: ""
        },
      emailId: data.emailId ? data.emailId : "",
      id: data.id ? data.id : "",
      name: data.name ? data.name : "",
      owner: data.owner,
      phoneNumber: data.phoneNumber
        ? data.phoneNumber
        : { phone: "" as Value, country: "" },
      timeZone: data.timeZone ? data.timeZone : "Europe/London",
      operatingHours:
        data.operatingHours && data.operatingHours.length
          ? data.operatingHours
          : initialOperatingHours,
      minimumBookingAmount: data.minimumBookingAmount || initialBookingAmount,
      overbookingAllowed: data.overbookingAllowed,
      minBookingDurationOptIn: data.minBookingDurationOptIn || false,
      minBookingDuration: data.minBookingDuration || 0,
      corporateRateCardEnabled: data.corporateRateCardEnabled ? true : false,
      autoColorEnabled: data.autoColorEnabled ? true : false,
      eventsColor: data.eventsColor && data.eventsColor.length ? data.eventsColor : [{reason: "", color: ""}],
      // minExtensionDurationOptIn: data.minExtensionDurationOptIn || false,
      // minimumExtensionDuration: data.minimumExtensionDuration || 0,
      minBookingAmountOptIn: data.minBookingAmountOptIn
        ? true
        : data.id
          ? false
          : true,
      preBookingQuestions: data.preBookingQuestions || [],
      deliveryQuestions: data.deliveryQuestions || [],
      distanceUnit: data.distanceUnit || "mile",
      depositCollection:
        data.depositCollection || DepositCollection.AT_BOOKING_CONFIRMATION,
      netPaymentTerms: data.netPaymentTerms || 0,
      dailyBillingCycle: data.dailyBillingCycle || 30,
      weeklyBillingCycle: data.weeklyBillingCycle || 1,
      monthlyBillingCycle: data.monthlyBillingCycle || 1,
      vehiclePreparationTime: data.vehiclePreparationTime || 15,
      carShareGracePeriodMins: data.carShareGracePeriodMins || 15,
      useThresholds: data.useThresholds ? data.useThresholds : false,
      offenceThreshold: data.offenceThreshold || 0,
      pointThreshold: data.pointThreshold || 0,
      holidays: data.holidays || [],
      addTaxOptIn: data.addTaxOptIn,
      taxes: data.taxes,
      durationBasedPricingEnabled: data.durationBasedPricingEnabled,
      epyxSchemeReferenceNumber: data.epyxSchemeReferenceNumber || "",
      epyxAccountNumber: data.epyxAccountNumber || "",
      signatureUrl: data.signatureUrl,
      isAdditionalDriverQuestionsEnabled: data.isAdditionalDriverQuestionsEnabled,
      additionalDriverQuestions: data.additionalDriverQuestions || [],
      dashboardReminder: data.dashboardReminder || 7,
      showPaymentMethodInvoice: data.showPaymentMethodInvoice || false,
      paymentMethodString: data.paymentMethodString || "",
      subscriptionSettings: {
        creditCheckEnabled: data.subscriptionSettings?.creditCheckEnabled || false,
        dvlaVerificationEnabled: data.subscriptionSettings?.dvlaVerificationEnabled || false,
        idvEnabled: data.subscriptionSettings?.idvEnabled || false
      },
      autoPayEnabled: data.autoPayEnabled || false,
      autoPayDays: data.autoPayDays || [],
    });
    if (data.signatureUrl) {
      const image = await getImage(data.signatureUrl);
      setSignature(image);
    }
    if (data.holidays) {
      setHolidays(data.holidays);
    }
  };

  const saveBranch = (values: IBranch) => {
    values.name = values.name.trim();
    if (values.autoPayEnabled && !values.autoPayDays?.length) {
      return snackbar({
        message: "Please select at least one Auto Pay Day",
        variant: SnackBarVariant.ERROR
      })
    }
    if (values.id) {
      delete values.id;
      values.eventsColor = sections
      updateBranchMutation({
        variables: {
          branch: {
            ...values,
            owner: values.owner.id
          },
          organisationId,
          branchId: branchToUpdate.id
        }
      });
    } else {
      delete values.id;
      values.eventsColor = sections
      addBranchMutation({
        variables: {
          branch: {
            ...values,
            owner: values.owner.id,
            rentalAgreementSettings: DEFAULT_RENTAL_AGREEMENT_SETTINGS,
            subscriptionAgreementSettings: DEFAULT_SUBSCRIPTION_AGREEMENT_SETTINGS
          },
          organisationId
        }
      });
    }
    setSignature("");
  };

  const uploadDocument = async (files: File[]) => {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      if (uploadInProgress) {
        return snackbar({
          message: "Please wait image upload in progress!",
          variant: SnackBarVariant.ERROR
        });
      }

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 50MB limit exceeded
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        setUploadInProgress(true);
        if (file.type === "image/png") {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const uniqueId = uuidv4();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_.add({
              name: key,
              file: file,
              complete: async () => {
                snackbar({
                  message: "Signature Uploaded Successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                const image = await getImage(key);
                setSignature(image);
                setDocumentDialogVisible(false);
                setUploadInProgress(false);
                setValues({
                  ...values,
                  signatureUrl: key
                });
              }
            });
          }
        } else {
          setDocumentDialogVisible(false);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload .png image format!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
    } catch (err: any) {
      snackbar({
        message: formatGraphQLErrorMessage(err.message),
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const branchSchema = Yup.object().shape({
    address: Yup.object()
      .shape({
        fullAddress: Yup.string().required("Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
        zipcode: Yup.string().required("Zip/Postal Code is required")
      })
      .required("Address is required"),
    emailId: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    name: Yup.string().required("Branch name is required."),
    owner: Yup.object().shape({
      id: Yup.string().required("Please select a Manager."),
      email: Yup.string().required("Please select a Manager.")
    }),
    timeZone: Yup.string().required("Please select a time-zone."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    minBookingDurationOptIn: Yup.boolean(),
    minBookingDuration: Yup.number().when("minBookingDurationOptIn", {
      is: true,
      then: schema => schema
        .typeError("Minimum booking duration can not be less than 1 hour")
        .required()
        .min(1, "Minimum booking duration can not be less than 1 hour")
        .integer("Minimum booking days should be integer only")
    }),
    distanceUnit: Yup.string().required("Please select distance unit."),
    depositCollection: Yup.string().required(
      "Please select desposit collection."
    ),
    vehiclePreparationTime: Yup.number()
      .integer()
      .required()
      .min(15, "Please select vehicle preparation time."),
    carShareGracePeriodMins: Yup.number()
      .integer()
      .required()
      .min(15, "Please select carshare grace period time"),
    signatureUrl: Yup.string()
      .required("Operator Signature is required.")
      .typeError("Operator Signature is required."),
    dashboardReminder: Yup.number()
      .integer(),
    autoPayEnabled: Yup.boolean(),
    autoPayDays: Yup.array().of(Yup.number())
  });

  const operatingDaySchema = Yup.object().shape({
    pickupFromTime: Yup.string().when("closed", {
      is: false,
      then: schema => schema.test("Time test", "Invalid time", (value: any) => {
        return d.fromFormat(value, "hh:mm").isValid;
      })
    }),
    pickupToTime: Yup.string().when("closed", {
      is: false,
      then: schema => schema.test("Time test", "Invalid time", (value: any) => {
        return d.fromFormat(value, "hh:mm").isValid;
      })
    }),
    returnFromTime: Yup.string().when("closed", {
      is: false,
      then: schema => schema.test("Time test", "Invalid time", (value: any) => {
        return d.fromFormat(value, "hh:mm").isValid;
      })
    }),
    returnToTime: Yup.string().when("closed", {
      is: false,
      then: schema => schema.test("Time test", "Invalid time", (value: any) => {
        return d.fromFormat(value, "hh:mm").isValid;
      })
    })
  });

  const paymentGatewayAvailable = () => {
    if (props.organisation.stripeAccountId) {
      return true;
    } else if (props.organisation.convergeEnabled) {
      return true;
    } else {
      return false;
    }
  }

  const validateMinimumAmount = (value: any, required: boolean) => {
    let error;
    if (isNaN(value)) {
      error = "Please enter a valid number";
    }
    if (required && value > 0 && !paymentGatewayAvailable()) {
      error =
        "Payment Gateway integration is required for accepting payments for booking from website";
    }
    return error;
  };

  const handleDialogueClose = () => {
    setDialog({
      ...dialog,
      open: false
    });
  };

  const saveDailogData = (bookingQuestion: IBookingQuestion) => {
    if (questionDialogData.questionType === BookingQuestionType.PRE_BOOKING) {
      const preBookingQuestions = [...values.preBookingQuestions];
      const preBookingQuestionIndex = preBookingQuestions.findIndex(
        (preBookingQuestion, index) =>
          index === questionDialogData.questionIndex
      );
      if (preBookingQuestionIndex !== -1) {
        preBookingQuestions[preBookingQuestionIndex] = bookingQuestion;
      } else {
        preBookingQuestions.push(bookingQuestion);
      }
      setValues({
        ...values,
        preBookingQuestions: [...preBookingQuestions]
      });
      setQuestionDialogData({ ...questionDialogData, open: false });
    } else if (questionDialogData.questionType === BookingQuestionType.ADDITIONAL_DRIVER) {
      const additionalDriverQuestions = [...values.additionalDriverQuestions];
      const additionalDriverQuestionsIndex = additionalDriverQuestions.findIndex(
        (additionalDriverQuestions, index) =>
          index === questionDialogData.questionIndex
      );
      if (additionalDriverQuestionsIndex !== -1) {
        additionalDriverQuestions[additionalDriverQuestionsIndex] = bookingQuestion;
      } else {
        additionalDriverQuestions.push(bookingQuestion);
      }
      setValues({
        ...values,
        additionalDriverQuestions: [...additionalDriverQuestions]
      });
      setQuestionDialogData({ ...questionDialogData, open: false });
    }
    else {
      const deliveryQuestions = [...values.deliveryQuestions];
      const vehiclePickUpQuestionIndex = deliveryQuestions.findIndex(
        (vehiclePickUpQuestion, index) =>
          index === questionDialogData.questionIndex
      );
      if (vehiclePickUpQuestionIndex !== -1) {
        deliveryQuestions[vehiclePickUpQuestionIndex] = bookingQuestion;
      } else {
        deliveryQuestions.push(bookingQuestion);
      }
      setValues({
        ...values,
        deliveryQuestions: [...deliveryQuestions]
      });
      setQuestionDialogData({ ...questionDialogData, open: false });
    }
  };

  const handleQuestionDelete = () => {
    if (questionDialogData.questionType === BookingQuestionType.PRE_BOOKING) {
      const preBookingQuestions = [...values.preBookingQuestions];
      if (typeof questionDialogData.questionIndex === "number") {
        preBookingQuestions.splice(questionDialogData.questionIndex, 1);
        setValues({
          ...values,
          preBookingQuestions: [...preBookingQuestions]
        });
      }
    } else if (questionDialogData.questionType === BookingQuestionType.ADDITIONAL_DRIVER) {
      const additionalDriverQuestions = [...values.additionalDriverQuestions];
      if (typeof questionDialogData.questionIndex === "number") {
        additionalDriverQuestions.splice(questionDialogData.questionIndex, 1);
        setValues({
          ...values,
          additionalDriverQuestions: [...additionalDriverQuestions]
        });
      }
    } else {
      const deliveryQuestions = [...values.deliveryQuestions];
      if (typeof questionDialogData.questionIndex === "number") {
        deliveryQuestions.splice(questionDialogData.questionIndex, 1);
        setValues({
          ...values,
          deliveryQuestions: [...deliveryQuestions]
        });
      }
    }
    setQuestionDialogData({ ...questionDialogData, open: false });
  };

  const getQuestionTag = (bookingQuestion: IBookingQuestion) => (
    <div className={styles.tag}>
      {bookingQuestion.B2B && bookingQuestion.B2B2C
        ? "Web App + Website"
        : bookingQuestion.B2B2C
          ? "Website Only"
          : bookingQuestion.B2B
            ? "Web App only"
            : ""}
    </div>
  );
  const initialSectionValue = { reason: "", color: "" };
  const [sections, setSections] = useState<Section[]>([initialSectionValue]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isEnabled = event.target.checked;
    setValues({ ...values, autoColorEnabled: isEnabled });

    if (isEnabled && branchToUpdate.eventsColor) {
      setDialogOpen(false);
      setSections([...branchToUpdate.eventsColor]);
    } else if (isEnabled) {
      setDialogOpen(false);
      setSections([initialSectionValue]);
    }
  };

  const handleSectionChange = (index: number, field: keyof Section, value: string) => {
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === index ? { ...section, [field]: value } : section
      )
    );
  };

  const handleAddSection = () => {
    setSections([...sections, { reason: "", color: "" }]);
  };

  const handleRemoveSection = (index: number) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSections([...values.eventsColor]);
  };
  
  const handleSaveSections = () => {
    setValues({ ...values, eventsColor: [...sections] });
    setDialogOpen(false);
  };

  const stripeAccountId = props.organisation.stripeAccountId;

  const addHoliday = (newHoliday: IHoliday) => {
    const holidaysArray = [...holidays];
    const existingHolidayIndex = holidays.findIndex(
      (holiday: IHoliday, index: number) => {
        if (holidayIndex !== null && index === holidayIndex) {
          return holiday;
        }
      }
    );

    if (existingHolidayIndex !== -1) {
      holidaysArray[existingHolidayIndex] = newHoliday;
    } else {
      holidaysArray.push(newHoliday);
    }
    setHolidays(holidaysArray);
    setOpenAddHolidayDialog(false);
    setHolidayIndex(null);
  };

  const onChangeAmount = (value: number, taxIndex: number) => {
    setValues({
      ...values,
      taxes: [
        ...values.taxes.slice(0, taxIndex),
        {
          ...values.taxes[taxIndex],
          value
        },
        ...values.taxes.slice(taxIndex + 1)
      ]
    });
  };

  const handleTaxRemove = () => {
    setValues({
      ...values,
      addTaxOptIn: false,
      taxes: []
    });
    setOpenTaxDialog(false);
  };

  const handleAutoPayDaySelection = (day: number, isChecked: boolean): void => {
    let updatedAutoPayDays = values.autoPayDays ? [...values.autoPayDays] : [];
    if (isChecked) {
      updatedAutoPayDays.push(day);
    } else {
      updatedAutoPayDays = updatedAutoPayDays.filter((selectedDay: number) => selectedDay !== day);
    }
    setValues({
      ...values,
      autoPayDays: updatedAutoPayDays
    });
  };

  return (
    <Grid container style={{ padding: 30 }}>
      <CssBaseline />
      <Formik
        enableReinitialize
        validationSchema={branchSchema}
        initialValues={{ ...values }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.taxes && values.taxes.length) {
            for (let index = 0; index < values.taxes.length; index++) {
              const tax = values.taxes[index];
              if (!tax.value) {
                return snackbar({
                  message: "Tax value can not be empty",
                  variant: SnackBarVariant.ERROR
                });
              }
              if (tax.isVehicleGroupSpecific && !tax.vehicleGroups?.length) {
                return snackbar({
                  message: "Atleast 1 vehicle group is required for specific vehicle group tax",
                  variant: SnackBarVariant.ERROR
                });
              }
            }
          }
          if (!values.taxes || !values.taxes.length) {
            values.taxes = [];
          }
          if (values.autoColorEnabled) {
            const hasEmptyFields = sections.some(
              (section) => !section.reason || !section.color
            );
        
            if (hasEmptyFields) {
              snackbar({
                message: `Please select a reason and ${country === "United States" ? "Color" : "Colour"} for all sections before saving.`,
                variant: SnackBarVariant.ERROR,
              });
              return;
            }
          }
          const updateValues = {
            ...values,
            operatingHours: operatingHours,
            phoneNumber: {
              ...values.phoneNumber,
              country: countryCode
            },
            holidays: [...holidays]
          };
          saveBranch(updateValues);
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Form noValidate style={{ flexGrow: 1, width: "100%" }}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <h4 style={{ margin: "0px 0 0px 0" }}>Branch Details</h4>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="name"
                  placeholder="Branch Name"
                  label="Branch Name"
                  value={values.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 75 }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    value={formikProps.values.owner.id}
                    name={"owner.id"}
                    select
                    fullWidth
                    label={"Manager"}
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    InputProps={{
                      onChange: (event: any) => {
                        const filterOwner = usersList.users.length && usersList.users.find((el: any) => el.id === event.target.value)
                        if (filterOwner && filterOwner.id) {
                          setValues({
                            ...formikProps.values,
                            owner: {
                              ...formikProps.values.owner,
                              email: filterOwner.email,
                              id: event.target.value
                            }
                          });
                        } else {
                          setValues({
                            ...formikProps.values,
                            owner: {
                              ...formikProps.values.owner,
                              email: "",
                              id: ""
                            }
                          });
                        }
                      }
                    }}
                  >
                    {usersList && usersList.users.length &&
                      usersList.users.map((user: any) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.email}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  placeholder="Branch Email"
                  label="Branch Email"
                  name={"emailId"}
                  type="email"
                  value={values.emailId}
                  onChange={handleChange}
                  fullWidth
                  required
                ></Field>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name={"phoneNumber.phone"}
                  required
                >
                  {({ form: { }, meta }: FieldProps) => {
                    return (
                      <div className={`phone-input-container`}>
                        <label
                          className={`${phoneError ? "input-error-label" : ""
                            } phone-input-label`}
                        >
                          Phone Number{" "}
                          <sup className={"phone-input-required"}>*</sup>
                        </label>
                        <PhoneInput
                          international={false}
                          defaultCountry={countryCode}
                          placeholder="Enter phone number"
                          className={phoneError ? "phone-input-error" : ""}
                          value={formikProps.values.phoneNumber.phone}
                          name={"phoneNumber.phone"}
                          required
                          onChange={(val: any) => {
                            if (val && isValidPhoneNumber(val)) {
                              setPhoneError("");
                            }
                            setValues({
                              ...formikProps.values,
                              phoneNumber: {
                                ...formikProps.values.phoneNumber,
                                phone: val
                              }
                            });
                          }}
                          onBlur={() => {
                            if (!formikProps.values.phoneNumber.phone) {
                              setPhoneError("Phone number is required.");
                            } else if (
                              !isValidPhoneNumber(
                                formikProps.values.phoneNumber.phone
                              )
                            ) {
                              setPhoneError("Enter a valid phone number.");
                            } else {
                              setPhoneError("");
                            }
                          }}
                          onCountryChange={(val) => {
                            setCountryCode(val);
                          }}
                        />
                        {phoneError !== "" ? (
                          <span className={"phone-error-message"}>
                            {phoneError}
                          </span>
                        ) : (
                          meta.touched &&
                          meta.error && (
                            <span className={"phone-error-message"}>
                              {phoneError || "Phone number is required."}
                            </span>
                          )
                        )}
                      </div>
                    );
                  }}
                </Field>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <AddressPicker
                  fieldName={"address"}
                  required={true}
                  addGeometry={true}
                  onChange={(address: IAddress) => {
                    if (address) {
                      setValues({ ...values, address });
                    }
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={InputField}
                    fullWidth
                    type="text"
                    select
                    required
                    name="timeZone"
                    label="Time Zone"
                    value={formikProps.values.timeZone}
                    InputProps={{
                      onChange: (event: any) => {
                        setValues({
                          ...formikProps.values,
                          timeZone: event.target.value
                        });
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    {TIMEZONES.map((timeZone) => (
                      <MenuItem key={timeZone.value} value={timeZone.value}>
                        {timeZone.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    name={"distanceUnit"}
                    fullWidth
                    select
                    label="Distance Unit"
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    InputProps={{
                      onChange: (
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setValues({
                          ...formikProps.values,
                          distanceUnit: event.target.value
                        });
                      },
                      value: formikProps.values.distanceUnit
                    }}
                  >
                    <MenuItem value={"kilometer"}>{"Kilometer"}</MenuItem>
                    <MenuItem value={"mile"}>{"Mile"}</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <Field
                  component={TextField}
                  name="netPaymentTerms"
                  placeholder="Net Payment Terms (days)"
                  label="Net Payment Terms (days)"
                  inputProps={{
                    value: values.netPaymentTerms || 0,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      let input = e.target.value;
                      if (!["-", "."].includes(input)) {
                        if (input.match(/^[0-9]+$/)) {
                          setValues({
                            ...values,
                            netPaymentTerms: parseInt(input)
                          });
                        }
                      }
                    }
                  }}
                  fullWidth
                  required
                />
              </Grid>
              {/* <Grid item xs={4}>
                  <Field
                    component={TextField}
                    name="dailyBillingCycle"
                    placeholder="Daily Billing Cycle (days)"
                    label="Daily Billing Cycle (days)"
                    inputProps={{
                      value: values.dailyBillingCycle || 30,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        let input = e.target.value;
                        if (!["-", "."].includes(input)) {
                          if (input.match(/^[0-9]+$/)) {
                            setValues({
                              ...values,
                              dailyBillingCycle: parseInt(input)
                            });
                          }
                        }
                      }
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={TextField}
                    name="weeklyBillingCycle"
                    placeholder="Weekly Billing Cycle (weeks)"
                    label="Weekly Billing Cycle (weeks)"
                    inputProps={{
                      value: values.weeklyBillingCycle || 30,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        let input = e.target.value;
                        if (!["-", "."].includes(input)) {
                          if (input.match(/^[0-9]+$/)) {
                            setValues({
                              ...values,
                              weeklyBillingCycle: parseInt(input)
                            });
                          }
                        }
                      }
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={TextField}
                    name="monthlyBillingCycle"
                    placeholder="Monthly Billing Cycle (months)"
                    label="Monthly Billing Cycle (months)"
                    inputProps={{
                      value: values.monthlyBillingCycle || 30,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        let input = e.target.value;
                        if (!["-", "."].includes(input)) {
                          if (input.match(/^[0-9]+$/)) {
                            setValues({
                              ...values,
                              monthlyBillingCycle: parseInt(input)
                            });
                          }
                        }
                      }
                    }}
                    fullWidth
                    required
                  />
                </Grid> */}
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    fullWidth
                    select
                    required
                    name="vehiclePreparationTime"
                    label="Vehicle Preparation Time"
                    value={formikProps.values.vehiclePreparationTime}
                    InputProps={{
                      onChange: (event: any) => {
                        setValues({
                          ...formikProps.values,
                          vehiclePreparationTime: event.target.value
                        });
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    {vehiclePreparationTime.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    name={"depositCollection"}
                    fullWidth
                    select
                    label="Deposit Collection"
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    InputProps={{
                      onChange: (
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setValues({
                          ...formikProps.values,
                          depositCollection: event.target.value
                        });
                      },
                      value: formikProps.values.depositCollection
                    }}
                  >
                    {Object.keys(DepositCollection).map((item: string) => {
                      let label = DepositCollection[item];
                      label = label.replaceAll("_", " ");
                      if (country === "United States") {
                        label = label.replace("BOOKING", "RESERVATION")
                      }
                      return (
                        <MenuItem value={item}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </FormControl>
              </Grid>

              {props.organisation.allowCarSharing && (
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      select
                      required
                      name="carShareGracePeriodMins"
                      label="Car Share Grace Period"
                      value={formikProps.values.carShareGracePeriodMins}
                      InputProps={{
                        onChange: (event: any) => {
                          setValues({
                            ...formikProps.values,
                            carShareGracePeriodMins: event.target.value
                          });
                        }
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    >
                      {vehiclePreparationTime.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
              )}

              {userState.currentTenancy.enableEpyxIntegration && (
                <>
                  <Grid item xs={12}>
                    <h4 style={{ margin: "0px 0 0px 0" }}>Epyx Details</h4>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <Field
                      component={TextField}
                      placeholder="Epyx scheme reference number"
                      label="Epyx scheme reference number"
                      name={"epyxSchemeReferenceNumber"}
                      value={epyxDetails.epyxSchemeReferenceNumber}
                      onChange={handleChange}
                      fullWidth
                      required
                    ></Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <Field
                      component={TextField}
                      placeholder="Epyx account number"
                      label="Epyx account number"
                      name={"epyxAccountNumber"}
                      value={epyxDetails.epyxAccountNumber}
                      onChange={handleChange}
                      fullWidth
                      required
                    ></Field>
                  </Grid>
                </>
              )}

              <Hidden smDown>
                <Grid
                  item
                  container
                  xs={12}
                  className="branch-operating-hours"
                >
                  <Grid xs={12}>
                    <h4 style={{ margin: "20px 0 0px 0" }}>
                      Operating Hours
                    </h4>
                  </Grid>
                  {/* <Grid xs={12}>
                    <Typography variant="body1">Set operating hours for this branch. Pickup and return time can only be selected within these hours. Click on days to edit</Typography>
                  </Grid> */}
                  <Grid xs={12}>
                    <div className="flex">
                      {operatingHours.map((day, dayIndex) => (
                        <div
                          className="day"
                          onClick={() => {
                            setDialog({ open: true, data: day });
                          }}
                        >
                          <Typography variant="h6" className="day-name">
                            {day.day.charAt(0).toUpperCase() +
                              day.day.slice(1)}
                          </Typography>
                          {!day.closed ? (
                            <>
                              <div className="flex timing">
                                <div
                                  className="arrow"
                                  style={{ border: 0, fontSize: 14 }}
                                >
                                  <ScheduleIcon fontSize="inherit" />
                                </div>
                                <p>{`${day.workingFromTime} - ${day.workingToTime}`}</p>
                              </div>
                              <div className="flex timing">
                                <div className="arrow">
                                  <ArrowUpwardIcon fontSize="inherit" />
                                </div>
                                <p>{`${day.pickupFromTime} - ${day.pickupToTime}`}</p>
                              </div>
                              <div className="flex timing">
                                <div className="arrow">
                                  <ArrowDownwardIcon fontSize="inherit" />
                                </div>
                                <p>{`${day.returnFromTime} - ${day.returnToTime}`}</p>
                              </div>
                            </>
                          ) : (
                            <span className="closed-text">Closed</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Accordion style={{ width: `calc(100vw - 105px)` }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h3">Operating Hours</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container item xs={12}>
                      {operatingHours.map((day) => (
                        <Grid item xs={12}>
                          <ButtonBase
                            onClick={() => {
                              setDialog({ open: true, data: day });
                            }}
                            style={{ marginBottom: "8px" }}
                          >
                            <Card className={classes.cardStyle}>
                              <CardContent>
                                <Grid container xs={12}>
                                  <Grid item justifyContent="center" xs={12}>
                                    <Typography
                                      variant="h6"
                                      className="day-name"
                                    >
                                      {day.day.charAt(0).toUpperCase() +
                                        day.day.slice(1)}
                                    </Typography>
                                  </Grid>
                                  {!day.closed ? (
                                    <>
                                      <Grid
                                        container
                                        item
                                        align-items="center"
                                        justifyContent="center"
                                        xs={12}
                                      >
                                        <ScheduleIcon fontSize="inherit" />
                                        <Typography>
                                          {`${day.workingFromTime} - ${day.workingToTime}`}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        container
                                        item
                                        align-items="center"
                                        justifyContent="center"
                                        xs={12}
                                      >
                                        <ArrowUpwardIcon fontSize="inherit" />
                                        <Typography>{`${day.pickupFromTime} - ${day.pickupToTime}`}</Typography>
                                      </Grid>
                                      <Grid
                                        container
                                        item
                                        align-items="center"
                                        justifyContent="center"
                                        xs={12}
                                      >
                                        <ArrowDownwardIcon fontSize="inherit" />
                                        <Typography>{`${day.returnFromTime} - ${day.returnToTime}`}</Typography>
                                      </Grid>
                                    </>
                                  ) : (
                                    <Grid
                                      container
                                      item
                                      align-items="center"
                                      justifyContent="center"
                                      xs={12}
                                    >
                                      <Typography className="closed-text">
                                        Closed
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              </CardContent>
                            </Card>
                          </ButtonBase>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Hidden>
              <Dialog
                open={dialog.open}
                onClose={handleDialogueClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <Formik
                  enableReinitialize
                  initialValues={dialog.data}
                  validationSchema={operatingDaySchema}
                  onSubmit={(values) => {
                    if (
                      values &&
                      values.workingFromTime &&
                      values.workingToTime &&
                      values.workingFromTime > values.workingToTime
                    ) {
                      return snackbar({
                        message:
                          "Working from time should be less than the to time",
                        variant: SnackBarVariant.ERROR
                      });
                    }
                    if (
                      values &&
                      values.pickupFromTime &&
                      values.pickupToTime &&
                      values.pickupFromTime > values.pickupToTime
                    ) {
                      return snackbar({
                        message:
                          "Pick-up from time should be less than the to time",
                        variant: SnackBarVariant.ERROR
                      });
                    }
                    if (
                      values &&
                      values.returnFromTime &&
                      values.returnToTime &&
                      values.returnFromTime > values.returnToTime
                    ) {
                      return snackbar({
                        message:
                          "Drop-off from time should be less than the to time",
                        variant: SnackBarVariant.ERROR
                      });
                    }
                    let newValues = { ...values };
                    setDialog({
                      ...dialog,
                      open: false
                    });
                    let tempDays = [...operatingHours];
                    const thisIndex = operatingHours.findIndex(
                      (day) => day.dayIndex === newValues.dayIndex
                    );
                    tempDays[thisIndex] = newValues;
                    setOperatingHours(tempDays);
                  }}
                >
                  {(dialogFormprops) => (
                    <Form>
                      <DialogTitle style={{ padding: "16px 35px" }}>
                        <Typography
                          variant="h4"
                          style={{ fontWeight: 700, fontSize: 16 }}
                        >
                          Edit {dialog.data.day} hours
                        </Typography>
                      </DialogTitle>
                      <DialogContent dividers>
                        <div className="dialog-content-branch-op">
                          <div style={{ display: "flex", marginBottom: 30 }}>
                            <Field name="closed">
                              {({ field }: FieldProps) => (
                                <AntSwitch checked={field.value} {...field} />
                              )}
                            </Field>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 700, marginLeft: 10 }}
                            >
                              Closed day
                            </Typography>
                          </div>
                          <div className="timing-wrap">
                            <Grid item xs style={{ position: "relative" }}>
                              {dialogFormprops.values.closed && (
                                <div className="overlay"></div>
                              )}
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: -10 }}
                                >
                                  <div className="flex timing">
                                    <div
                                      className="arrow"
                                      style={{ border: 0, fontSize: 16 }}
                                    >
                                      <ScheduleIcon fontSize="inherit" />
                                    </div>
                                    <p>Working hours</p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field name="workingFromTime">
                                    {({
                                      field,
                                      meta: { touched, error }
                                    }: FieldProps) => (
                                      <>
                                        <Typography variant="body1">
                                          From time *
                                          {touched && Boolean(error) && (
                                            <span className="error-text">
                                              {error}
                                            </span>
                                          )}
                                        </Typography>
                                        <TimePicker
                                          is24Hour
                                          time={field.value}
                                          setTime={(time: string) =>
                                            dialogFormprops.setFieldValue(
                                              "workingFromTime",
                                              time
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field name="workingToTime">
                                    {({
                                      field,
                                      meta: { touched, error }
                                    }: FieldProps) => (
                                      <>
                                        <Typography variant="body1">
                                          To time *
                                          {touched && Boolean(error) && (
                                            <span className="error-text">
                                              {error}
                                            </span>
                                          )}
                                        </Typography>
                                        <TimePicker
                                          is24Hour
                                          time={field.value}
                                          setTime={(time: string) =>
                                            dialogFormprops.setFieldValue(
                                              "workingToTime",
                                              time
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginTop: 15 }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: -10 }}
                                >
                                  <div className="flex timing">
                                    <div className="arrow">
                                      <ArrowUpwardIcon fontSize="inherit" />
                                    </div>
                                    <p>Pick-up hours</p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field name="pickupFromTime">
                                    {({
                                      field,
                                      meta: { touched, error }
                                    }: FieldProps) => (
                                      <>
                                        <Typography variant="body1">
                                          From time *
                                          {touched && Boolean(error) && (
                                            <span className="error-text">
                                              {error}
                                            </span>
                                          )}
                                        </Typography>
                                        <TimePicker
                                          is24Hour
                                          time={field.value}
                                          setTime={(time: string) =>
                                            dialogFormprops.setFieldValue(
                                              "pickupFromTime",
                                              time
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field name="pickupToTime">
                                    {({
                                      field,
                                      meta: { touched, error }
                                    }: FieldProps) => (
                                      <>
                                        <Typography variant="body1">
                                          To time *
                                          {touched && Boolean(error) && (
                                            <span className="error-text">
                                              {error}
                                            </span>
                                          )}
                                        </Typography>
                                        <TimePicker
                                          is24Hour
                                          time={field.value}
                                          setTime={(time: string) =>
                                            dialogFormprops.setFieldValue(
                                              "pickupToTime",
                                              time
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginTop: 15 }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: -10 }}
                                >
                                  <div className="flex timing">
                                    <div className="arrow">
                                      <ArrowDownwardIcon fontSize="inherit" />
                                    </div>
                                    <p>Drop-off hours</p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field name="returnFromTime">
                                    {({
                                      field,
                                      meta: { touched, error }
                                    }: FieldProps) => (
                                      <>
                                        <Typography variant="body1">
                                          From time *
                                          {touched && Boolean(error) && (
                                            <span className="error-text">
                                              {error}
                                            </span>
                                          )}
                                        </Typography>
                                        <TimePicker
                                          is24Hour
                                          time={field.value}
                                          setTime={(time: string) =>
                                            dialogFormprops.setFieldValue(
                                              "returnFromTime",
                                              time
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field name="returnToTime">
                                    {({
                                      field,
                                      meta: { touched, error }
                                    }: FieldProps) => (
                                      <>
                                        <Typography variant="body1">
                                          To time *
                                          {touched && Boolean(error) && (
                                            <span className="error-text">
                                              {error}
                                            </span>
                                          )}
                                        </Typography>
                                        <TimePicker
                                          is24Hour
                                          time={field.value}
                                          setTime={(time: string) =>
                                            dialogFormprops.setFieldValue(
                                              "returnToTime",
                                              time
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </DialogContent>
                      <DialogActions style={{ padding: "20px 35px" }}>
                        <div style={{ display: "flex", flex: 1 }}></div>
                        <Fab
                          className="blackBackButton"
                          variant="extended"
                          size="small"
                          onClick={handleDialogueClose}
                        >
                          Cancel
                        </Fab>
                        <Fab variant="extended" size="small" type="submit">
                          <strong>Done</strong>
                        </Fab>
                      </DialogActions>
                    </Form>
                  )}
                </Formik>
              </Dialog>
              <Grid item container xs={12} sx={{ alignItems: 'flex-start' }}>
                <Grid item container xs={12} md={6} lg={6}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      control={
                        <Switch
                          checked={values.minBookingDurationOptIn}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              minBookingDurationOptIn: event.target.checked
                            });
                          }}
                          name="minBookingDurationOptIn"
                          color="primary"
                        />
                      }
                      style={{
                        marginLeft: "0px",
                        marginBottom: "10px"
                      }}
                      labelPlacement="end"
                      label={`Activate Minimum ${getLocalizedBookingSyntex(
                        country
                      )} Duration (in Hours)`}
                    />
                  </Grid>
                  {values.minBookingDurationOptIn && (
                    <React.Fragment>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          placeholder={`Minimum ${getLocalizedBookingSyntex(
                            country
                          )} Duration (in Hours)`}
                          label={`Minimum ${getLocalizedBookingSyntex(
                            country
                          )} Duration (in Hours)`}
                          name={"minBookingDuration"}
                          inputProps={{
                            min: 1
                          }}
                          InputProps={{
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setValues({
                                ...values,
                                minBookingDuration: parseFloat(e.target.value)
                              }),
                            value: values.minBookingDuration
                          }}
                          type="number"
                          fullWidth
                          required
                        ></Field>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item container xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: "20px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.showPaymentMethodInvoice ? true : false}
                          onClick={(e: any) => {
                            setValues({
                              ...values,
                              showPaymentMethodInvoice: e.target.checked
                            })
                          }}
                          value="showPaymentMethod"
                        />
                      }
                      label={<b>Show Payment Details on Invoices</b>}
                    />
                  </Grid>
                  {values.showPaymentMethodInvoice &&
                    <Grid item xs={12}>
                      <Grid item>When enabled, this option allows you to add and display the payment method details on all invoices, providing clear transaction information to your customers.</Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          placeholder="Enter Payment Details"
                          name={"paymentMethodString"}
                          fullWidth
                          multiline
                          row={3}
                          InputProps={{
                            onChange: (event: ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...values,
                                paymentMethodString: event.target.value,
                              });
                            },
                            value: values.paymentMethodString,
                          }}
                          inputProps={{ maxLength: 300 }}
                          disabled={false}
                        ></Field>
                      </Grid>
                    </Grid>
                  }
                </Grid>
                {userState.currentTenancy.rateCardsEnabled && <Grid item container xs={12} md={6} lg={6}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      control={
                        <Switch
                          checked={values.corporateRateCardEnabled}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              corporateRateCardEnabled: event.target.checked
                            });
                          }}
                          name="corporateRateCardEnabled"
                          color="primary"
                        />
                      }
                      style={{
                        marginLeft: "0px",
                        marginBottom: "10px"
                      }}
                      labelPlacement="end"
                      label={`Enable Rate Cards for business customers`}
                    />
                  </Grid>
                </Grid>}
                <Grid item container xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: "20px", display: "flex", alignItems: "center" }}>  
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      userSelect: "none", 
                    }}
                  >
                    <Checkbox
                      checked={values.autoColorEnabled}
                      onChange={handleToggle}
                      name="autoColorEnabled"
                      color="primary"
                    />
                    <b>On Hold - {country === "United States" ? "Color" : "Colour"} Codes</b>
                  </label>
                  {values.autoColorEnabled && (
                    <span
                      onClick={(event) => {
                        event.stopPropagation(); 
                        event.preventDefault();
                        setSections([...values.eventsColor]);
                        setDialogOpen(true);
                      }}
                      style={{
                        marginLeft: "5px",
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#3f51b5",
                      }}
                    >
                      Edit
                    </span>
                  )}
                </Grid>
                <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
                  <DialogContent>
                    {sections.map((section, index) => (
                      <Grid container spacing={2} key={index} alignItems="center" style={{ marginBottom: "10px" }}>
                        <Grid item xs={7}>
                          <InputField
                            label="Reason"
                            value={section.reason || ""}
                            onChange={(e) => handleSectionChange(index, "reason", e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <ColorPickerField
                              color={section.color}
                              onChange={(newColor) => handleSectionChange(index, "color", newColor)}
                            />
                            {sections.length > 1 && (
                              <IconButton onClick={() => handleRemoveSection(index)} color="secondary" size="small">
                                <Remove />
                              </IconButton>
                            )}
                            {index === sections.length - 1 && section.reason && section.color && (
                              <IconButton onClick={handleAddSection} color="primary" size="small">
                                <Add />
                              </IconButton>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSaveSections}
                      color="primary"
                      disabled={!sections.every((sec) => sec.reason && sec.color)}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* <Grid item container xs={12} md={6} lg={6}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        classes={{ label: classes.formControlLabel }}
                        control={
                          <Switch
                            checked={values.minExtensionDurationOptIn}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...values,
                                minExtensionDurationOptIn: event.target.checked
                              });
                            }}
                            name="minExtensionDurationOptIn"
                            color="primary"
                          />
                        }
                        style={{
                          marginLeft: "0px",
                          marginBottom: "10px"
                        }}
                        labelPlacement="end"
                        label={`Activate Minimum ${getLocalizedBookingSyntex(country)} Extension Duration (in Hours)`}
                      />
                    </Grid>
                    {values.minExtensionDurationOptIn && (
                      <React.Fragment>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            placeholder={`Minimum Extension Duration (in Hours)`}
                            label={`Minimum Extension Duration (in Hours)`}
                            name={"minimumExtensionDuration"}
                            inputProps={{
                              min: 1
                            }}
                            InputProps={{
                              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                setValues({
                                  ...values,
                                  minimumExtensionDuration: parseFloat(e.target.value)
                                }),
                              value: values.minimumExtensionDuration,
                            }}
                            type="number"
                            fullWidth
                            required
                          ></Field>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid> */}

                {/* NOTE - commented code for variable minimum booking amount type */}
                {/* <Grid item container xs={12}>
                    <Grid item xs={12} lg={6}>
                      <Field
                        component={TextField}
                        select
                        name={"minimumBookingAmount.type"}
                        fullWidth
                        required
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        InputProps={{
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            setValues({
                              ...values,
                              minimumBookingAmount: {
                                value: 100,
                                type: e.target.value
                              }
                            }),
                          value: values.minimumBookingAmount.type,
                        }}
                      >
                        <MenuItem value="VARIABLE"  >
                          Percantage
                        </MenuItem>
                        <MenuItem value="FIXED">
                          Fixed
                        </MenuItem>
                      </Field>
                    </Grid>
                  </Grid> */}
              </Grid>

              <Grid item container xs={12} md={6} lg={6}>
                <Grid item xs={12}>
                  <FormControlLabel
                    classes={{ label: classes.formControlLabel }}
                    control={
                      <Switch
                        checked={formikProps.values.minBookingAmountOptIn}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (!stripeAccountId) {
                            snackbar({
                              message:
                                "Stripe integration is required to accept payments",
                              variant: SnackBarVariant.ERROR
                            });
                          } else {
                            setValues({
                              ...values,
                              minBookingAmountOptIn: event.target.checked
                            });
                          }
                        }}
                        name="minBookingAmountOptIn"
                        color="primary"
                      />
                    }
                    style={{
                      marginLeft: "0px",
                      marginBottom: "10px"
                    }}
                    labelPlacement="end"
                    label={`Activate minimum ${getLocalizedBookingSyntex(
                      country
                    )} amount`}
                  />
                </Grid>
                {formikProps.values.minBookingAmountOptIn && (
                  <Grid container xs={12} spacing={1} className="minimum-booking-amount-grid">
                    <Grid item xs={6}>
                      <Field
                        component={TextField}
                        label="Type"
                        select
                        name={"minimumBookingAmount.type"}
                        fullWidth
                        required
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        InputProps={{
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            setValues({
                              ...values,
                              minimumBookingAmount: {
                                value: 0,
                                type: e.target.value
                              }
                            }),
                          value: values.minimumBookingAmount.type
                        }}
                      >
                        {Object.keys(MinimumBookingAmountTypes).map(
                          (type: string) => {
                            return (
                              <MenuItem value={type}>
                                {type === MinimumBookingAmountTypes.VARIABLE
                                  ? "PERCENTAGE"
                                  : type}
                              </MenuItem>
                            );
                          }
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      {values.minimumBookingAmount.type ===
                        MinimumBookingAmountTypes.VARIABLE ? (
                        <Field
                          component={TextField}
                          placeholder="e.g 10"
                          label="Value(%)"
                          name={"minimumBookingAmount.value"}
                          required
                          type="number"
                          InputProps={{
                            value: values.minimumBookingAmount.value,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (parseFloat(e.target.value) > 100) {
                                snackbar({
                                  message: `Minimum ${getLocalizedBookingSyntex(
                                    country
                                  )} amount can't be more than 100%`,
                                  variant: SnackBarVariant.ERROR
                                });
                              } else if (parseFloat(e.target.value) < 0) {
                                snackbar({
                                  message: `Minimum ${getLocalizedBookingSyntex(
                                    country
                                  )} amount can't be less than 0%`,
                                  variant: SnackBarVariant.ERROR
                                });
                              } else {
                                setValues({
                                  ...values,
                                  minimumBookingAmount: {
                                    ...values.minimumBookingAmount,
                                    value: parseFloat(e.target.value)
                                  }
                                });
                              }
                            },
                            min: "1"
                          }}
                          validate={(value: any) =>
                            validateMinimumAmount(
                              value,
                              values.minBookingAmountOptIn
                            )
                          }
                          fullWidth
                        />
                      ) : (
                        <Field
                          component={TextField}
                          label={`Minimum ${getLocalizedBookingSyntex(
                            country
                          )} amount`}
                          name={"minimumBookingAmount.value"}
                          fullWidth
                          required
                          inputProps={{
                            hasCurrencyPrefix:
                              values.minimumBookingAmount.type ===
                                MinimumBookingAmountTypes.VARIABLE
                                ? false
                                : true,
                            allowNegative: false,
                            currency: props.organisation.currency
                          }}
                          validate={(value: any) =>
                            validateMinimumAmount(
                              value,
                              values.minBookingAmountOptIn
                            )
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              minimumBookingAmount: {
                                ...values.minimumBookingAmount,
                                value: parseFloat(e.target.value)
                              }
                            });
                          }}
                          value={values.minimumBookingAmount.value}
                          InputProps={{
                            inputComponent: FloatInput as any
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {country !== "United States" && (
                <Grid item container xs={12} md={6} lg={4}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      control={
                        <Switch
                          checked={
                            formikProps.values.useThresholds ? true : false
                          }
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              useThresholds: event.target.checked
                            });
                          }}
                          name="useThresholds"
                          color="primary"
                        />
                      }
                      style={{
                        marginLeft: "0px",
                        marginBottom: "10px"
                      }}
                      labelPlacement="end"
                      label="Use thresholds"
                    />
                  </Grid>
                  {formikProps.values.useThresholds && (
                    <Grid container xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="offenceThreshold"
                          placeholder="Offences Threshold"
                          label="Offences Threshold"
                          inputProps={{
                            value: values.offenceThreshold || 0,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              let input = e.target.value;
                              if (!["-", "."].includes(input)) {
                                if (input.match(/^[0-9]+$/)) {
                                  setValues({
                                    ...values,
                                    offenceThreshold: parseInt(input)
                                  });
                                }
                              }
                            }
                          }}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="pointThreshold"
                          placeholder="Points Threshold"
                          label="Points Threshold"
                          inputProps={{
                            value: values.pointThreshold || 0,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              let input = e.target.value;
                              if (!["-", "."].includes(input)) {
                                if (input.match(/^[0-9]+$/)) {
                                  setValues({
                                    ...values,
                                    pointThreshold: parseInt(input)
                                  });
                                }
                              }
                            }
                          }}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}

              <Grid item container spacing={2} xs={12}>
                {/* <Grid item container xs={12}>
                    <Grid item xs={10}>
                      <h4 style={{ margin: "0px 0 0px 0" }}>Booking Questions</h4>
                    </Grid>
                  </Grid> */}
                <Grid item container xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{`${getLocalizedBookingSyntex(
                      country
                    )} questions`}</Typography>
                    <Typography variant="subtitle2">
                      {`Add pre ${getLocalizedBookingSyntex(
                        country
                      )} questions for ${getLocalizedBookingSyntex(country).toLowerCase()} flow. Add to see how
                        the questions will look like on the website and web app.
                        Click on a question to edit or remove.`}
                    </Typography>
                    <Grid item xs={12}>
                      <Box mt={3} />
                    </Grid>
                    {formikProps.values &&
                      formikProps.values.preBookingQuestions &&
                      formikProps.values.preBookingQuestions.map(
                        (
                          bookingQuestion: IBookingQuestion,
                          index: number
                        ) => {
                          if (
                            bookingQuestion.answerType === ANSWER_TYPES.YES_NO
                          ) {
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                style={{ marginBottom: 30 }}
                                className={styles.bookingQuestion}
                                onClick={() =>
                                  setQuestionDialogData({
                                    isPreBooking: true,
                                    open: true,
                                    data: bookingQuestion,
                                    questionType:
                                      BookingQuestionType.PRE_BOOKING,
                                    questionIndex: index
                                  })
                                }
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    paddingRight: 15
                                  }}
                                >
                                  <Typography>
                                    {bookingQuestion.question}{" "}
                                    {bookingQuestion.required && " *"}
                                  </Typography>
                                </Grid>
                                <Grid
                                  xs={12}
                                  md={9}
                                  className={styles.yesnoWrap}
                                >
                                  <div
                                    className={`${styles.yesnoButton} ${styles.left}`}
                                  >
                                    Yes
                                  </div>
                                  <div
                                    className={`${styles.yesnoButton} ${styles.right}`}
                                  >
                                    No
                                  </div>
                                </Grid>
                                {getQuestionTag(bookingQuestion)}
                              </Grid>
                            );
                          }
                          if (
                            bookingQuestion.answerType ===
                            ANSWER_TYPES.DROP_DOWN
                          ) {
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                style={{ marginBottom: 30 }}
                                className={styles.bookingQuestion}
                                onClick={() =>
                                  setQuestionDialogData({
                                    isPreBooking: true,
                                    open: true,
                                    data: bookingQuestion,
                                    questionType:
                                      BookingQuestionType.PRE_BOOKING,
                                    questionIndex: index
                                  })
                                }
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    paddingRight: 15
                                  }}
                                >
                                  <Typography>
                                    {bookingQuestion.question}{" "}
                                    {bookingQuestion.required && " *"}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={9}
                                  className={styles.yesnoWrap}
                                >
                                  <div
                                    className={`${styles.dropdownBox} ${styles.left}`}
                                  >
                                    <span>Choose an option</span>
                                    <ArrowDropDownIcon
                                      style={{ fontSize: 18 }}
                                    />
                                  </div>
                                </Grid>
                                {getQuestionTag(bookingQuestion)}
                              </Grid>
                            );
                          }
                          if (
                            bookingQuestion.answerType === ANSWER_TYPES.TEXT
                          ) {
                            return (
                              <Grid
                                item
                                xs={12}
                                style={{ marginBottom: 15 }}
                                className={styles.textQuestion}
                                onClick={() =>
                                  setQuestionDialogData({
                                    isPreBooking: true,
                                    open: true,
                                    data: bookingQuestion,
                                    questionType:
                                      BookingQuestionType.PRE_BOOKING,
                                    questionIndex: index
                                  })
                                }
                              >
                                {bookingQuestion.question}{" "}
                                {bookingQuestion.required && " *"}
                                {getQuestionTag(bookingQuestion)}
                              </Grid>
                            );
                          }
                          if (
                            bookingQuestion.answerType ===
                            ANSWER_TYPES.CHECKBOX
                          ) {
                            return (
                              <div
                                className={styles.bookingQuestion}
                                onClick={() =>
                                  setQuestionDialogData({
                                    isPreBooking: true,
                                    open: true,
                                    data: bookingQuestion,
                                    questionType:
                                      BookingQuestionType.PRE_BOOKING,
                                    questionIndex: index
                                  })
                                }
                              >
                                <div className={styles.fakeCheck}></div>
                                <Typography>
                                  {bookingQuestion.question}{" "}
                                  {bookingQuestion.required && " *"}
                                </Typography>
                                {getQuestionTag(bookingQuestion)}
                              </div>
                            );
                          }
                        }
                      )}
                    <Fab
                      className="blackBackButton"
                      variant="extended"
                      size="small"
                      aria-label="add"
                      onClick={() =>
                        setQuestionDialogData({
                          isPreBooking: true,
                          open: true,
                          data: initialBookingQuestionData,
                          questionType: BookingQuestionType.PRE_BOOKING,
                          questionIndex: undefined
                        })
                      }
                    >
                      Add +
                    </Fab>
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                  <Grid>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      control={
                        <Switch
                          checked={
                            formikProps.values.isAdditionalDriverQuestionsEnabled
                          }
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              isAdditionalDriverQuestionsEnabled:
                                event.target.checked
                            });
                          }}
                          name="isAdditionalDriverQuestionsEnabled"
                          color="primary"
                        />
                      }
                      style={{
                        marginLeft: "0px",
                        marginBottom: "10px"
                      }}
                      labelPlacement="end"
                      label={
                        <Typography variant="h3" color="primary">
                          Additional Driver questions
                        </Typography>
                      }
                    />

                    {values.isAdditionalDriverQuestionsEnabled && (
                      <Grid container spacing={2}>
                        <Grid item container xs={12} md={6}>
                          <Grid item xs={12}>
                            <Typography variant="h4">{`${getLocalizedBookingSyntex(country)} questions for additional drivers`}</Typography>
                            <Typography variant="subtitle2">
                              {`Add additional driver questions for ${getLocalizedBookingSyntex(country).toLowerCase()} flow in mobile or operator app. Add to see how the questions will look like on the mobile app . Click on a question to edit or remove.`}
                            </Typography>
                            <Grid item xs={12}>
                              <Box mt={3} />
                            </Grid>
                            {formikProps.values && formikProps.values.additionalDriverQuestions && formikProps.values.additionalDriverQuestions.map(
                              (bookingQuestion, index) => {
                                if (bookingQuestion.answerType === ANSWER_TYPES.YES_NO) {
                                  return (
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      style={{ marginBottom: 30 }}
                                      className={styles.bookingQuestion}
                                      onClick={() =>
                                        setQuestionDialogData({
                                          isPreBooking: false,
                                          open: true,
                                          data: bookingQuestion,
                                          questionType: BookingQuestionType.ADDITIONAL_DRIVER,
                                          questionIndex: index
                                        })
                                      }
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        style={{ display: "flex", flex: 1, paddingRight: 15 }}
                                      >
                                        <Typography>
                                          {bookingQuestion.question} {bookingQuestion.required && " *"}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={12} md={9} className={styles.yesnoWrap}>
                                        <div className={`${styles.yesnoButton} ${styles.left}`}>
                                          Yes
                                        </div>
                                        <div className={`${styles.yesnoButton} ${styles.right}`}>
                                          No
                                        </div>
                                      </Grid>
                                    </Grid>
                                  );
                                }
                                if (bookingQuestion.answerType === ANSWER_TYPES.DROP_DOWN) {
                                  return (
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      style={{ marginBottom: 30 }}
                                      className={styles.bookingQuestion}
                                      onClick={() =>
                                        setQuestionDialogData({
                                          isPreBooking: false,
                                          open: true,
                                          data: bookingQuestion,
                                          questionType: BookingQuestionType.ADDITIONAL_DRIVER,
                                          questionIndex: index
                                        })
                                      }
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        style={{ display: "flex", flex: 1, paddingRight: 15 }}
                                      >
                                        <Typography>
                                          {bookingQuestion.question} {bookingQuestion.required && " *"}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} md={9} className={styles.yesnoWrap}>
                                        <div className={`${styles.dropdownBox} ${styles.left}`}>
                                          <span>Choose an option</span>
                                          <ArrowDropDownIcon style={{ fontSize: 18 }} />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  );
                                }
                                if (bookingQuestion.answerType === ANSWER_TYPES.TEXT) {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ marginBottom: 15 }}
                                      className={styles.textQuestion}
                                      onClick={() =>
                                        setQuestionDialogData({
                                          isPreBooking: false,
                                          open: true,
                                          data: bookingQuestion,
                                          questionType: BookingQuestionType.ADDITIONAL_DRIVER,
                                          questionIndex: index
                                        })
                                      }
                                    >
                                      {bookingQuestion.question} {bookingQuestion.required && " *"}
                                    </Grid>
                                  );
                                }
                                if (bookingQuestion.answerType === ANSWER_TYPES.CHECKBOX) {
                                  return (
                                    <div
                                      className={styles.bookingQuestion}
                                      onClick={() =>
                                        setQuestionDialogData({
                                          isPreBooking: false,
                                          open: true,
                                          data: bookingQuestion,
                                          questionType: BookingQuestionType.ADDITIONAL_DRIVER,
                                          questionIndex: index
                                        })
                                      }
                                    >
                                      <div className={styles.fakeCheck}></div>
                                      <Typography>
                                        {bookingQuestion.question} {bookingQuestion.required && " *"}
                                      </Typography>
                                    </div>
                                  );
                                }
                              }
                            )}
                            <Fab
                              className={styles.blackBackButton}
                              variant="extended"
                              size="small"
                              aria-label="add"
                              onClick={() =>
                                setQuestionDialogData({
                                  isPreBooking: false,
                                  open: true,
                                  data: initialBookingQuestionData,
                                  questionType: BookingQuestionType.ADDITIONAL_DRIVER,
                                  questionIndex: undefined
                                })
                              }
                            >
                              Add +
                            </Fab>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>


                <Grid item container xs={12}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h4">
                      Vehicle handover questions
                    </Typography>
                    <Typography variant="subtitle2">
                      {`Add Vehicle handover questions for ${getLocalizedBookingSyntex(
                        country
                      )} flow. Add to
                        see how the question will look like on the mobile app.
                        Click on a question to edit or remove.`}
                    </Typography>
                    {formikProps.values &&
                      formikProps.values.deliveryQuestions &&
                      formikProps.values.deliveryQuestions.map(
                        (
                          bookingQuestion: IBookingQuestion,
                          index: number
                        ) => {
                          if (
                            bookingQuestion.answerType === ANSWER_TYPES.YES_NO
                          ) {
                            return (
                              <div
                                className={styles.bookingQuestion}
                                onClick={() =>
                                  setQuestionDialogData({
                                    open: true,
                                    data: bookingQuestion,
                                    questionType:
                                      BookingQuestionType.VEHICLE_PICKUP,
                                    questionIndex: index
                                  })
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    paddingRight: 15
                                  }}
                                >
                                  <Typography>
                                    {bookingQuestion.question}{" "}
                                    {bookingQuestion.required && " *"}
                                  </Typography>
                                </div>
                                <div className={styles.yesnoWrap}>
                                  <div
                                    className={`${styles.yesnoButton} ${styles.left}`}
                                  >
                                    Yes
                                  </div>
                                  <div
                                    className={`${styles.yesnoButton} ${styles.right}`}
                                  >
                                    No
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (
                            bookingQuestion.answerType ===
                            ANSWER_TYPES.DROP_DOWN
                          ) {
                            return (
                              <div
                                className={styles.bookingQuestion}
                                onClick={() =>
                                  setQuestionDialogData({
                                    open: true,
                                    data: bookingQuestion,
                                    questionType:
                                      BookingQuestionType.VEHICLE_PICKUP,
                                    questionIndex: index
                                  })
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    paddingRight: 15
                                  }}
                                >
                                  <Typography>
                                    {bookingQuestion.question}{" "}
                                    {bookingQuestion.required && " *"}
                                  </Typography>
                                </div>
                                <div className={styles.yesnoWrap}>
                                  <div
                                    className={`${styles.dropdownBox} ${styles.left}`}
                                  >
                                    <span>Choose an option</span>
                                    <ArrowDropDownIcon
                                      style={{ fontSize: 18 }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (
                            bookingQuestion.answerType === ANSWER_TYPES.TEXT
                          ) {
                            return (
                              <div
                                className={styles.textQuestion}
                                onClick={() =>
                                  setQuestionDialogData({
                                    open: true,
                                    data: bookingQuestion,
                                    questionType:
                                      BookingQuestionType.VEHICLE_PICKUP,
                                    questionIndex: index
                                  })
                                }
                              >
                                {bookingQuestion.question}{" "}
                                {bookingQuestion.required && " *"}
                              </div>
                            );
                          }
                          if (
                            bookingQuestion.answerType ===
                            ANSWER_TYPES.CHECKBOX
                          ) {
                            return (
                              <div
                                className={styles.bookingQuestion}
                                onClick={() =>
                                  setQuestionDialogData({
                                    open: true,
                                    data: bookingQuestion,
                                    questionType:
                                      BookingQuestionType.VEHICLE_PICKUP,
                                    questionIndex: index
                                  })
                                }
                              >
                                <div className={styles.fakeCheck}></div>
                                <Typography>
                                  {bookingQuestion.question}{" "}
                                  {bookingQuestion.required && " *"}
                                </Typography>
                              </div>
                            );
                          }
                        }
                      )}
                    <Fab
                      className="blackBackButton"
                      style={{ marginTop: 30 }}
                      variant="extended"
                      size="small"
                      aria-label="add"
                      onClick={() =>
                        setQuestionDialogData({
                          open: true,
                          data: initialBookingQuestionData,
                          questionType: BookingQuestionType.VEHICLE_PICKUP,
                          questionIndex: undefined
                        })
                      }
                    >
                      Add +
                    </Fab>
                  </Grid>

                  <Grid xs={12} sm={12} md={6} lg={4} xl={4} mx={1}>
                    <Typography variant="h4" mb={2}>
                      Set Dashboard Reminder
                    </Typography>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        fullWidth
                        select
                        name="dashboardReminder"
                        label="Dashboard Reminders"
                        value={formikProps.values.dashboardReminder}
                        InputProps={{
                          onChange: (event: any) => {
                            setValues({
                              ...formikProps.values,
                              dashboardReminder: event.target.value
                            });
                          }
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {dashboardReminders.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1}></Box>
              </Grid>
              <Grid item container alignItems="baseline" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4">Holidays</Typography>
                  <Typography variant="subtitle2" style={{ marginRight: 10 }}>
                    {`Add Holidays dates to block those dates from pickup date
                      selection while creating ${getLocalizedBookingSyntex(
                      country
                    )}.`}
                  </Typography>
                  {holidays && holidays.length > 0 && (
                    <Grid container>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {/* <TableCell align="left"></TableCell> */}
                              <TableCell align="left">Name</TableCell>
                              <TableCell align="left">Start Date</TableCell>
                              <TableCell align="left">End Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {holidays.map(
                              (holiday: IHoliday, index: number) => (
                                <TableRow
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setHoliday(holiday);
                                    setHolidayIndex(index);
                                    setOpenAddHolidayDialog(true);
                                  }}
                                >
                                  {/* <TableCell align="left">{index + 1}</TableCell> */}
                                  <TableCell align="left">
                                    {holiday.name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {" "}
                                    {holiday.startDate}
                                  </TableCell>
                                  <TableCell align="left">
                                    {holiday.endDate}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                  <Fab
                    className="blackBackButton"
                    style={{ marginTop: 10 }}
                    variant="extended"
                    size="small"
                    aria-label="add"
                    onClick={() => {
                      setHoliday({
                        endDate: "",
                        isActive: true,
                        name: "",
                        startDate: "",
                        id: ""
                      });
                      setOpenAddHolidayDialog(true);
                    }}
                  >
                    Add +
                  </Fab>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="h4">Operator Signature</Typography>
                  </Grid>
                  {signature && (
                    <Grid item xs={12}>
                      <ViewLogo logoUrl={signature} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Fab
                      className="blackBackButton"
                      variant="extended"
                      size="small"
                      aria-label="Update"
                      onClick={() => {
                        setDocumentDialogVisible(true);
                        formikProps.setFieldTouched("signatureUrl");
                      }}
                    >
                      UPLOAD SIGNATURE
                    </Fab>
                    {!signature && formikProps.errors.signatureUrl && (
                      <Typography
                        style={{ marginTop: 5, fontSize: "0.75rem" }}
                        className="error-text"
                        color="error"
                      >
                        {formikProps.errors.signatureUrl}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    classes={{ label: classes.formControlLabel }}
                    control={
                      <Switch
                        checked={formikProps.values.addTaxOptIn}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (!event.target.checked) {
                            setOpenTaxDialog(true);
                          }
                          if (event.target.checked) {
                            setValues({
                              ...values,
                              addTaxOptIn: event.target.checked,
                              taxes: [
                                {
                                  title: "",
                                  rate: "",
                                  subdivisionRate: "",
                                  subdivisionOptIn: false,
                                  subdivisionType: "",
                                  subdivisionValue: 0,
                                  type: "",
                                  value: 0
                                }
                              ]
                            });
                          }
                        }}
                        name="addTaxOptin"
                        color="primary"
                      />
                    }
                    style={{
                      marginLeft: "0px",
                      marginBottom: "10px"
                    }}
                    labelPlacement="end"
                    label={
                      <Typography variant="h3" color="primary">
                        {country === "United States"
                          ? "Add Taxes & Fees"
                          : "Add Tax"}
                      </Typography>
                    }
                  />
                </Grid>
                {formikProps.values.addTaxOptIn && (
                  <Grid item xs={12} container>
                    <Grid item xs={12} container justifyContent="space-between">
                      <Typography variant={"h5"}>
                        {`Defined ${country === "United States" ? "Taxes & Fees" : "Tax"
                          } will be applicable on the total rental amount.`}
                      </Typography>
                      <Tooltip title="Add new tax item">
                        <IconButton
                          aria-label="add"
                          onClick={() => {
                            setValues({
                              ...values,
                              taxes: [
                                ...values.taxes,
                                {
                                  title: "",
                                  rate: "",
                                  subdivisionRate: "",
                                  subdivisionOptIn: false,
                                  subdivisionType: "",
                                  subdivisionValue: 0,
                                  type: "",
                                  value: 0
                                }
                              ]
                            });
                          }}
                          size="large">
                          <AddCircleIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    {values.taxes &&
                      values.taxes.length > 0 &&
                      values.taxes.map((tax: ITaxInput, index: number) => {
                        return (
                          <Paper
                            variant="outlined"
                            key={index}
                            style={{
                              flexGrow: 1,
                              padding: 15,
                              marginBottom: 10
                            }}
                          >
                            <Grid item xs={12} container spacing={2}>
                              <Grid item xs={6} md={3}>
                                <Field
                                  component={TextField}
                                  name={`${values.taxes[index]}.title`}
                                  placeholder="tax title"
                                  label="Tax title"
                                  InputProps={{
                                    onChange: (event: any) => {
                                      const updatedTaxes = [
                                        ...values.taxes.slice(0, index),
                                        {
                                          ...values.taxes[index],
                                          title: event.target.value
                                        },
                                        ...values.taxes.slice(index + 1)
                                      ];
                                      setValues({
                                        ...values,
                                        taxes: updatedTaxes
                                      });
                                    },
                                    value: tax.title
                                  }}
                                  inputProps={{ maxLength: 75 }}
                                  fullWidth
                                  required
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Field
                                  component={TextField}
                                  name={`${values.taxes[index]}.type`}
                                  placeholder="type"
                                  label="Type"
                                  inputProps={{
                                    value: tax.type,
                                    onChange: (event: any) => {
                                      const updatedTaxes = [
                                        ...values.taxes.slice(0, index),
                                        {
                                          ...values.taxes[index],
                                          type: event.target.value
                                        },
                                        ...values.taxes.slice(index + 1)
                                      ];
                                      setValues({
                                        ...values,
                                        taxes: updatedTaxes
                                      });
                                    }
                                  }}
                                  fullWidth
                                  select
                                  required
                                >
                                  {taxTypes.map((type) => {
                                    return (
                                      <MenuItem
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Grid>
                              {tax.type === TaxValueType.PERCENTAGE ? (
                                <Grid item container xs={6} md={3}>
                                  <Field
                                    component={TextField}
                                    placeholder="e.g 10"
                                    label="Value(%)"
                                    name={`${values.taxes[index]}.value`}
                                    required
                                    type="number"
                                    InputProps={{
                                      value: tax.value,
                                      onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        const floatValue = parseFloat(
                                          e.target.value
                                        );
                                        if (floatValue > 100) {
                                          snackbar({
                                            message:
                                              "Tax Value can't be more than 100%",
                                            variant: SnackBarVariant.ERROR
                                          });
                                        } else if (floatValue < 0) {
                                          snackbar({
                                            message:
                                              "Tax value can't be less than 0%",
                                            variant: SnackBarVariant.ERROR
                                          });
                                        } else {
                                          onChangeAmount(floatValue, index);
                                        }
                                      },
                                      min: "1"
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                              ) : (
                                <Grid item xs={6} md={3}>
                                  <Field
                                    component={TextField}
                                    label="Value"
                                    fullWidth
                                    required
                                    name={`${values.taxes[index]}.value`}
                                    InputProps={{
                                      value: tax.value,
                                      inputComponent: FloatInput as any,
                                      onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        const integerValue = parseInt(
                                          e.target.value
                                        );
                                        onChangeAmount(integerValue, index);
                                      }
                                    }}
                                    inputProps={{
                                      hasCurrencyPrefix: true,
                                      allowNegative: false,
                                      currency: props.organisation.currency
                                    }}
                                  ></Field>
                                </Grid>
                              )}
                              <Grid item xs={6} md={3}>
                                <Field
                                  component={TextField}
                                  name={`${values.taxes[index]}.rate`}
                                  placeholder="rate"
                                  label="Rate"
                                  required
                                  select
                                  inputProps={{
                                    onChange: (event: any) => {
                                      const updatedTaxes = [
                                        ...values.taxes.slice(0, index),
                                        {
                                          ...values.taxes[index],
                                          rate: event.target.value
                                        },
                                        ...values.taxes.slice(index + 1)
                                      ];
                                      setValues({
                                        ...values,
                                        taxes: updatedTaxes
                                      });
                                    },
                                    value: tax.rate
                                  }}
                                  fullWidth
                                >
                                  {taxRateTypes.map((rateType) => {
                                    return (
                                      <MenuItem
                                        key={rateType.value}
                                        value={rateType.value}
                                      >
                                        {rateType.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <FormControlLabel
                                  classes={{
                                    label: classes.formControlLabel
                                  }}
                                  control={
                                    <Switch
                                      checked={tax.subdivisionOptIn}
                                      onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (!event.target.checked) {
                                          const updatedTaxes = [
                                            ...values.taxes.slice(0, index),
                                            {
                                              ...values.taxes[index],
                                              subdivisionOptIn:
                                                event.target.checked,
                                              subdivisionRate: "",
                                              subdivisionType: "",
                                              subdivisionValue: 0
                                            },
                                            ...values.taxes.slice(index + 1)
                                          ];
                                          setValues({
                                            ...values,
                                            taxes: updatedTaxes
                                          });
                                        } else {
                                          const updatedTaxes = [
                                            ...values.taxes.slice(0, index),
                                            {
                                              ...values.taxes[index],
                                              subdivisionOptIn:
                                                event.target.checked
                                            },
                                            ...values.taxes.slice(index + 1)
                                          ];
                                          setValues({
                                            ...values,
                                            taxes: updatedTaxes
                                          });
                                        }
                                      }}
                                      name={`${values.taxes[index]}.subdivisionOptIn`}
                                      color="primary"
                                    />
                                  }
                                  style={{
                                    marginLeft: "0px",
                                    marginBottom: "10px"
                                  }}
                                  labelPlacement="end"
                                  label={
                                    <Typography variant="h5">
                                      Add Subdivison Tax
                                    </Typography>
                                  }
                                />
                              </Grid>
                              {tax.subdivisionOptIn && (
                                <>
                                  <Grid item xs={6} md={3}>
                                    <Field
                                      component={TextField}
                                      name={`taxes[${index}].subdivisionType`}
                                      placeholder="type"
                                      label="Type"
                                      inputProps={{
                                        onChange: (event: any) => {
                                          const updatedTaxes = [
                                            ...values.taxes.slice(0, index),
                                            {
                                              ...values.taxes[index],
                                              subdivisionType:
                                                event.target.value
                                            },
                                            ...values.taxes.slice(index + 1)
                                          ];
                                          setValues({
                                            ...values,
                                            taxes: updatedTaxes
                                          });
                                        },
                                        value: tax.subdivisionType
                                      }}
                                      fullWidth
                                      select
                                      required
                                    >
                                      {taxTypes.map((rateType) => {
                                        return (
                                          <MenuItem
                                            key={rateType.value}
                                            value={rateType.value}
                                          >
                                            {rateType.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Field>
                                  </Grid>
                                  {tax.subdivisionType ===
                                    TaxValueType.FIXED ? (
                                    <Grid item container xs={6} md={3}>
                                      <Field
                                        component={TextField}
                                        label="Value"
                                        name={`${values.taxes[index]}.subdivisionValue`}
                                        required
                                        InputProps={{
                                          value: tax.subdivisionValue,
                                          inputComponent: FloatInput as any,
                                          onChange: (
                                            e: React.ChangeEvent<
                                              HTMLInputElement
                                            >
                                          ) => {
                                            const value = parseInt(
                                              e.target.value
                                            );
                                            setValues((previousValues) => ({
                                              ...previousValues,
                                              taxes: [
                                                ...previousValues.taxes.slice(
                                                  0,
                                                  index
                                                ),
                                                {
                                                  ...previousValues.taxes[
                                                  index
                                                  ],
                                                  subdivisionValue: value
                                                },
                                                ...previousValues.taxes.slice(
                                                  index + 1
                                                )
                                              ]
                                            }));
                                          }
                                        }}
                                        inputProps={{
                                          hasCurrencyPrefix: true,
                                          allowNegative: false,
                                          currency:
                                            props.organisation.currency
                                        }}
                                        fullWidth
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item xs={6} md={3}>
                                      <Field
                                        component={TextField}
                                        placeholder="e.g 10"
                                        label="Value(%)"
                                        fullWidth
                                        type="number"
                                        name={`${values.taxes[index]}.subdivisionValue`}
                                        InputProps={{
                                          value: tax.subdivisionValue,
                                          onChange: (
                                            e: React.ChangeEvent<
                                              HTMLInputElement
                                            >
                                          ) => {
                                            const value = parseFloat(
                                              e.target.value
                                            );
                                            if (value > 100) {
                                              snackbar({
                                                message:
                                                  "Subdivision Tax Value can't be more than 100%",
                                                variant: SnackBarVariant.ERROR
                                              });
                                            } else if (value < 0) {
                                              snackbar({
                                                message:
                                                  "Subdivision Tax value can't be less than 0%",
                                                variant: SnackBarVariant.ERROR
                                              });
                                            } else {
                                              setValues((previousValues) => ({
                                                ...previousValues,
                                                taxes: [
                                                  ...previousValues.taxes.slice(
                                                    0,
                                                    index
                                                  ),
                                                  {
                                                    ...previousValues.taxes[
                                                    index
                                                    ],
                                                    subdivisionValue: value
                                                  },
                                                  ...previousValues.taxes.slice(
                                                    index + 1
                                                  )
                                                ]
                                              }));
                                            }
                                          },
                                          min: "1"
                                        }}
                                      ></Field>
                                    </Grid>
                                  )}
                                  <Grid item xs={6} md={3}>
                                    <Field
                                      component={TextField}
                                      name={`${values.taxes[index]}.subdivisionRate`}
                                      label="Sub Divison Rate"
                                      InputProps={{
                                        onChange: (event: any) => {
                                          const updatedTaxes = [
                                            ...values.taxes.slice(0, index),
                                            {
                                              ...values.taxes[index],
                                              subdivisionRate:
                                                event.target.value
                                            },
                                            ...values.taxes.slice(index + 1)
                                          ];
                                          setValues({
                                            ...values,
                                            taxes: updatedTaxes
                                          });
                                        }
                                      }}
                                      value={tax.subdivisionRate}
                                      inputProps={{ maxLength: 75 }}
                                      fullWidth
                                      select
                                      required
                                    >
                                      {taxRateTypes.map((rateType) => {
                                        return (
                                          <MenuItem
                                            key={rateType.value}
                                            value={rateType.value}
                                          >
                                            {rateType.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Field>
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={6} md={3}>
                                <Tooltip
                                  title={
                                    values.taxes.length < 2
                                      ? "Tax item can not be removed because if tax is enabled then one item should be there"
                                      : "Remove tax item"
                                  }
                                >
                                  <IconButton
                                    aria-label="remove"
                                    onClick={() => {
                                      const newTaxes = [
                                        ...formikProps.values.taxes.slice(
                                          0,
                                          index
                                        ),
                                        ...formikProps.values.taxes.slice(
                                          index + 1
                                        )
                                      ];
                                      setValues({
                                        ...formikProps.values,
                                        taxes: newTaxes
                                      });
                                    }}
                                    disabled={values.taxes.length < 2}
                                    size="large">
                                    <DeleteIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid container item xs={12}>
                                <Grid item xs={6} md={3}>
                                  <FormControlLabel
                                    classes={{
                                      label: classes.formControlLabel
                                    }}
                                    control={
                                      <Switch
                                        checked={tax.isVehicleGroupSpecific}
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>
                                        ) => {
                                          if (!event.target.checked) {
                                            const updatedTaxes = [
                                              ...values.taxes.slice(0, index),
                                              {
                                                ...values.taxes[index],
                                                isVehicleGroupSpecific:
                                                  event.target.checked,
                                              },
                                              ...values.taxes.slice(index + 1)
                                            ];
                                            setValues({
                                              ...values,
                                              taxes: updatedTaxes
                                            });
                                          } else {
                                            const updatedTaxes = [
                                              ...values.taxes.slice(0, index),
                                              {
                                                ...values.taxes[index],
                                                isVehicleGroupSpecific:
                                                  event.target.checked
                                              },
                                              ...values.taxes.slice(index + 1)
                                            ];
                                            setValues({
                                              ...values,
                                              taxes: updatedTaxes
                                            });
                                          }
                                        }}
                                        name={`${values.taxes[index]}.isVehicleGroupSpecific`}
                                        color="primary"
                                      />
                                    }
                                    style={{
                                      marginLeft: "0px",
                                      marginBottom: "10px"
                                    }}
                                    labelPlacement="end"
                                    label={
                                      <Typography variant="h5">
                                        Vehicle Groups Specific Tax
                                      </Typography>
                                    }
                                  />
                                </Grid>
                                {tax.isVehicleGroupSpecific && (
                                  <Grid item xs={12} md={6}>
                                    <Autocomplete
                                      multiple
                                      freeSolo
                                      id="free-solo-tax-vehicle-group-search"
                                      disableClearable
                                      options={vehicleGroups}
                                      getOptionLabel={(option: any) =>
                                        option.name
                                      }
                                      filterSelectedOptions
                                      onChange={(_: any, newValues: any, reason: any) => {
                                        if (reason === "selectOption") {
                                          const updatedTaxes = [
                                            ...values.taxes.slice(0, index),
                                            {
                                              ...values.taxes[index],
                                              vehicleGroups: [
                                                ...values.taxes[index].vehicleGroups || [],
                                                newValues[newValues.length - 1].id
                                              ]
                                            },
                                            ...values.taxes.slice(index + 1)
                                          ];
                                          setValues({
                                            ...values,
                                            taxes: updatedTaxes
                                          });
                                        }
                                        if (reason === "removeOption") {
                                          const updatedGroups = newValues.map((value: IVehiclePriceGroup) => value.id);
                                          const updatedTaxes = [
                                            ...values.taxes.slice(0, index),
                                            {
                                              ...values.taxes[index],
                                              vehicleGroups: updatedGroups
                                            },
                                            ...values.taxes.slice(index + 1)
                                          ];
                                          setValues({
                                            ...values,
                                            taxes: updatedTaxes
                                          });
                                        }
                                        const updatedGroups = [...selectedVehicleGroups];
                                        updatedGroups[index] = {
                                          ...updatedGroups[index],
                                          vehicleGroups: newValues,
                                        };
                                        setSelectedVehicleGroups(updatedGroups);
                                      }}
                                      value={selectedVehicleGroups[index]?.vehicleGroups || []}
                                      defaultValue={[]}
                                      renderInput={(params) => (
                                        <InputField
                                          {...params}
                                          label="Select Vehicle Group(s)"
                                          variant="outlined"
                                          placeholder={"Select Vehicle Group(s)"}
                                          InputProps={{ ...params.InputProps, type: "search" }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Paper>
                        );
                      })}
                  </Grid>
                )}
                <Grid item container xs={12} md={6} lg={6}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      control={
                        <Switch
                          checked={formikProps.values.overbookingAllowed}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              overbookingAllowed: event.target.checked
                            });
                          }}
                          name="ghostVehicleActivation"
                          color="primary"
                        />
                      }
                      style={{
                        marginLeft: "0px",
                        marginBottom: "10px"
                      }}
                      labelPlacement="end"
                      label={
                        <Typography variant="h3" color="primary">
                          Allow Overbooking
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      control={
                        <Switch
                          checked={
                            formikProps.values.durationBasedPricingEnabled
                          }
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              durationBasedPricingEnabled:
                                event.target.checked
                            });
                          }}
                          name="durationbasedPricingActivation"
                          color="primary"
                        />
                      }
                      style={{
                        marginLeft: "0px",
                        marginBottom: "10px"
                      }}
                      labelPlacement="end"
                      label={
                        <Typography variant="h3" color="primary">
                          Enable Duration Based Pricing
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        classes={{ label: classes.formControlLabel }}
                        control={
                          <Switch
                            checked={
                              formikProps.values.autoPayEnabled
                            }
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              setValues({
                                ...values,
                                autoPayEnabled: event.target.checked
                              });
                            }}
                            name="autoPayEnabled"
                            color="primary"
                          />
                        }
                        style={{
                          marginLeft: "0px",
                          marginBottom: "10px"
                        }}
                        labelPlacement="end"
                        label={
                          <Typography variant="h3" color="primary">
                            Enable Auto Pay For Invoice
                          </Typography>
                        }
                      />
                    </Grid>
                    {values.autoPayEnabled && autoPayDays.map((option: IOptionsMenu, index: number) => (
                      <FormGroup row key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.autoPayDays?.includes(option.value as number)}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAutoPayDaySelection(option.value as number, event.target.checked)}
                              value={option.value}
                              color="secondary"
                              name={option.label}
                            />
                          }
                          label={<Typography variant="body1">{option.label}</Typography>}
                        />
                      </FormGroup>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  type="submit"
                  disabled={
                    (userState.role === UserRoles.BRANCH_MANAGER &&
                      !branchToUpdate.id) ||
                    branchLoading ||
                    updateBranchLoading
                  }
                >
                  {(branchLoading || updateBranchLoading) && (
                    <CircularProgress
                      size={14}
                      style={{ color: "white", marginRight: "10px" }}
                    />
                  )}
                  Save
                </Fab>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container item xs={12} style={{ marginTop: "20px" }}>
        <Hidden smDown>
          {branches && branches.length > 0 && (
            <BranchList
              branches={branches}
              isUpdateSuccessful={isUpdateSuccessful}
              setIsUpdateSuccessful={setIsUpdateSuccessful}
            />
          )}
        </Hidden>
        <Hidden mdUp>
          <MobileBranchCards branches={branches} />
        </Hidden>
      </Grid>
      {questionDialogData.open && (
        <QuestionsDialog
          isPreBooking={questionDialogData.isPreBooking || false}
          open={questionDialogData.open}
          handleClose={() => {
            setQuestionDialogData({ ...questionDialogData, open: false });
          }}
          data={questionDialogData.data}
          questionIndex={questionDialogData.questionIndex}
          handleSubmit={saveDailogData}
          handleDelete={handleQuestionDelete}
        />
      )}
      <div>
        <DragDropImages
          title={"Upload Signature"}
          open={documentDialogVisible}
          handleCloseDragDrop={() => setDocumentDialogVisible(false)}
          handleSave={uploadDocument}
          filesLimit={1}
        />
      </div>
      {openAddHolidayDialog && (
        <AddHolidaysDialog
          open={openAddHolidayDialog}
          handleClose={() => {
            setOpenAddHolidayDialog(false);
          }}
          handleSubmit={addHoliday}
          holidayData={holiday}
        />
      )}
      {openTaxDialog && (
        <ConfirmationDialog
          open={openTaxDialog}
          description="Toggling off the Tax section would remove all the tax details you have created and can not be retrieved. "
          onClose={() => setOpenTaxDialog(false)}
          onConfirm={handleTaxRemove}
          title="Remove Taxes"
        />
      )}
    </Grid>
  );
};
export default Branch;